import { Box, Button, Container, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniMenu from "../common/miniMenu/MiniMenu";
import BreadCrumbs from "../common/breadCrumbs/BreadCrumbs";
import { Stack } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DescriptionIcon from "@mui/icons-material/Description";
import { DataGrid, GridOverlay, GridToolbar } from "@mui/x-data-grid";
import { getAllAPIExceptionLogs } from "../../apiCalls/allApiCalls";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useSelector } from "react-redux";

const ApiExceptionLogs = () => {
  const [apiExceptionsLogs, setApiExceptionsLogs] = useState([]);
  const [, setIsLoading] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const { allTranslations } = useSelector((state) => state.translations);

  function CustomNoDataOverlay() {
    return (
      <GridOverlay>
        <Typography color="text.secondary">
          {allTranslations?.no_data_yet?.[userLang] ?? "No data yet"}
        </Typography>
      </GridOverlay>
    );
  }

  const getAllApiExceptionLogs = async () => {
    try {
      setIsLoading(true);
      const response = await getAllAPIExceptionLogs();
      if (response.status === 200) {
        setApiExceptionsLogs(response.data);
        setFilteredLogs(response.data); // Set the filtered logs initially
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllApiExceptionLogs();
  }, []);

  const columns = [
    {
      field: "updatedAt",
      headerName: allTranslations?.date_and_time?.[userLang] ?? "Date and Time",
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: false,
      renderCell: (params) => {
        return (
          <span
            title={DateTime(params)}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              display: "block",
            }}
          >
            {DateTime(params)}
          </span>
        );
      },
    },
    {
      field: "message",
      headerName: allTranslations?.message?.[userLang] ?? "Message",
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "activityBy",
      headerName: allTranslations?.activity_by?.[userLang] ?? "Activity By",
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "level",
      headerName: allTranslations?.level?.[userLang] ?? "Level",
      editable: false,
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          params.row.level === "error" && (
            <Typography color="error" mt={2}>
              Error
            </Typography>
          )
        );
      },
    },
    {
      field: "errorStack",
      headerName: allTranslations?.error_stack?.[userLang] ?? "Error Stack",
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip
            title={params.row.errorStack || "No error details available"}
            arrow
          >
            <Box mt={1}>
              <ErrorOutlineIcon color="error" />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "payload",
      headerName: allTranslations?.payload?.[userLang] ?? "Payload",
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const payloadString = params.row.payload
          ? JSON.stringify(params.row.payload, null, 2) // Format the JSON object with indentation
          : "No payload details available";

        return (
          <Tooltip
            title={<pre style={{ margin: 0 }}>{payloadString}</pre>} // Display JSON in a preformatted block
            arrow
          >
            <Box mt={1}>
              <DescriptionIcon />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "fileName",
      headerName: allTranslations?.file_name?.[userLang] ?? "File Name",
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "methodName",
      headerName: allTranslations?.method_name?.[userLang] ?? "Method Name",
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "activityTitle",
      headerName:
        allTranslations?.activity_title?.[userLang] ?? "Activity Title",
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: false,
      headerAlign: "center",
      align: "center",
    },
  ];

  // Define translations for the toolbar
  const localeText = {
    toolbarColumns: allTranslations?.columns?.[userLang] ?? "Columns",
    toolbarFilters: allTranslations?.filters?.[userLang] ?? "Filters",
    toolbarDensity: allTranslations?.density?.[userLang] ?? "Density",
    toolbarExport: allTranslations?.export?.[userLang] ?? "Export",
    toolbarQuickFilterPlaceholder:
      allTranslations?.search?.[userLang] ?? "Search",
  };

  const getRowId = (row) => row._id;

  const DateTime = (params) => {
    const utcDate = new Date(params.value); // Convert `updatedAt` to Date object assuming UTC

    // Convert UTC date to IST
    const istDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );

    // Format the date and time as "dd-mm-yyyy, hh:mm:ss"
    const formattedDate = `${String(istDate.getDate()).padStart(
      2,
      "0"
    )}-${String(istDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${istDate.getFullYear()}, ${String(istDate.getHours()).padStart(
      2,
      "0"
    )}:${String(istDate.getMinutes()).padStart(2, "0")}:${String(
      istDate.getSeconds()
    ).padStart(2, "0")}`;
    return formattedDate;
  };

  // Today's date and time in "DD-MM-YYYY_HH-mm-ss" format
  const todayWithTime = dayjs().format("DD-MM-YYYY_HH-mm-ss");

  const handleSearch = () => {
    if (dateFrom && dateTo) {
      const isSameDay = dayjs(dateFrom).isSame(dayjs(dateTo), "day");

      if (isSameDay) {
        // Filter logs for the specific date
        const filtered = apiExceptionsLogs.filter((log) =>
          dayjs(log.updatedAt).isSame(dayjs(dateFrom), "day")
        );
        setFilteredLogs(filtered);
      } else {
        // Filter logs between dateFrom and dateTo
        const filtered = apiExceptionsLogs.filter((log) => {
          const logDate = dayjs(log.updatedAt);
          return logDate.isBetween(dateFrom, dateTo, null, "[]");
        });
        setFilteredLogs(filtered);
      }
    } else {
      toast.warn("Please select both Date From and Date To.");
    }
  };

  const handleReset = () => {
    setDateFrom(null);
    setDateTo(null);
    setFilteredLogs(apiExceptionsLogs);
  };

  return (
    <Box width={"95%"} m={"auto"}>
      <MiniMenu />
      <Container
        sx={{
          p: 4,
          bgcolor: "background.default",
          borderRadius: 5,
          minHeight: "80vh",
          minWidth: "100%",
        }}
      >
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BreadCrumbs />
        </Box>
        <Box>
          <Stack direction={"row"}>
            <Stack
              direction={"row"}
              gap={2}
              width={"50%"}
              alignItems={"center"}
            >
              <Stack gap={2}>
                <Typography color={"text.primary"}>
                  {allTranslations?.date_from?.[userLang] ?? "Date From"}:
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dateFrom}
                    onChange={(newValue) => setDateFrom(newValue)}
                    slotProps={{ textField: { size: "small" } }}
                  />
                </LocalizationProvider>
              </Stack>
              <Stack gap={2}>
                <Typography color={"text.primary"}>
                  {allTranslations?.date_to?.[userLang] ?? "Date To"}:
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dateTo}
                    onChange={(newValue) => setDateTo(newValue)}
                    slotProps={{ textField: { size: "small" } }}
                    minDate={dateFrom}
                  />
                </LocalizationProvider>
              </Stack>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "primary.main",
                  mt: 5,
                }}
                onClick={handleSearch}
              >
                {allTranslations?.search?.[userLang] ?? "Search"}
              </Button>
              <Button variant="outlined" sx={{ mt: 5 }} onClick={handleReset}>
                {allTranslations?.reset?.[userLang] ?? "Reset"}
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Box
          sx={(theme) => ({
            "& .super-app-theme--header": {
              backgroundColor: theme.palette.dataGrid.headerBg,
            },
            height: 600,
            width: "100%",
            mt: 3,
          })}
        >
          {/* <DataGrid
            rows={filteredLogs}
            columns={columns}
            getRowId={getRowId}
            pageSize={10}
            rowsPerPageOptions={[10]}
            pageSizeOptions={[5, 10, 25, { value: -1, label: "All" }]}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: CustomNoDataOverlay,
              Toolbar: GridToolbar,
            }}
            loading={isLoading}
            autoHeight
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  fileName: `User Feedback`,
                },
              },
            }}
          /> */}
          <DataGrid
            localeText={localeText}
            rows={filteredLogs} // Use filtered logs instead of original logs
            getRowId={getRowId}
            columns={columns}
            checkboxSelection
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, { value: -1, label: "All" }]}
            slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoDataOverlay }}
            sx={{
              ".css-t89xny-MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
              minHeight: "200px",
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  fileName: `API Exception Logs - ${todayWithTime}`,
                },
              },
            }}
            disableRowSelectionOnClick
          />
        </Box>
      </Container>
    </Box>
  );
};

export default ApiExceptionLogs;
