import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@emotion/react";

// Styled components for customization
const CircularProgressWithLabel = styled(Box)({
  position: "relative",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  // marginLeft: ,
  // paddingTop: 35,
});

const Label = styled(Typography)({
  position: "absolute",
  color: "white",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  textAlign: "center",
});

const Segment = styled(CircularProgress)(({ theme, color, rotation }) => ({
  position: "absolute",
  color,
  transform: `rotate(${rotation}deg)`,
}));

const InnerCircle = styled(Box)({
  position: "absolute",
  width: "110px",
  height: "110px",
  borderRadius: "50%",
  // backgroundColor: "#0B7643",
  background: `linear-gradient(to right bottom, #266555, #0B7643)`,
});

const InnerCircleTwo = styled(Box)({
  position: "absolute",
  width: "110px",
  height: "110px",
  borderRadius: "50%",
  backgroundColor: "#299D92",
});

const ProgressBar = ({ label, mode }) => {
  return (
    <CircularProgressWithLabel>
      <Segment
        variant="determinate"
        value={25}
        size={120}
        thickness={3}
        color={"success"}
        style={{ transform: "rotate(270deg)" }}
      />
      {/* <Segment
        variant="determinate"
        value={25}
        size={120}
        thickness={4}
        // style={{ color: "red", transform: "rotate(90deg)" }}
      />
      <Segment
        variant="determinate"
        value={25}
        size={120}
        thickness={4}
        // style={{ color: "green", transform: "rotate(180deg)" }}
      />
      <Segment
        variant="determinate"
        value={25}
        size={120}
        thickness={4}
        style={{ color: "primary", transform: "rotate(270deg)" }}
      /> */}
      {mode === "dark" ? <InnerCircle /> : <InnerCircleTwo />}
      <Label variant="caption">{label}</Label>
    </CircularProgressWithLabel>
  );
};

export default ProgressBar;
