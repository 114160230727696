import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProgressBar from "../components/common/progressBar/ProgressBar";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import MiniMenu from "../components/common/miniMenu/MiniMenu";
import {
  getUserDetailsByUid,
  getUserProductDetails,
} from "../apiCalls/allApiCalls";
import { productDetails } from "../utilities/data";
import ProductFilters from "../components/newRequest/ProductFilters";
import { useTheme } from "@emotion/react";
import { getUsers } from "../apiCalls/allApiCalls";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ProductsTable from "../components/common/productsTable/ProductsTable";
import UserFilters from "../components/peerRequest/UserFilters";
import Comments from "../components/newRequest/Comments";
import { useLocation } from "react-router-dom";
import UserDetails from "../components/common/userDetails/UserDetails";

function ReferenceUser({ pageName }) {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const theme = useTheme();
  const [platform, setPlatform] = useState("");
  const [system, setSystem] = useState("");
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const [addedItems, setAddedItems] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const [users, setUsers] = useState([]);
  const [comments, setComments] = useState("");
  const [initial, setInitial] = useState(true);

  //Attachments
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resumeError, setResumeError] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [fileList, setFileList] = useState(null);

  //CART
  const [cart, setCart] = useState([]);

  //Drawer
  const [isActive, setIsActive] = useState(false);
  // State for user filters
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [emailId, setEmailId] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [dates, setDates] = useState();
  // State for filtered options
  // State for filtered options
  const [userNames, setUserNames] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [emailIds, setEmailIds] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [userProductsLoading, setUserProductsLoading] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterLoading, setFilterLoading] = useState(true);
  const [selectedUserDetails, setSelectedUserDetails] = useState("");
  const [userRole, setUserRole] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedUserProducts, setSelectedUserProducts] = useState([]);

  const { state } = useLocation();
  console.log(state);
  useEffect(() => {
    const uid = state?.uid;
    handleUserIdChange("", uid);
  }, [state]);

  const [requestData, setRequestsData] = useState({
    requestorId: "U-76e2a7",
    requestType: "SAP",
    requestData: { cart },
  });
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );

  async function fetchUserProducts(uid) {
    const response = await getUserProductDetails(uid);
    // console.log("fetchUserProducts", response);
    if (response) {
      setUserProductsLoading(false);
      setSelectedUserProducts(response);
    }
  }

  // console.log(userIds);
  const handleChangePlatform = (event) => {
    setPlatform(event.target.value);
    setInitial(false);
  };

  const handleClearAll = () => {
    setCart([]);
    setComments("");
    setAddedItems({});
    setFileNames([]);
    setSelectedFiles([]);
  };

  const handleChangeSystem = (event) => {
    setSystem(event.target.value);
    setInitial(false);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setInitial(false);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
    setInitial(false);
  };

  const handleClearFilters = () => {
    setSearch("");
    setPlatform("");
    setSystem("");
    setRole("");
  };

  async function fetchUserById(uid) {
    try {
      const response = await getUserDetailsByUid(uid);
      if (response?.status === 200 && response.data) {
        // console.log(response.data, "userdeets");
        setSelectedUserDetails(response.data);
        setUserName(response.data.FullName);
        setUserId(response.data.Uid);
        setEmailId(response.data.Email);
        setEmploymentType(response.data.Role);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to fetch user details:", error);
    }
  }

  const handleUserIdChange = (event, value) => {
    setUserProductsLoading(true);
    if (value) {
      setInitial(false);
      fetchUserById(value);
      fetchUserProducts(value);
      // console.log("prods", products);
      // setSelectedUserProducts(products);
    } else {
      setSelectedUserProducts("");
      setUserId("");
      setInitial(false);
      setFilteredProducts("");
    }
  };

  const handleUpload = () => {
    if (selectedFiles) {
      console.log("selectedFiles:", selectedFiles);
      // setFileNames([...fileNames, selectedFiles.name]);
      // setFileConfirmed(true);
      setResumeError(false);
    } else {
      alert("Please select a file");
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFileNames = [...files].map((f) => f.name);
    const formData = new FormData();
    [...files].forEach((item) => {
      formData.append("file", item);
    });
    setSelectedFiles([...files]);
    setFileNames([...fileNames, ...newFileNames]);
    if (!fileList) {
      return;
    }
  };

  const filterFiles = (fName) => {
    const updatedFiles = fileNames.filter((eachFile) => eachFile !== fName);
    const updatedSelectedFiles = selectedFiles.filter(
      (eachFile) => eachFile.name !== fName
    );
    setFileNames(updatedFiles);
    setSelectedFiles(updatedSelectedFiles);
    console.log("updatedFiles:", updatedFiles);
  };

  // // Filtering the products by Filters
  const hasFilters = platform || system || search || role || userId;

  function filterProducts(productsList) {
    // console.log("from filters", productsList)
    setFilterLoading(true);
    const prods = hasFilters
      ? productsList.filter(
          (product) =>
            (!platform || product.productDetails.platform.includes(platform)) &&
            (!system || product.productDetails.system.includes(system)) &&
            (!search ||
              product.productDetails.productName
                .toLowerCase()
                .includes(search.toLowerCase())) &&
            (!role ||
              product.productDetails.approver
                .toLowerCase()
                .includes(role.toLowerCase()))
        )
      : [];
    // console.log(prods);
    setFilteredProducts(prods);
    setFilterLoading(false);
    // console.log("filtered", filterLoading, prods);
  }

  // updates products list on filter change
  useEffect(() => {
    if (selectedUserProducts && selectedUserProducts.length > 0) {
      // console.log("userProducts", selectedUserProducts);
      filterProducts(selectedUserProducts);
    }
  }, [selectedUserProducts, hasFilters, search, system, platform, role]);

  if (isLoading) {
    return (
      <Box
        sx={{
          // bgcolor: "background.default",
          padding: "15px",
          margin: 6,
          borderRadius: 3,
          px: 3,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        padding: "15px",
        margin: 6,
        borderRadius: 3,
        minWidth: "90vw",
        minHeight: "100vh",
        px: 3,
      }}
    >
      <MiniMenu
        cart={cart}
        setCart={setCart}
        addedItems={addedItems}
        setAddedItems={setAddedItems}
      />
      <Stack direction={"row"} gap={11} mb={6}>
        {/* <ProgressBar
          sx={{
            bgcolor: "background.primary",
          }}
          label={pageName}
          mode={theme.palette.mode}
        /> */}
        <BreadCrumbs />
      </Stack>
      {/* User-Details */}
      <Typography
        sx={{ color: "text.primary", fontWeight: 600, fontSize: 18, mb: 1 }}
      >
        {allTranslations?.user_details?.[userLang] ?? "User Details"} :
      </Typography>
      {/* {filteredUsers?.length > 0 && ( */}
      {selectedUserDetails && (
        <>
          <Box
            sx={{
              my: 2,
              borderRadius: "5px",
              py: 2,
              bgcolor:
                theme.palette.mode === "light"
                  ? theme.palette.primary.mainGradient2
                  : "background.secondary",
            }}
          >
            {" "}
            <UserDetails selectedUserDetails={selectedUserDetails} />
          </Box>
          {/* Product Details */}
          <Typography
            sx={{ color: "text.primary", fontWeight: 600, fontSize: 18, mb: 1 }}
          >
            Product Details :
          </Typography>
          <ProductFilters
            handleChangePlatform={handleChangePlatform}
            handleChangeSystem={handleChangeSystem}
            handleSearchChange={handleSearchChange}
            handleRoleChange={handleRoleChange}
            handleClearFilters={handleClearFilters}
            search={search}
            system={system}
            platform={platform}
            role={role}
          />
          {/* Product Table */}
          <Box>
            {!userProductsLoading ? (
              <ProductsTable
                data={filteredProducts ? filteredProducts : []}
                initial={initial}
                setCart={setCart}
                cart={cart}
                addedItems={addedItems}
                setAddedItems={setAddedItems}
                setDates={setDates}
                setFilteredProducts={setFilteredProducts}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  p: 3,
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </>
      )}

      {/* Comments & Btns Section */}
      <Typography
        sx={{
          color: "text.primary",
          fontWeight: 600,
          fontSize: 18,
          mb: 1,
          mt: 2,
        }}
      >
        Comments{" "}
        <span
          style={{
            color: "tomato",
          }}
        >
          *
        </span>{" "}
        & Attachments :
      </Typography>
      <Comments
        comments={comments}
        setComments={setComments}
        handleFileChange={handleFileChange}
        fileNames={fileNames}
        filterFiles={filterFiles}
        Close={Close}
        resumeError={resumeError}
        setCart={setCart}
        setAddedItems={setAddedItems}
        setFileNames={setFileNames}
        addedItems={addedItems}
        setIsActive={setIsActive}
        isActive={isActive}
        cart={cart}
        selectedFiles={selectedFiles}
        handleClearAll={handleClearAll}
        profile={profile}
        requestType="Reference User"
        submitLoading={submitLoading}
        setSubmitLoading={setSubmitLoading}
      />
    </Box>
  );
}

export default ReferenceUser;
