import {
  Autocomplete,
  Checkbox,
  Divider,
  InputBase,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";

import { tileShadows } from "../../pages/Root";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import {
  getAllEndUsers,
  getUserDetailsByEmail,
  getUserDetailsByUid,
} from "../../apiCalls/allApiCalls";
import PeerRequest from "../../pages/PeerRequest";
import UserInfo from "./UserInfo";

const UserFilters = ({
  setSelectedUserDetails,
  selectedUserDetails,
  requestType,
  isDisabled,
}) => {
  console.log(isDisabled);
  const [nameInputValue, setNameInputValue] = useState("");
  const [inputName, setInputName] = useState("");
  const [users, setUsers] = useState([]);
  const [idInputValue, setIdInputValue] = useState("");
  const [inputId, setInputId] = useState("");
  const [emailInputValue, setEmailInputValue] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [isPeerRequest, setIsPeerRequest] = useState(false);
  const theme = useTheme();

  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const [selectedSelf, setSelectedSelf] = useState();

  useEffect(() => {
    if (requestType === "peerRequest" || requestType === "deprovisioning") {
      setIsPeerRequest(true);
    }
  }, [requestType]);

  useEffect(() => {
    async function fetchAllUsers() {
      try {
        const response = await getAllEndUsers();
        if (response?.status === 200) {
          // Filter out the current user's profile ID if requestType is peerRequest
          // const filteredUsers =
          //   requestType === "peerRequest"
          //     ? response.data.filter((user) => user.Uid !== profile.Uid)
          //     : response.data;
          // // console.log(profile, "users12345");
          // console.log(filteredUsers, "users12345");
          // setUsers(filteredUsers);
          setUsers(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    }

    fetchAllUsers();
  }, [requestType, profile.Uid]);

  async function fetchUserById(uid) {
    try {
      const response = await getUserDetailsByUid(uid);
      if (response?.status === 200 && response.data) {
        setSelectedUserDetails(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  }

  async function fetchUserByEmail(email) {
    try {
      const response = await getUserDetailsByEmail(email);
      if (response?.status === 200) {
        setSelectedUserDetails(response.data);
        return response.data;
      }
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  }

  // Handle Functions
  // UserFilters component

  const handleUserNameChange = (event, value) => {
    if (value && value.includes(",")) {
      const temp = value.split(",");
      const uid = temp[1].trim();
      if (uid) {
        fetchUserById(uid); // Make API call only if UID is valid
      }
    }
    setNameInputValue("");
    setInputName(""); // Clear input
  };

  const handleUserIdChange = (event, value) => {
    if (value) {
      // Check if the user ID is not empty
      fetchUserById(value); // Make API call only if ID is valid
    }
    setIdInputValue(""); // Clear input
    setInputId(""); // Clear input
  };

  const handleEmailIdChange = async (event, value) => {
    if (value && value.includes("@")) {
      // Check if the email is valid (contains "@")
      await fetchUserByEmail(value); // Make API call only if email is valid
    }
    setEmailInputValue("");
    setInputEmail(""); // Clear input
  };

  const handleEmploymentTypeChange = (event, value) => {};

  useEffect(() => {
    // Initialize profile when requestType is not peerRequest and no user details are selected
    if (
      requestType !== "peerRequest" &&
      requestType != "deprovisioning" &&
      !selectedUserDetails?.Uid
    ) {
      setSelectedUserDetails(profile);
      setSelectedSelf(true);
    }
  }, [requestType, profile, selectedUserDetails, setSelectedUserDetails]);

  useEffect(() => {
    if (selectedUserDetails?.Uid !== profile?.Uid) {
      setSelectedSelf(false);
    }
  }, [selectedUserDetails, profile]);

  console.log(selectedUserDetails, "test123");
  return (
    <Box
      sx={{
        my: 2,
        borderRadius: "5px",
        py: 2,
        bgcolor:
          theme.palette.mode === "light"
            ? theme.palette.primary.mainGradient2
            : "background.secondary",
        border: `1px solid`,
        borderColor: "primary.border",
        ...tileShadows,
      }}
    >
      <Stack direction={"row"} justifyContent={"space-around"} mb={2}>
        <Box>
          <Typography sx={{ fontWeight: 600, color: "text.primary", mb: 1 }}>
            {allTranslations?.user_name?.[userLang] ?? "User Name"}:
          </Typography>
          <Autocomplete
            value={nameInputValue}
            inputValue={inputName}
            freeSolo
            disabled={isDisabled}
            disableClearable
            onChange={(e, v) => {
              handleUserNameChange(e, v);
            }}
            onInputChange={(event, newInputValue) => {
              setInputName(newInputValue);
            }}
            options={users?.map(
              (option) => `${option.FullName}, ${option.Uid}`
            )}
            filterOptions={(options, state) =>
              state.inputValue.length >= 2
                ? options?.filter((option) =>
                    option
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  )
                : []
            }
            renderInput={(params) => (
              <InputBase
                {...params.InputProps}
                sx={{
                  border: "0.5px solid #5d5d5e",
                  borderRadius: 1,
                  padding: 0.5,
                  pl: 1,
                  width: 250,
                }}
                inputProps={{
                  ...params.inputProps,
                  type: "search",
                }}
                placeholder={
                  allTranslations?.search_user_name?.[userLang] ??
                  "Search User Name"
                }
              />
            )}
          />
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 600, color: "text.primary", mb: 1 }}>
            {allTranslations?.user?.[userLang] ?? "User"}ID:
          </Typography>
          <Autocomplete
            value={idInputValue}
            inputValue={inputId}
            freeSolo
            disabled={isDisabled}
            // disablePortal
            disableClearable
            onChange={(e, v) => {
              setIdInputValue(v);
              handleUserIdChange(e, v);
            }}
            onInputChange={(event, newInputValue) => {
              setInputId(newInputValue);
            }}
            options={users?.map((option) => option.Uid)}
            filterOptions={(options, state) =>
              state.inputValue.length >= 2
                ? options?.filter((option) =>
                    option
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  )
                : []
            }
            renderInput={(params) => (
              <InputBase
                {...params.InputProps}
                sx={{
                  border: "0.5px solid #5d5d5e",
                  borderRadius: 1,
                  padding: 0.5,
                  pl: 1,
                  width: 250,
                }}
                inputProps={{
                  ...params.inputProps,
                  type: "search",
                }}
                placeholder={
                  allTranslations?.search_user?.[userLang] ?? "Search User ID"
                }
              />
            )}
          />
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 600, color: "text.primary", mb: 1 }}>
            {allTranslations?.email?.[userLang] ?? "Email"} ID:
          </Typography>
          <Autocomplete
            value={emailInputValue}
            inputValue={inputEmail}
            freeSolo
            disabled={isDisabled}
            disableClearable
            onChange={(e, v) => {
              handleEmailIdChange(e, v);
            }}
            onInputChange={(event, newInputValue) => {
              setInputEmail(newInputValue);
            }}
            options={users?.map((option) => option.Email)}
            filterOptions={(options, state) =>
              state.inputValue.length >= 2
                ? options?.filter((option) =>
                    option
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  )
                : []
            }
            renderInput={(params) => (
              <InputBase
                {...params.InputProps}
                sx={{
                  border: "0.5px solid #5d5d5e",
                  borderRadius: 1,
                  padding: 0.5,
                  pl: 1,
                  width: 250,
                }}
                inputProps={{
                  ...params.inputProps,
                  type: "search",
                }}
                placeholder={
                  allTranslations?.search_user_email?.[userLang] ??
                  "Search User Email"
                }
              />
            )}
          />
        </Box>
        <Box pl={2}>
          <Typography sx={{ fontWeight: 600, color: "text.primary", mb: 1 }}>
            {allTranslations?.role?.[userLang] ?? "Role"}:
          </Typography>
          <Autocomplete
            disabled
            size="small"
            sx={{ width: 250 }}
            options={users?.map((option) => option.EmployeeType || "No Role")}
            // value={employmentType || "Role Disabled"}
            onChange={handleEmploymentTypeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  allTranslations?.search?.[userLang] +
                    " " +
                    allTranslations?.role?.[userLang] ?? "Search Role"
                }
              />
            )}
          />
        </Box>
      </Stack>
      <Box px={4}>
        {/* {isPeerRequest && (
          <>
            <Divider
              sx={{
                width: "100%",
                bgcolor:
                  theme.palette.mode === "light"
                    ? theme.palette.primary.mainGradient2
                    : "background.secondary",
                border: `1px light`,
                borderColor: "primary.border",
              }}
            />
            <Stack
              direction={"row"}
              mt={2}
              px={3}
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                {requestType === "peerRequest" && (
                  <Checkbox checked={selectedSelf === true} />
                )}

                <Typography
                  sx={{
                    fontSize: 15,
                    color: "text.primary",
                    fontWeight: 600,
                  }}
                >
                  {allTranslations?.user?.[userLang] ?? "User"} ID :
                </Typography>
                <Typography sx={{ fontSize: 15, color: "text.secondary" }}>
                  {allTranslations?.profile?.Uid?.[userLang] ?? profile?.Uid} (
                  {allTranslations?.self?.[userLang] ?? "Self"})
                </Typography>
              </Stack>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <Typography
                  sx={{
                    fontSize: 15,
                    color: "text.primary",
                    fontWeight: 600,
                  }}
                >
                  {allTranslations?.user_name?.[userLang] ?? "User Name"} :
                </Typography>
                <Typography sx={{ fontSize: 15, color: "text.secondary" }}>
                  {allTranslations?.profile?.FullName?.[userLang] ??
                    profile?.FullName}{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <Typography
                  sx={{
                    fontSize: 15,
                    color: "text.primary",
                    fontWeight: 600,
                  }}
                >
                  {allTranslations?.email?.[userLang] ?? "Email"} :
                </Typography>
                <Typography sx={{ fontSize: 15, color: "text.secondary" }}>
                  {allTranslations?.profile?.Email?.[userLang] ??
                    profile?.Email}{" "}
                </Typography>
              </Stack>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <Typography
                  sx={{
                    fontSize: 15,
                    color: "text.primary",
                    fontWeight: 600,
                  }}
                >
                  {allTranslations?.manager_name?.[userLang] ?? "Manager Name"}:
                </Typography>
                <Typography sx={{ fontSize: 15, color: "text.secondary" }}>
                  {allTranslations?.profile?.Manager?.[userLang]
                    ? profile?.Manager
                    : "N/A"}{" "}
                </Typography>
              </Stack>
            </Stack>
          </>
        )} */}
        {!isPeerRequest ? (
          selectedSelf === false ? (
            <>
              <Divider
                sx={{
                  width: "100%",
                  bgcolor:
                    theme.palette.mode === "light"
                      ? theme.palette.primary.mainGradient2
                      : "background.secondary",
                  border: "1px solid",
                  borderColor: "primary.border",
                }}
              />

              <UserInfo
                details={selectedUserDetails}
                isSelf={selectedUserDetails?.Uid === profile?.Uid}
              />
            </>
          ) : (
            <>
              <Divider
                sx={{
                  width: "100%",
                  bgcolor:
                    theme.palette.mode === "light"
                      ? theme.palette.primary.mainGradient2
                      : "background.secondary",
                  border: "1px solid",
                  borderColor: "primary.border",
                }}
              />
              <UserInfo details={profile} isSelf={true} />
            </>
          )
        ) : selectedUserDetails ? (
          <>
            <Divider
              sx={{
                width: "100%",
                bgcolor:
                  theme.palette.mode === "light"
                    ? theme.palette.primary.mainGradient2
                    : "background.secondary",
                border: "0.5px solid",
                borderColor: "primary.border",
              }}
            />
            <UserInfo
              details={selectedUserDetails}
              isSelf={selectedUserDetails?.Uid === profile?.Uid}
            />
          </>
        ) : null}
        {/* dfddfdfddddddd  ddddddddddddddddddddddddddddddd */}
      </Box>
    </Box>
  );
};

export default UserFilters;
