import { Box, Stack, Badge, Tooltip, IconButton } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import Cart from "../../../pages/Cart";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MiniMenu = (props) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [cartisActive, setCartIsActive] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false); // Close the menu when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        right: 16,
        bottom: 100,
        zIndex: 1000,
      }}
    >
      {/* Initial Menu Icon */}
      {/* {!menuVisible && (
        <IconButton
          onClick={() => setMenuVisible(true)}
          sx={{
            width: "60px",
            height: "60px",
            bgcolor: theme.palette.primary.main,
            borderRadius: "50%",
            border: "1px solid #BEBEBE",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            filter: "drop-shadow(4px 4px 4px rgb(0,0,0,0.24))",
          }}
        >
          <MenuIcon
            sx={{
              fontSize: 30,
              color: "white",
            }}
          />
        </IconButton>
      )} */}

      {/* Full Mini Menu */}
      {/* {menuVisible && ( */}
      <Box
        ref={menuRef}
        sx={{
          width: "42px",
          height: "161px",
          borderRadius: "42px",
          bgcolor: "rgb(244, 244, 244)",
          border: "1px solid #BEBEBE",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          filter: "drop-shadow(4px 4px 4px rgb(0,0,0,0.24))",
        }}
      >
        <Cart
          cartisActive={cartisActive}
          setCartIsActive={setCartIsActive}
          cart={props.cart}
          setCart={props.setCart}
          addedItems={props.addedItems}
          setAddedItems={props.setAddedItems}
        />
        <Stack
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"23px"}
        >
          <Tooltip
            title={allTranslations?.cart?.[userLang] ?? "Cart"}
            placement="left"
          >
            <Badge
              badgeContent={props.cart ? props.cart.length : 0}
              color="primary"
              onClick={() => setCartIsActive(true)}
              sx={{
                "& .MuiBadge-badge": {
                  minWidth: "14px",
                  height: "14px",
                  fontSize: "10px",
                  padding: "0",
                },
              }}
            >
              <LocalMallOutlinedIcon
                sx={{
                  fontSize: 24,
                  color: "rgb(95, 99, 104)",
                  ":hover": {
                    color: "#000",
                    cursor: "pointer",
                  },
                }}
              />
            </Badge>
          </Tooltip>
          <Tooltip
            title={allTranslations?.settings?.[userLang] ?? "Settings"}
            placement="left"
          >
            <IconButton onClick={() => navigate("/admin")}>
              <SettingsOutlinedIcon
                sx={{
                  fontSize: 24,
                  color: "rgb(95, 99, 104)",
                  ":hover": {
                    color: "#000",
                    cursor: "pointer",
                  },
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={allTranslations?.nexus?.[userLang] ?? "Nexus"}
            placement="left"
          >
            <AutoAwesomeOutlinedIcon
              sx={{
                fontSize: 24,
                color: "rgb(95, 99, 104)",
                ":hover": {
                  color: "#000",
                  cursor: "pointer",
                },
              }}
            />
          </Tooltip>
        </Stack>
      </Box>
      {/* )} */}
    </Box>
  );
};

export default MiniMenu;
