import {
  Autocomplete,
  Button,
  InputAdornment,
  InputBase,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllEndUsers } from "../apiCalls/allApiCalls";
import { useTheme } from "@emotion/react";
import { tileShadows } from "./Root";
import SearchIcon from "@mui/icons-material/Search";

function ForwardRequest() {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [selectedUid, setSelectedUid] = useState("");
  const [usersData, setUsersData] = useState([]);

  const { allUsers: userDataMap } = useSelector((state) => state.users);
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  // Local States for Modals management
  const [openApproveSec, setOpenApproveSec] = useState(false);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    const response = await getAllEndUsers();
    // const response = await getUsers();
    const approvers = [
      "U0012",
      "U0020",
      "U0024",
      "U0025",
      "U0026",
      "U0002",
      "U0030",
      "U0029",
      "U0036",
    ];

    if (response) {
      const onlyApprovers = response?.data.filter((user) => {
        if (approvers.includes(user.Uid)) {
          return user;
        }
      });
      setUsersData(onlyApprovers);
    } else {
      setUsersData([]);
    }
  };

  return (
    <>
      <Stack gap={8}>
        <Stack gap={1.5}>
          <Typography
            sx={{
              color: "primary.main",
            }}
          >
            Forward request
          </Typography>
          {/* SEARCH */}
          <Box sx={{ width: 450 }}>
            <Autocomplete
              open={inputValue.length > 0}
              value={inputValue}
              freeSolo
              disableClearable
              onChange={(e, newValue) => {
                console.log("triggered onChange", newValue);
                if (newValue) {
                  const temp = newValue.split(",");
                  console.log("temp", temp);
                  const uid = temp[1]?.trim();
                  console.log("uid", uid);
                  if (userDataMap && userDataMap[uid]) {
                    console.log("uid found", userDataMap[uid]);
                    setSelectedUid(uid);
                    const stateValue = { uid };
                    // sessionStorage.setItem("orgChartUser", uid);
                    // if (location.pathname === "/reference-user") {
                    //   window.location.reload();
                    // } else {
                    //   navigate("/reference-user", { state: selectedUid });
                    // }
                    setInputValue(""); // Clear input value
                  } else {
                    console.log("uid not found");
                  }
                }
              }}
              onKeyDown={(e, newValue) => {
                if (e.key === "Enter" && newValue) {
                  e.preventDefault();
                  // Additional logic for 'Enter' key if needed
                }
              }}
              onBlur={() => {
                setInputValue(""); // Clear search when moving away
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                console.log("input change", newInputValue);
              }}
              options={usersData.map((option) => {
                return `${option.FullName}, ${option.Uid}`;
              })}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option
                    ?.toLowerCase()
                    .includes(state.inputValue?.toLowerCase())
                );
              }}
              renderInput={(params) => (
                <InputBase
                  {...params.InputProps}
                  sx={{
                    borderRadius: "6px",
                    py: 1,
                    pl: 3,
                    pr: 1,
                    backgroundColor: theme.palette.primary.mainGradient2,
                    color: theme.palette.text.light,
                    width: "100%",
                    border: `1px solid ${theme.palette.primary.border}`,
                    ...tileShadows,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    type: "search",
                  }}
                  placeholder={
                    allTranslations?.search_approver?.[userLang] ??
                    "Search Approver"
                  }
                  endAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        color: theme.palette.text.primary,
                      }}
                    >
                      <SearchIcon
                        fontSize="large"
                        sx={{
                          borderRadius: 5,
                          p: 0.5,
                        }}
                      />
                    </InputAdornment>
                  }
                />
              )}
            />
          </Box>
        </Stack>
        {!openApproveSec && (
          <CommentSection setOpenApproveSec={setOpenApproveSec} />
        )}
        {openApproveSec && <ApproveCommentsSection />}
      </Stack>
    </>
  );
}

function CommentSection({ setOpenApproveSec }) {
  return (
    <>
      <Stack
        gap={5}
        sx={{
          px: 10,
          py: 6,
          width: "600px",
          border: "1px solid black",
          borderColor: "primary.border",
        }}
      >
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          Do you want to forward selected items to user Shanawaz Hussain
          (4352958456) ?
        </Typography>
        <Stack gap={1.5}>
          <Typography
            sx={{
              color: "text.primary",
              fontWeight: 200,
            }}
          >
            comments <span style={{ color: "red" }}>*</span>
          </Typography>
          <Box
            sx={{
              border: "1px solid black",
              borderColor: "primary.border",
              borderRadius: "5px",
              height: "80px",
            }}
          ></Box>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button
              variant="contained"
              disableElevation
              sx={{
                border: "1.5px solid black",
                borderColor: "primary.border",
                borderRadius: "5px",
                bgcolor: "text.lighter",
                color: "text.primary",
                px: 3,
                py: 0,
                fontSize: "12px",
                height: "25px",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{
                bgcolor: "primary.main",
                px: 3,
                py: 0,
                fontSize: "12px",
                height: "25px",
              }}
              onClick={() => setOpenApproveSec(true)}
            >
              Yes
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

function ApproveCommentsSection() {
  return (
    <>
      <Stack
        gap={3}
        sx={{
          px: 10,
          py: 6,
          width: "600px",
          border: "1px solid black",
          borderColor: "primary.border",
        }}
      >
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          Would you like to approve Request ID 23456 for the selected users?
        </Typography>
        <Stack gap={1.5}>
          <Typography
            sx={{
              color: "text.primary",
              fontWeight: 200,
            }}
          >
            comments <span style={{ color: "red" }}>*</span>
          </Typography>
          <Box
            sx={{
              border: "1px solid black",
              borderColor: "primary.border",
              borderRadius: "5px",
              height: "80px",
            }}
          ></Box>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button
              variant="contained"
              disableElevation
              sx={{
                border: "1.5px solid black",
                borderColor: "primary.border",
                borderRadius: "5px",
                bgcolor: "text.lighter",
                color: "text.primary",
                px: 3,
                py: 0,
                fontSize: "12px",
                height: "25px",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{
                bgcolor: "primary.main",
                px: 3,
                py: 0,
                fontSize: "12px",
                height: "25px",
              }}
            >
              Approve
            </Button>
          </Stack>
        </Stack>
        <Typography
          sx={{
            color: "text.primary",
            fontSize: "12px",
          }}
        >
          Note: Individual acknowledges that their electronic signature is the
          legally binding equivalent of the signer's handwritten signature.
        </Typography>
      </Stack>
    </>
  );
}

export default ForwardRequest;
