import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const Notifications = ({ handleCloseMenu, anchorEl }) => {
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const [notifications, setNotifications] = useState([
    {
      status: allTranslations?.approved?.[userLang] ?? "APPROVED",
      description:
        "We are pleased to inform you that your (request ID) for Jira access has been approved. Welcome aboard!",
      time: "14:00",
      date: "20-08-2024",
    },
    {
      status: allTranslations?.rejected?.[userLang] ?? "REJECTED",
      time: "15:00",
      date: "20-08-2024",
      description:
        "We regret to inform you that your (Request ID) for Jira access has been denied.",
    },
    {
      status: allTranslations?.expired?.[userLang] ?? "EXPIRED",
      time: "09:00",
      date: "20-08-2024",
      description:
        "Please be advised that your Jira access has expired, Request ID. Please renew it if needed.",
    },
    {
      status: allTranslations?.expired?.[userLang] ?? "EXPIRED",
      time: "18:00",
      date: "20-08-2024",
      description:
        "Please be advised that your Jira access has expired, Request ID. Please renew it if needed.",
    },
  ]);

  const clearAllNotifications = () => {
    setNotifications([]);
  };

  const deleteNotification = (index) => {
    setNotifications((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Box
        width={400}
        maxHeight={400}
        px={2}
        py={1}
        sx={{
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack direction={"row"} justifyContent={"space-between"} mb={1}>
          <Typography fontSize={15} fontWeight={600}>
            {allTranslations?.notifications?.[userLang] ?? "Notifications"}
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontSize: 10,
              textTransform: "capitalize",
              bgcolor: "primary.main",
            }}
            onClick={clearAllNotifications}
          >
            {allTranslations?.clear_all?.[userLang] ?? "Clear all"}
          </Button>
        </Stack>
        {!notifications.length > 0 ? (
        // {notifications.length > 0 ? (
          <>
            <Typography fontWeight={600} mb={1}>
              {allTranslations?.request_status_update?.[userLang] ??
                "Request Status Update!!"}
            </Typography>
            {notifications.map((data, index) => (
              <MenuItem key={index} sx={{ mt: 1 }}>
                <Stack direction={"row"} gap={2}>
                  <Avatar />
                  <Box flex={1}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      mb={1}
                    >
                      <Typography fontWeight={600} fontSize={14}>
                        {data.status}
                      </Typography>
                      <Typography fontSize={12}>{data.time}</Typography>
                    </Stack>
                    <Typography
                      sx={{
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        textAlign: "justify",
                        fontSize: 12,
                      }}
                    >
                      {data.description}
                    </Typography>
                    <Typography mt={1} fontSize={12}>
                      {data.date}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => deleteNotification(index)}
                    sx={{
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </MenuItem>
            ))}
          </>
        ) : (
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={2}
          >
            <Typography fontSize={14} fontWeight={600}>
              {allTranslations?.no_notifications?.[userLang] ?? "No notifications"}
            </Typography>
          </Box>
        )}
      </Box>
    </Menu>
  );
};

export default Notifications;
