import {
  Box,
  Button,
  Container,
  Stack,
  CircularProgress,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
  InputBase,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import MiniMenu from "../components/common/miniMenu/MiniMenu";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  approveDeprovisioning,
  disableProductsUnlock,
  fetchRequestDetails,
  forwardRequest,
  getUserDetailsByUid,
  updateRequest,
  updateRequestStatus,
} from "../apiCalls/allApiCalls";
import { toast } from "react-toastify";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ApproveDrawer from "../components/approver/ApproverDrawer";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UserDetails from "../components/common/approver/UserDetails";
import RequestDetails from "../components/common/approver/RequestDetails";
import RequestsDetailsTable from "../components/common/approver/RequestsDetailsTable";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { getAllEndUsers } from "../apiCalls/allApiCalls";
import { tileShadows } from "./Root";
import SearchIcon from "@mui/icons-material/Search";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  minHeight: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ApproveRequestDetails = ({ path }) => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { user: profile } = useSelector((state) => state.users);
  const params = useParams();
  const [details, setDetails] = useState();
  const [productDetailsFiltered, setProductDetailsFiltered] = useState([]);
  const [userDetails, setUserDetails] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  // console.log(selectedRows);
  const [loading, setLoading] = useState(true);
  const [drawerType, setDrawerType] = useState(""); // Loading state for the whole page
  //For Approver Drawer
  const [inputValue, setInputValue] = useState("");

  // Local States for Feeback Modals management
  const [openForwardSec, setOpenForwardSec] = useState(false);

  const [open, setOpen] = useState(false);
  const toggleDrawer = (open) => () => {
    setOpen(open);
  };
  const { state: receivedData } = useLocation();

  const navigate = useNavigate();
  const theme = useTheme();
  const today = dayjs().startOf("day");
  // Modal States

  useEffect(() => {
    // Fetch both the request details and user details
    const getRequestDetails = async () => {
      try {
        const response = await fetchRequestDetails(params.reqId);
        if (response?.status === 200) {
          setDetails(response.data);
          const prodDeets = response.data?.requestData.productsData;
          if (receivedData && receivedData.isDelegation) {
            setProductDetailsFiltered(
              prodDeets.filter(
                (item) =>
                  item.productDetails.approvalID === receivedData.delegatorId
              )
            );
            // console.log(
            //   "first",
            //   prodDeets.filter(
            //     (item) =>
            //       item.productDetails.approvalID === receivedData.delegatorId
            //   )
            // );
          } else {
            setProductDetailsFiltered(
              prodDeets.filter(
                (item) => item.productDetails.approvalID === profile?.Uid
              )
            );
            // console.log(
            //   "first",
            //   prodDeets.filter(
            //     (item) => item.productDetails.approvalID === profile?.Uid
            //   )
            // );
          }
        } else {
          throw new Error("Failed to fetch request details");
        }
      } catch (error) {
        console.error("Failed to fetch request details:", error);
      }
    };

    const getUsersDetails = async (requestorId) => {
      try {
        const response = await getUserDetailsByUid(requestorId);
        if (response?.status === 200) {
          setUserDetails(response.data);
        } else {
          throw new Error("Failed to fetch user details");
        }
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };

    const fetchData = async () => {
      await getRequestDetails();
      if (details) {
        let id = details.raisedForId
          ? details.raisedForId
          : details.requestorId;
        await getUsersDetails(id);
      }
      // Set loading to false once both requests are completed
      setLoading(false);
    };

    fetchData();
  }, [params.reqId, details?.requestorId]);

  const getMainStatus = (rows) => {
    const statuses = rows.map((row) => row.status);

    if (statuses.every((status) => status === "Approved")) {
      return "Approved";
    }

    if (statuses.every((status) => status === "Rejected")) {
      return "Rejected";
    }

    if (
      statuses.some((status) => status === "Approved") &&
      statuses.some((status) => status === "Rejected")
    ) {
      return "In Progress";
    }

    return "In Progress"; // Default case if none of the conditions match
  };

  //  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  async function handleForwardRequest(newApprovalId) {
    let oldApprovalId;
    // forward the request from profile?.Uid to newApprovalId
    if (receivedData && receivedData.isDelegation) {
      oldApprovalId = receivedData.delegatorId;
      console.log(
        `Selected Approver where request ${params.reqId} is getting forwarded to ${newApprovalId} from ${receivedData.delegatorId}`
      );
    } else {
      oldApprovalId = profile?.Uid;
      console.log(
        `Selected Approver where request ${params.reqId} is getting forwarded to ${newApprovalId} from ${profile?.Uid}`
      );
    }

    try {
      const res = await forwardRequest(
        params.reqId,
        oldApprovalId,
        newApprovalId
      );
      navigate("/approver/requests");
    } catch (error) {
      console.error(error);
    }
  }

  const handleApprove = async (requestType, selectedRows) => {
    try {
      // Update status of selected rows to "Approved"
      const updatedRows = rows.map((row) =>
        selectedRows.some((selected) => selected.id === row.id)
          ? { ...row, status: "Approved" }
          : row
      );
      console.log(updatedRows, "updatedRows");

      const body = {
        requestID: details?.requestId,
        productDetails: selectedRows.map((row) =>
          row.risk === true && !path.includes("compliance")
            ? {
                productid: row.productID,
                status: "movedToCompliance",
                approvercomments: "Approved by approver & moved to compliance",
              }
            : {
                productid: row.productID,
                status: "Approved",
                approvercomments: "Approved by approver", //handle for compliance manager as well
              }
        ),
      };

      const getStatusAndComments = (requestType) => {
        if (requestType === "Deprovisioning User") {
          return { status: "true", approverComments: "Approved by approver" };
        } else if (requestType === "Unlocking User") {
          return { status: "false", approverComments: "Approved by approver" };
        }
        return { status: null, approverComments: null }; // For other request types
      };

      if (
        requestType === "Deprovisioning User" ||
        requestType === "Unlocking User"
      ) {
        // Extract productIds from selectedRows
        const productIds = selectedRows.map((row) => row.productID);
        const { status, approverComments } = getStatusAndComments(requestType);

        // Create the data objects for API calls
        const data = {
          Uid: details?.raisedForId
            ? details?.raisedForId
            : details?.requestorId,
          productIds: productIds,
          status,
        };

        const data1 = {
          requestID: details?.requestId,
          productDetails: selectedRows.map((row) => ({
            productid: row.productID,
            status: "Approved",
            approvercomments: approverComments,
          })),
        };

        const data2 = {
          requestID: details?.requestId,
          mainStatus: "Approved",
        };

        console.log(data, "grc data");
        // Call API for Deprovisioning or Unlocking
        await approveDeprovisioning(data);
        const grcResponse = await updateRequest(details?.requestId, data1);

        if (requestType === "Unlocking User") {
          const grcDisabledStatus = {
            Uid: details?.raisedForId
              ? details?.raisedForId
              : details?.requestorId,
            productIds: productIds,
            status: "false",
          };
          await disableProductsUnlock(grcDisabledStatus);
        }

        if (grcResponse.status === 201) {
          await updateRequestStatus(details?.requestId, data2);
          toast.success("Request(s) Approved successfully.");
          navigate("/approver");
        }
      } else {
        // Handle other request types
        const response = await updateRequest(details?.requestId, body);

        if (response.status === 201) {
          // Determine the main request status including all rows
          const mainStatus = getMainStatus(updatedRows);

          // Update the main request status
          const mainRequestBody = {
            requestID: details?.requestId,
            mainStatus,
          };
          const statusResponse = await updateRequestStatus(
            details?.requestId,
            mainRequestBody
          );

          if (statusResponse.status === 201) {
            toast.success("Request(s) Approved successfully.");
            navigate("/approver");
          }
        }
      }
    } catch (error) {
      toast.error(
        <div>
          Failed to Approve the request(s)
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            contact support
          </a>
          .
        </div>
      );
      console.error("Failed to Approve the request(s)", error);
    }
  };
  // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  const handleReject = async (requestType, selectedRows) => {
    try {
      // Update status of selected rows to "Rejected"
      const updatedRows = rows.map((row) =>
        selectedRows.some((selected) => selected.id === row.id)
          ? { ...row, status: "Rejected" }
          : row
      );

      const body = {
        requestID: details?.requestId,
        productDetails: selectedRows.map((row) => ({
          productid: row.productID,
          status: "Rejected",
          approvercomments: "Rejected by approver",
        })),
      };

      if (requestType === "Deprovisioning User") {
        // Extract productId from each object in selectedRows
        const productIds = selectedRows.map((row) => row.productID);

        let data1 = {
          requestID: details?.requestId,
          productDetails: selectedRows.map((row) => ({
            productid: row.productID,
            status: "Rejected",
            approvercomments: "Rejectd by approver",
          })),
        };
        let data2 = {
          requestID: details?.requestId,
          mainStatus: "Rejected",
        };

        // await approveDeprovisioning(data);
        const grcResponce = await updateRequest(details?.requestId, data1);
        if (grcResponce.status === 201) {
          await updateRequestStatus(details?.requestId, data2);
          toast.success("Request(s) Rejected successfully.");
          navigate("/approver");
        }
      }

      const response = await updateRequest(details?.requestId, body);

      if (response.status === 201) {
        // Determine main request status including all rows
        const mainStatus = getMainStatus(updatedRows);

        // Update the main request status
        const mainRequestBody = {
          requestID: details?.requestId,
          mainStatus,
        };

        await updateRequestStatus(details?.requestId, mainRequestBody);

        toast.success("Request(s) Rejected successfully.");
        navigate("/approver");
      }
    } catch (error) {
      toast.error(
        <div>
          Failed to Reject the request(s)
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            contact support
          </a>
          .
        </div>
      );
      console.error("Failed to Reject the request(s)", error);
    }
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "productName",
      headerName: allTranslations?.product_name?.[userLang] ?? "Product Names",
      flex: 1,
      width: 500,
      resizable: false,
      sortable: false,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "status",
      headerName: allTranslations?.product_name?.[userLang] ?? "Status",
      flex: 1,
      resizable: false,
      sortable: false,
      width: 150,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "approver",
      headerName: allTranslations?.approver?.[userLang] ?? "Approver",
      flex: 1,
      resizable: false,
      sortable: false,
      width: 150,
    },
    ...(details?.requestType !== "Deprovisioning User"
      ? [
          {
            headerClassName: "super-app-theme--header",
            field: "validFrom",
            headerName: allTranslations?.valid_from?.[userLang] ?? "Valid From",
            flex: 1,
            resizable: false,
            sortable: false,
            width: 150,
            renderCell: (params) => (
              <Box mt={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputProps={{ disableUnderline: true }}
                    value={
                      params.row.validFrom ? dayjs(params.row.validFrom) : null
                    }
                    onChange={(newDate) => {
                      // Handle the date change logic here
                      console.log("New Valid From Date:", newDate);
                    }}
                    slotProps={{ textField: { variant: "standard" } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{ disableUnderline: true }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            ),
            // console.log(params, "hello"),
          },
          {
            headerClassName: "super-app-theme--header",
            field: "validTill",
            headerName: allTranslations?.valid_till?.[userLang] ?? "Valid Till",
            flex: 1,
            resizable: false,
            sortable: false,
            width: 150,
            renderCell: (params) => (
              <Box mt={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{ textField: { variant: "standard" } }}
                    minDate={today}
                    value={
                      params.row.validTill
                        ? dayjs(params.row.validTill, "DD-MM-YYYY")
                        : null
                    }
                    onChange={(newDate) => {
                      // Handle the date change logic here
                      console.log("New Valid Till Date:", newDate);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={false} // Ensure there is no error outline
                        helperText="" // Clear any potential helper text
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            ),
          },
          {
            headerClassName: "super-app-theme--header",
            field: "risk",
            headerName: allTranslations?.risk?.[userLang] ?? "Risk",
            flex: 1,
            resizable: false,
            sortable: false,
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
              <Box mt={1}>
                {params.row.risk ? (
                  <ReportGmailerrorredIcon color="error" />
                ) : (
                  <CheckCircleOutlineIcon color="success" />
                )}
              </Box>
            ),
          },
        ]
      : []),
  ];

  const rows = productDetailsFiltered?.map((row, index) => ({
    // details?.requestData.productsData?.map((row, index) => ({
    id: index,
    productName: row.productName || row.productDetails.productName,
    status:
      row.status === "movedToCompliance"
        ? "Moved To Compliance"
        : row.status || "In Progress",
    validFrom: row.validFrom, // Dates in the format DD-MM-YYYY
    validTill: row.validTill,
    approver: row.approver || row.productDetails.approver.toUpperCase(),
    productID: row.productId,
    risk: row.risk || row.productDetails.risk,
  }));
  // : details?.requestData.productsData
  //     ?.map((row, index) => ({
  //       id: index,
  //       productName: row.productName || row.productDetails.productName,
  //       status: row.status || "In Progress",
  //       validFrom: row.validFrom, // Dates in the format DD-MM-YYYY
  //       validTill: row.validTill,
  //       approver: row.approver || row.productDetails.approver.toUpperCase(),
  //       productID: row.productId,
  //       risk: row.risk || row.productDetails.risk,
  //     }))
  //     .filter((rowData) => rowData.risk === true) || [];

  const handleValidate = (type) => {
    setDrawerType(type); // Set the type of drawer being opened
    toggleDrawer(true)();
  };

  const handleSelectionChange = (newSelectionModel) => {
    const selectedRowData = newSelectionModel.map((id) =>
      rows.find((row) => row.id === id)
    );
    setSelectedRows(selectedRowData);
    // console.log("Selected Rows:", selectedRowData); // Debugging purposes
  };
  return (
    <Box>
      {loading ? ( // Show full-page loader while data is being fetched
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1300, // Ensures it's on top
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container
          sx={(theme) => ({
            p: 4,
            bgcolor:
              theme.palette.mode === "dark"
                ? "background.default"
                : "transparent",
            borderRadius: 5,
            minHeight: "100vh",
            minWidth: "90vw",
            mt: 3,
            ml: 3,
          })}
        >
          <Box mb={3}>
            <BreadCrumbs />
          </Box>
          <MiniMenu />

          <UserDetails details={details} userDetails={userDetails} />
          <RequestDetails details={details} />
          <RequestsDetailsTable
            rows={rows}
            columns={columns}
            setSelectedRows={setSelectedRows}
            handleSelectionChange={handleSelectionChange}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            mt={3}
            mr={4}
          >
            <Button
              variant="contained"
              color="success"
              onClick={() => handleValidate("approve")}
              disabled={selectedRows.length === 0}
            >
              {allTranslations?.approve?.[userLang] ?? "Approve"}
            </Button>
            <Button
              variant="outlined"
              sx={{ color: "text.primary", borderColor: "text.primary" }}
              onClick={() => handleValidate("reject")}
              disabled={selectedRows.length === 0}
            >
              {allTranslations?.reject?.[userLang] ?? "Reject"}
            </Button>
            <SearchApproverModal
              value={allTranslations?.forward?.[userLang] ?? "Forward"}
              setOpenForwardSec={setOpenForwardSec}
              inputValue={inputValue}
              setInputValue={setInputValue}
              receivedData={receivedData}
              profile={profile}
              handleValidate={handleValidate}
            />
            <ForwardCommentsModal
              openForwardSec={openForwardSec}
              setOpenForwardSec={setOpenForwardSec}
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleForwardRequest={handleForwardRequest}
            />
          </Stack>
        </Container>
      )}
      <ApproveDrawer
        open={open}
        setOpen={setOpen}
        toggleDrawer={toggleDrawer}
        selectedRows={selectedRows}
        type={drawerType}
        requestType={details?.requestType}
        handleApprove={handleApprove}
        handleReject={handleReject}
        inputValue={inputValue}
        handleForwardRequest={handleForwardRequest}
        requestId={params.reqId}
        // type = type === "Approver" ? "Approver" : "Reject"
      />
    </Box>
  );
};

const SearchApproverModal = ({
  value,
  setOpenForwardSec,
  inputValue,
  setInputValue,
  receivedData,
  profile,
  handleValidate,
}) => {
  // Modal States
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Search Approver States
  const theme = useTheme();
  const [selectedUid, setSelectedUid] = useState("");
  const [usersData, setUsersData] = useState([]);

  const { allUsers: userDataMap } = useSelector((state) => state.users);
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    const response = await getAllEndUsers();
    // const response = await getUsers();
    let approvers = [
      "U0012",
      "U0020",
      "U0024",
      "U0025",
      "U0026",
      "U0002",
      "U0030",
      "U0029",
      "U0036",
    ];

    const filteredApprovers = approvers.filter((approver) => {
      if (receivedData && receivedData.isDelegation) {
        // Filter out `receivedData.delegatorId` if `isDelegation` is true
        return approver !== receivedData.delegatorId;
      } else {
        // Filter out `profile?.Uid` if `isDelegation` is not true
        return approver !== profile?.Uid;
      }
    });

    if (response) {
      const onlyApprovers = response?.data.filter((user) => {
        if (filteredApprovers.includes(user.Uid)) {
          return user;
        }
      });
      setUsersData(onlyApprovers);
    } else {
      setUsersData([]);
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={() => handleOpen()}>
        {value}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack gap={1.5} sx={style}>
          <Typography
            sx={{
              color: "primary.main",
            }}
          >
            {allTranslations?.forward_request?.[userLang] ?? "Forward request"}
          </Typography>
          {/* SEARCH */}
          <Box sx={{ width: 450 }}>
            <Autocomplete
              open={inputValue.length > 0}
              value={inputValue}
              freeSolo
              disableClearable
              onChange={(e, newValue) => {
                console.log("triggered onChange", newValue);
                if (newValue) {
                  const temp = newValue.split(",");
                  console.log("temp", temp);
                  // setOpenForwardSec(true);
                  setInputValue(temp);
                  const uid = temp[1]?.trim();
                  console.log("uid", uid);
                  if (userDataMap && userDataMap[uid]) {
                    console.log("uid found", userDataMap[uid]);
                    setSelectedUid(uid);
                    const stateValue = { uid };
                    // sessionStorage.setItem("orgChartUser", uid);
                    // if (location.pathname === "/reference-user") {
                    //   window.location.reload();
                    // } else {
                    //   navigate("/reference-user", { state: selectedUid });
                    // }
                    // setInputValue(""); // Clear input value
                    handleValidate("forward");
                    handleClose();
                  } else {
                    console.log("uid not found");
                  }
                }
              }}
              onKeyDown={(e, newValue) => {
                if (e.key === "Enter" && newValue) {
                  e.preventDefault();
                  // Additional logic for 'Enter' key if needed
                }
              }}
              onBlur={() => {
                setInputValue(""); // Clear search when moving away
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                console.log("input change", newInputValue);
              }}
              options={usersData.map((option) => {
                return `${option.FullName}, ${option.Uid}`;
              })}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option
                    ?.toLowerCase()
                    .includes(state.inputValue?.toLowerCase())
                );
              }}
              renderInput={(params) => (
                <InputBase
                  {...params.InputProps}
                  sx={{
                    borderRadius: "6px",
                    py: 1,
                    pl: 3,
                    pr: 1,
                    backgroundColor: theme.palette.primary.mainGradient2,
                    color: theme.palette.text.light,
                    width: "100%",
                    border: `1px solid ${theme.palette.primary.border}`,
                    ...tileShadows,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    type: "search",
                  }}
                  placeholder={
                    allTranslations?.search_approver?.[userLang] ??
                    "Search Approver"
                  }
                  endAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        color: theme.palette.text.primary,
                      }}
                    >
                      <SearchIcon
                        fontSize="large"
                        sx={{
                          borderRadius: 5,
                          p: 0.5,
                        }}
                      />
                    </InputAdornment>
                  }
                />
              )}
            />
          </Box>
        </Stack>
      </Modal>
    </>
  );
};

const ForwardCommentsModal = ({
  openForwardSec,
  setOpenForwardSec,
  inputValue,
  setInputValue,
  handleForwardRequest,
}) => {
  const [comments, setComments] = useState("");
  const theme = useTheme();
  const handleClose = () => {
    setOpenForwardSec(false);
    setInputValue("");
    setComments("");
  };

  return (
    <Modal
      open={openForwardSec}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        gap={4}
        sx={{
          ...style,
          px: 10,
          py: 6,
          width: "600px",
          border: "1px solid black",
          borderColor: "primary.border",
        }}
      >
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          Do you want to forward selected items to user {inputValue[0]}(
          {inputValue[1]}) ?
        </Typography>
        <Stack gap={1.5}>
          <Typography
            sx={{
              color: "text.primary",
              fontWeight: 200,
            }}
          >
            comments <span style={{ color: "red" }}>*</span>
          </Typography>

          <TextField
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            placeholder={
              // allTranslations?.type_your_comments_here?.[userLang] ??
              "Type your comments here..."
            }
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            multiline
            sx={{
              width: "100%",
              minHeight: 100,
              maxHeight: 150,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "2px",
                bgcolor: theme.palette.icons.bgLight,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "icons.bgDark",
                borderRadius: 5,
              },
              p: 1.5,
              border: "1px solid gray",
            }}
          />
          <Stack direction={"row"} justifyContent={"space-between"} mt={2}>
            <Button
              variant="contained"
              disableElevation
              sx={{
                border: "1.5px solid black",
                borderColor: "primary.border",
                borderRadius: "5px",
                bgcolor: "text.lighter",
                color: "text.primary",
                px: 3,
                py: 0,
                fontSize: "12px",
                height: "25px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{
                bgcolor: "primary.main",
                px: 3,
                py: 0,
                fontSize: "12px",
                height: "25px",
              }}
              disabled={comments ? false : true}
              onClick={() => {
                handleForwardRequest(inputValue[1].trim());
                handleClose();
              }}
            >
              Yes
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ApproveRequestDetails;
