import { configureStore } from "@reduxjs/toolkit";
import translationsReducer from "./slices/translations";
import userLanguageReducer from "./slices/userLanguage";
import usersReducer from "./slices/users";

/* eslint-disable no-underscore-dangle */
export const store = configureStore({
  reducer: {
    translations: translationsReducer,
    userLanguage: userLanguageReducer,
    users: usersReducer,
  },
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
});
/* eslint-enable */
