import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Modal,
  Typography,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniMenu from "../common/miniMenu/MiniMenu";
import BreadCrumbs from "../common/breadCrumbs/BreadCrumbs";
import { DataGrid, GridOverlay, GridToolbar } from "@mui/x-data-grid";
import {
  deleteTranslationsNexus,
  editTranslationNexus,
  getAllFeedback,
  getAllTranslations,
  insertTranslationNexus,
} from "../../apiCalls/allApiCalls";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { getGridLocalization } from "@mui/x-data-grid/utils/getGridLocalization";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { useTheme } from "@emotion/react";

const LanguageTranslations = () => {
  const theme = useTheme();
  const [feedbacksData, setFeedbackData] = useState([]);
  const [activeRow, setActiveRow] = useState("");
  const [openEditTranslation, setOpenEditTranslation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [newWord, setNewWord] = useState("");
  const [disableTranslate, setDisableTranslate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disableDeleteButton, setdisableDeleteButton] = useState(true);

  function CustomNoDataOverlay() {
    return (
      <GridOverlay>
        <Typography color="text.secondary">
          {allTranslations?.no_data_yet?.[userLang] ?? "No data yet"}
        </Typography>
      </GridOverlay>
    );
  }

  const handleRowSelection = (rowSelectionModel) => {
    setSelectedRows(rowSelectionModel);
  };

  const getTranslations = async () => {
    try {
      setIsLoading(true);
      const response = await getAllTranslations();
      if (response.status === 200) {
        setFeedbackData(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditTranslationOpen = (row) => {
    setActiveRow(row);
    setOpenEditTranslation(true);
  };

  const handleEditModalClose = () => {
    setOpenEditTranslation(false);
    setActiveRow("");
  };

  async function handleEditModalSubmit(e) {
    setDisableSubmit(true);
    e.preventDefault();
    try {
      const res = await editTranslationNexus(activeRow._id, activeRow);
      // toast.success("Tanslation updated successfully");
    } catch (error) {
      console.error(error);
    }
    handleEditModalClose();
    setDisableSubmit(false);
    setActiveRow("");
    getTranslations();
  }

  async function handleNewWordSubmit(e) {
    e.preventDefault();
    setDisableTranslate(true);
    try {
      const res = await insertTranslationNexus(newWord);
    } catch (error) {
      console.error(error);
    }
    setDisableTranslate(false);
    setNewWord("");
    getTranslations();
  }

  async function handleDelete() {
    setdisableDeleteButton(true);
    try {
      const res = await deleteTranslationsNexus(selectedRows);
    } catch (error) {
      console.error(error);
    }
    setdisableDeleteButton(false);
    setSelectedRows("");
    getTranslations();
  }

  useEffect(() => {
    getTranslations();
  }, []);

  useEffect(() => {
    if (selectedRows && selectedRows.length > 0) {
      setdisableDeleteButton(false);
    } else {
      setdisableDeleteButton(true);
    }
  }, [selectedRows]);

  // Today's date and time in "DD-MM-YYYY_HH-mm-ss" format
  const todayWithTime = dayjs().format("DD-MM-YYYY_HH-mm-ss");
  const columns = [
    {
      field: "Edit",
      headerName: allTranslations?.edit?.[userLang] ?? "Edit",
      headerClassName: "super-app-theme--header",
      resizable: false,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEditTranslationOpen(params.row)}>
            <EditNoteOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "unique_id",
      headerName: allTranslations?.key?.[userLang] ?? "Key",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "English",
      headerName: allTranslations?.english?.[userLang] ?? "English",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Telugu",
      headerName: allTranslations?.telugu?.[userLang] ?? "Telugu",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Hindi",
      headerName: allTranslations?.hindi?.[userLang] ?? "Hindi",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Spanish",
      headerName: allTranslations?.spanish?.[userLang] ?? "Spanish",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "French",
      headerName: allTranslations?.french?.[userLang] ?? "French",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "German",
      headerName: allTranslations?.german?.[userLang] ?? "German",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Chinese",
      headerName: allTranslations?.chinese?.[userLang] ?? "Chinese",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Russian",
      headerName: allTranslations?.russian?.[userLang] ?? "Russian",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Japanese",
      headerName: allTranslations?.japanese?.[userLang] ?? "Japanese",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Korean",
      headerName: allTranslations?.korean?.[userLang] ?? "Korean",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Arabic",
      headerName: allTranslations?.arabic?.[userLang] ?? "Arabic",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Italian",
      headerName: allTranslations?.italian?.[userLang] ?? "Italian",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Tamil",
      headerName: allTranslations?.tamil?.[userLang] ?? "Tamil",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Malayalam",
      headerName: allTranslations?.malayalam?.[userLang] ?? "Malayalam",
      editable: false,
      sortable: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
  ];

  // Define translations for the toolbar
  const localeText = {
    toolbarColumns: allTranslations?.columns?.[userLang] ?? "Columns",
    toolbarFilters: allTranslations?.filters?.[userLang] ?? "Filters",
    toolbarDensity: allTranslations?.density?.[userLang] ?? "Density",
    toolbarExport: allTranslations?.export?.[userLang] ?? "Export",
    toolbarQuickFilterPlaceholder:
      allTranslations?.search?.[userLang] ?? "Search",
  };

  const getRowId = (row) => row._id;

  return (
    <Box width={"95%"} m={"auto"}>
      <MiniMenu />
      <Container
        sx={{
          p: 4,
          bgcolor: "background.default",
          borderRadius: 5,
          minHeight: "80vh",
          minWidth: "100%",
          // m: "auto",
        }}
      >
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BreadCrumbs />
        </Box>
        <Box
          sx={(theme) => ({
            // height: 350,
            width: "100%",
            mt: 2,
            minHeight: 500,
            "& .super-app-theme--header": {
              backgroundColor: theme.palette.dataGrid.headerBg,
            },
          })}
        >
          <form onSubmit={handleNewWordSubmit}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
              mb={1}
            >
              <Stack gap={1} mb={3} mt={5} width={350}>
                <Typography
                  sx={{ color: theme.palette.text.bold, fontWeight: 600 }}
                >
                  {allTranslations?.translate_a_word?.[userLang] ??
                    "Translate a word"}
                  :
                </Typography>
                <Stack direction={"row"} gap={3} alignItems={"center"}>
                  <TextField
                    required
                    // fullWidth
                    name="newWord"
                    placeholder={
                      allTranslations?.enter_a_new_word?.[userLang] ??
                      "Enter a new word"
                    }
                    value={newWord}
                    onChange={(e) => setNewWord(e.target.value)}
                    id="newWord"
                    type="text"
                    size="small"
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={disableTranslate}
                    sx={{
                      bgcolor: "primary.main",
                    }}
                  >
                    {allTranslations?.translate?.[userLang] ?? "Translate"}
                  </Button>
                </Stack>
              </Stack>
              <Button
                variant="contained"
                disabled={disableDeleteButton}
                onClick={handleDelete}
                sx={{
                  bgcolor: "primary.main",
                }}
              >
                {allTranslations?.delete?.[userLang] ?? "Delete"}
              </Button>
            </Stack>
          </form>
          {!isLoading ? (
            <DataGrid
              rows={feedbacksData}
              getRowId={getRowId}
              columns={columns}
              checkboxSelection
              onRowSelectionModelChange={handleRowSelection}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, { value: -1, label: "All" }]}
              slots={{
                toolbar: GridToolbar,
                noRowsOverlay: CustomNoDataOverlay,
              }}
              sx={{
                ".css-t89xny-MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                },
                height: 500,
                // ".css-1bkcjja-MuiDataGrid-columnsManagementFooter": {
                //   borderTop: "1px solid #ffe3e3",
                // }
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  csvOptions: {
                    fileName: `User Feedback - ${todayWithTime}`,
                  },
                },
              }}
              disableRowSelectionOnClick
              localeText={localeText}
            />
          ) : (
            <Box>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Container>
      <Modal
        open={openEditTranslation}
        onClose={handleEditModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            px: 4,
            py: 6,
            overflowY: "scroll",
            maxHeight: 500,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            fontWeight={"bold"}
          >
            {allTranslations?.edit_translation?.[userLang] ??
              "Edit translation"}
          </Typography>
          <form onSubmit={handleEditModalSubmit}>
            <Stack gap={3} sx={{ p: 1, my: 3 }}>
              <TextField
                required
                fullWidth
                name="unique_id"
                label="Unique ID"
                value={activeRow.unique_id || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, unique_id: e.target.value })
                }
                id="unique_id"
                type="text"
                size="small"
                disabled
              />
              <TextField
                required
                fullWidth
                name="English"
                label="English"
                value={activeRow.English || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, English: e.target.value })
                }
                id="English"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="Telugu"
                label="Telugu"
                value={activeRow.Telugu || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, Telugu: e.target.value })
                }
                id="Telugu"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="Hindi"
                label="Hindi"
                value={activeRow.Hindi || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, Hindi: e.target.value })
                }
                id="Hindi"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="Spanish"
                label="Spanish"
                value={activeRow.Spanish || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, Spanish: e.target.value })
                }
                id="Spanish"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="French"
                label="French"
                value={activeRow.French || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, French: e.target.value })
                }
                id="French"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="German"
                label="German"
                value={activeRow.German || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, German: e.target.value })
                }
                id="German"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="Chinese"
                label="Chinese"
                value={activeRow.Chinese || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, Chinese: e.target.value })
                }
                id="Chinese"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="Russian"
                label="Russian"
                value={activeRow.Russian || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, Russian: e.target.value })
                }
                id="Russian"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="Japanese"
                label="Japanese"
                value={activeRow.Japanese || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, Japanese: e.target.value })
                }
                id="Japanese"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="Korean"
                label="Korean"
                value={activeRow.Korean || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, Korean: e.target.value })
                }
                id="Korean"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="Arabic"
                label="Arabic"
                value={activeRow.Arabic || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, Arabic: e.target.value })
                }
                id="Arabic"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="Italian"
                label="Italian"
                value={activeRow.Italian || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, Italian: e.target.value })
                }
                id="Italian"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="Tamil"
                label="Tamil"
                value={activeRow.Tamil || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, Tamil: e.target.value })
                }
                id="Tamil"
                type="text"
                size="small"
              />
              <TextField
                required
                fullWidth
                name="Malayalam"
                label="Malayalam"
                value={activeRow.Malayalam || ""}
                onChange={(e) =>
                  setActiveRow({ ...activeRow, Malayalam: e.target.value })
                }
                id="Malayalam"
                type="text"
                size="small"
              />
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              disabled={disableSubmit}
            >
              <Button
                variant="outlined"
                onClick={handleEditModalClose}
                disabled={disableSubmit}
              >
                {allTranslations?.cancel?.[userLang] ?? "Cancel"}
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  bgcolor: "primary.main",
                }}
              >
                {allTranslations?.submit?.[userLang] ?? "Submit"}
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default LanguageTranslations;
