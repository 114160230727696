import { Breadcrumbs, Stack, Typography } from "@mui/material";
import React from "react";
import { MdOutlineHome } from "react-icons/md";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useLocation, Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

const BreadCrumbs = ({ checked }) => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const location = useLocation();
  const pathname = location.pathname;
  const theme = useTheme(); // Access theme to use primary and secondary colors

  // Helper function to generate breadcrumb items based on the pathname
  const generateBreadcrumbs = (path) => {
    const pathSegments = path.split("/").filter((segment) => segment);
    const breadcrumbItems = [
      {
        label: allTranslations?.home?.[userLang] ?? "Home",
        to: "/",
        image: (
          <MdOutlineHome
            style={{ color: theme.palette.text.thin, fontSize: 30 }}
          />
        ),
      },
    ];

    // Check for the specific path /new-delegation/approve
    if (path === "/approver/all/requests") {
      breadcrumbItems.push({
        label: allTranslations?.approver?.[userLang] ?? "Approver",
        to: "/approver", // Redirect to approver delegation
      });
    } else if (path === "/new-delegation/compliance") {
      breadcrumbItems.push({
        label: allTranslations?.new_delegation?.[userLang] ?? "New Delegation",
        to: "/compliance-delegation", // Redirect to approver delegation
      });
    } else if (path.startsWith("/request-details/")) {
      breadcrumbItems.push(
        {
          label:
            allTranslations?.request_status?.[userLang] ?? "Request Status",
          to: "/request-status",
        },
        {
          label: pathSegments[1], // Assuming "REQ-abbe40" is the second segment
          to: path,
        }
      );
    } else {
      breadcrumbItems.push(
        ...pathSegments.map((segment, index) => {
          let fullPath = "/" + pathSegments.slice(0, index + 1).join("/");
          if (checked) {
            fullPath = "/bulk-upload";
          }
          // Map specific paths to desired labels
          const labels = {
            "/request-status":
              allTranslations?.request_status?.[userLang] ?? "Request Status",
            "/peer-request":
              allTranslations?.peer_request?.[userLang] ?? "Peer Request",
            "/elevated-request":
              allTranslations?.elevated_request?.[userLang] ??
              "Elevated Request",
            "/approver/requests":
              allTranslations?.request?.[userLang] ?? "Requests",
            "/compliance/requests":
              allTranslations?.request?.[userLang] ?? "Requests",
            "/new-request":
              allTranslations?.new_request?.[userLang] ?? "New Request",
            "/reference-user":
              allTranslations?.reference_user?.[userLang] ?? "Reference User",
            "/bulk-upload":
              allTranslations?.bulk_upload?.[userLang] ?? "Bulk Upload",
            "/unlock-user":
              allTranslations?.unlock_user?.[userLang] ?? "Unlock user",
            "/new-delegation":
              allTranslations?.new_delegation?.[userLang] ?? "New Delegation",
            "/approver-delegation":
              allTranslations?.approver_delegation?.[userLang] ??
              "Approver Delegation",
            "/compliance-delegation":
              allTranslations?.compliance_delegation?.[userLang] ??
              "Compliance Delegation",
            "/admin/api-exception-logs":
              allTranslations?.api_exception_logs?.[userLang] ??
              "API Exception Logs",
            "/admin/ui-exception-logs":
              allTranslations?.ui_exception_logs?.[userLang] ??
              "UI Exception Logs",
            "/admin/language-translations":
              allTranslations?.language_translations?.[userLang] ??
              "Language Translations",

            // Add more specific paths and labels as needed
          };
          return {
            label:
              labels[fullPath] ||
              segment.charAt(0).toUpperCase() + segment.slice(1),
            to: fullPath,
          };
        })
      );
    }

    return breadcrumbItems;
  };

  const breadcrumbs = generateBreadcrumbs(pathname);

  return (
    <Stack
      sx={
        theme.palette.mode === "dark"
          ? {
              background: `linear-gradient(to right, ${theme.palette.primary.mainGradient1}, ${theme.palette.primary.mainGradient2})`,
            }
          : {
              bgcolor: theme.palette.icons.bgLight,
            }
      }
      py={0.5}
      px={3}
      borderRadius={3}
      width={"fit-content"}
    >
      <Breadcrumbs
        separator={
          <KeyboardDoubleArrowRightIcon
            sx={{
              color: theme.palette.text.thin,
            }}
          />
        }
        aria-label="breadcrumb"
      >
        {breadcrumbs.map((breadcrumb, index) => (
          <Typography
            key={index}
            component={breadcrumb.to ? Link : "span"}
            to={breadcrumb.to}
            sx={{
              fontWeight: index === breadcrumbs.length - 1 ? 600 : 400,
              textDecoration: "none", // Remove underline for Links
              color:
                index === breadcrumbs.length - 1
                  ? theme.palette.text.breadCrumbs
                  : theme.palette.text.thin,
              display: "flex",
              alignItems: "center",
            }}
          >
            {breadcrumb.image && breadcrumb.label === "Home" ? (
              <span style={{ display: "flex", alignItems: "center" }}>
                {breadcrumb.image}
                <span style={{ marginLeft: 8 }}>{breadcrumb.label}</span>
              </span>
            ) : (
              allTranslations?.[breadcrumb.label.toLowerCase()]?.[userLang] ??
              breadcrumb.label
            )}
          </Typography>
        ))}
      </Breadcrumbs>
    </Stack>
  );
};

export default BreadCrumbs;
