import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
const SearchBars = ({
  usersData,
  setSelectedDelegatorOption,
  setSelectedDelegateeOption,
  selectedDelegatorOption,
  selectedDelegateeOption,
}) => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const theme = useTheme();
  const [delegatorCheckbox, setDelegatorCheckbox] = useState(null);
  const [delegateeCheckbox, setDelegateeCheckbox] = useState(null);
  const [delegatorInputValue, setDelegatorInputValue] = useState("");
  const [delegateeInputValue, setDelegateeInputValue] = useState("");

  const [selectedDelegatorFilter, setSelectedDelegatorFilter] = useState(null);
  const [selectedDelegateeFilter, setSelectedDelegateeFilter] = useState(null);

  const handleClickDelegator = (event) => {
    setDelegatorCheckbox(event.currentTarget);
  };

  const handleCloseDelegator = () => {
    setDelegatorCheckbox(null);
  };

  const openDelegatorFilters = Boolean(delegatorCheckbox);

  const handleClickDelegatee = (event) => {
    setDelegateeCheckbox(event.currentTarget);
  };

  const handleCloseDelegatee = () => {
    setDelegateeCheckbox(null);
  };

  const openDelegateeFilters = Boolean(delegateeCheckbox);

  // Filter users based on the selected filters and search input for delegator
  const filteredDelegatorUsers = usersData.filter((user) => {
    const fullName = `${user.FirstName?.toLowerCase()} ${user.LastName?.toLowerCase()}`;
    const inputValue = delegatorInputValue?.toLowerCase();

    if (inputValue.length < 2) return false;

    const isMatch =
      (selectedDelegatorFilter === "firstName" &&
        user.FirstName?.toLowerCase().includes(inputValue)) ||
      (selectedDelegatorFilter === "lastName" &&
        user.LastName?.toLowerCase().includes(inputValue)) ||
      (selectedDelegatorFilter === "email" &&
        user.Email?.toLowerCase().includes(inputValue)) ||
      (selectedDelegatorFilter === "uid" &&
        user.Uid?.toLowerCase().includes(inputValue)) ||
      (selectedDelegatorFilter === null &&
        (fullName.includes(inputValue) ||
          user.LastName?.toLowerCase().includes(inputValue) ||
          user.Email?.toLowerCase().includes(inputValue) ||
          user.Uid?.toLowerCase().includes(inputValue)));

    return isMatch;
  });

  // Filter users based on the selected filters and search input for delegatee
  const filteredDelegateeUsers = usersData.filter((user) => {
    const fullName = `${user.FirstName?.toLowerCase()} ${user.LastName?.toLowerCase()}`;
    const inputValue = delegateeInputValue?.toLowerCase();

    if (inputValue.length < 2) return false;

    const isMatch =
      (selectedDelegateeFilter === "firstName" &&
        user.FirstName?.toLowerCase().includes(inputValue)) ||
      (selectedDelegateeFilter === "lastName" &&
        user.LastName?.toLowerCase().includes(inputValue)) ||
      (selectedDelegateeFilter === "email" &&
        user.Email?.toLowerCase().includes(inputValue)) ||
      (selectedDelegateeFilter === "uid" &&
        user.Uid?.toLowerCase().includes(inputValue)) ||
      (selectedDelegateeFilter === null &&
        (fullName.includes(inputValue) ||
          user.LastName?.toLowerCase().includes(inputValue) ||
          user.Email?.toLowerCase().includes(inputValue) ||
          user.Uid?.toLowerCase().includes(inputValue)));

    return isMatch;
  });

  const handleDelegatorFilterChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedDelegatorFilter(selectedValue);
  };

  const handleDelegateeFilterChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedDelegateeFilter(selectedValue);
  };

  const handleDelegatorChange = (event, newValue) => {
    if (
      newValue &&
      selectedDelegateeOption &&
      newValue === selectedDelegateeOption
    ) {
      toast.error(
        <div>
          A user cannot be both a delegator and a delegatee.
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            contact support
          </a>
          .
        </div>
      );
      return;
    }
    setSelectedDelegatorOption(newValue);
  };

  const handleDelegateeChange = (event, newValue) => {
    if (
      newValue &&
      selectedDelegatorOption &&
      newValue === selectedDelegatorOption
    ) {
      toast.error(
        <div>
          A user cannot be both a delegator and a delegatee.
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            contact support
          </a>
          .
        </div>
      );
      setDelegateeInputValue("");
      setSelectedDelegateeOption("");
    }
    setSelectedDelegateeOption(newValue);
  };

  // Clear the delegator search and filter
  const clearDelegatorFilter = () => {
    setDelegatorInputValue("");
    setSelectedDelegatorOption(null);
    setSelectedDelegatorFilter(null);
  };

  // Clear the delegatee search and filter
  const clearDelegateeFilter = () => {
    setDelegateeInputValue("");
    setSelectedDelegateeOption(null);
    setSelectedDelegateeFilter(null);
  };

  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"} mt={5} mx={3}>
        {/* Delegator search */}
        <Stack width={"40%"} direction={"row"} gap={2} alignItems={"center"}>
          <Autocomplete
            sx={{ width: "100%" }}
            disablePortal
            options={
              filteredDelegatorUsers.length > 0
                ? filteredDelegatorUsers.map(
                    (option) =>
                      `${option.FirstName} ${option.LastName} (${option.Email}), ${option.Uid}`
                  )
                : []
            }
            // value={selectedDelegatorOption}
            inputValue={delegatorInputValue}
            onInputChange={(event, newInputValue) => {
              setDelegatorInputValue(newInputValue);
              setSelectedDelegatorOption(null);
            }}
            onChange={handleDelegatorChange}
            renderInput={(params) => (
              <InputBase
                {...params.InputProps}
                sx={{
                  borderRadius: "30px",
                  padding: 0.5,
                  pl: 3,
                  bgcolor: "background.secondary",
                  width: "100%",
                  height: 50,
                }}
                inputProps={{
                  ...params.inputProps,
                  type: "search",
                }}
                placeholder={
                  allTranslations?.search_delegator?.[userLang] ??
                  "Search Delegator"
                }
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon
                      fontSize="medium"
                      sx={{
                        borderRadius: 5,
                        p: 0.25,
                        bgcolor: "icons.bgDark",
                        color: "icons.colorActive",
                      }}
                    />
                  </InputAdornment>
                }
              />
            )}
          />
          <Box>
            <IconButton
              id="basic-button"
              aria-controls={openDelegatorFilters ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openDelegatorFilters ? "true" : undefined}
              onClick={handleClickDelegator}
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: theme.palette.icons.bgDark,
                }}
              />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={delegatorCheckbox}
              open={openDelegatorFilters}
              onClose={handleCloseDelegator}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <RadioGroup
                value={selectedDelegatorFilter}
                onChange={handleDelegatorFilterChange}
              >
                <MenuItem>
                  <Radio value="firstName" />
                  {allTranslations?.first_name?.[userLang] ?? "First Name"}
                </MenuItem>
                <MenuItem>
                  <Radio value="lastName" />
                  {allTranslations?.last_name?.[userLang] ?? "Last Name"}
                </MenuItem>
                <MenuItem>
                  <Radio value="email" />
                  {allTranslations?.email?.[userLang] ?? "Email"}
                </MenuItem>
                <MenuItem>
                  <Radio value="uid" />
                  {allTranslations?.user?.[userLang] ?? "User"} ID
                </MenuItem>
              </RadioGroup>
            </Menu>
          </Box>
          <Button
            onClick={clearDelegatorFilter}
            sx={{ fontSize: 12, textTransform: "capitalize" }}
            // variant="outlined"
          >
            {allTranslations?.clear_all?.[userLang] ?? "Clear All"}
          </Button>
        </Stack>

        {/* Delegatee search */}
        <Stack width={"40%"} direction={"row"} gap={2} alignItems={"center"}>
          <Autocomplete
            sx={{ width: "100%" }}
            disablePortal
            options={
              filteredDelegateeUsers.length > 0
                ? filteredDelegateeUsers.map(
                    (option) =>
                      `${option.FirstName} ${option.LastName} (${option.Email}), ${option.Uid}`
                  )
                : []
            }
            value={selectedDelegateeOption}
            inputValue={delegateeInputValue}
            onInputChange={(event, newInputValue) => {
              setDelegateeInputValue(newInputValue);
              setSelectedDelegateeOption(null);
            }}
            onChange={handleDelegateeChange}
            renderInput={(params) => (
              <InputBase
                {...params.InputProps}
                sx={{
                  borderRadius: "30px",
                  padding: 0.5,
                  pl: 3,
                  bgcolor: "background.secondary",
                  width: "100%",
                  height: 50,
                }}
                inputProps={{
                  ...params.inputProps,
                  type: "search",
                }}
                placeholder={
                  allTranslations?.search_delegatee?.[userLang] ??
                  "Search Delegatee"
                }
                endAdornment={
                  <InputAdornment position="start">
                    <SearchIcon
                      fontSize="medium"
                      sx={{
                        borderRadius: 5,
                        p: 0.25,
                        bgcolor: "icons.bgDark",
                        color: "icons.colorActive",
                      }}
                    />
                  </InputAdornment>
                }
              />
            )}
          />
          <Box>
            <IconButton
              id="basic-button"
              aria-controls={openDelegateeFilters ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openDelegateeFilters ? "true" : undefined}
              onClick={handleClickDelegatee}
            >
              <TuneOutlinedIcon
                sx={{ fontSize: 20, color: theme.palette.icons.bgDark }}
              />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={delegateeCheckbox}
              open={openDelegateeFilters}
              onClose={handleCloseDelegatee}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <RadioGroup
                value={selectedDelegateeFilter}
                onChange={handleDelegateeFilterChange}
              >
                <MenuItem>
                  <Radio value="firstName" />
                  {allTranslations?.first_name?.[userLang] ?? "First Name"}
                </MenuItem>
                <MenuItem>
                  <Radio value="lastName" />
                  {allTranslations?.last_name?.[userLang] ?? "Last Name"}
                </MenuItem>
                <MenuItem>
                  <Radio value="email" />
                  {allTranslations?.email?.[userLang] ?? "Email"}
                </MenuItem>
                <MenuItem>
                  <Radio value="uid" />
                  {allTranslations?.user?.[userLang] ?? "User"} ID
                </MenuItem>
              </RadioGroup>
            </Menu>
          </Box>
          <Button
            onClick={clearDelegateeFilter}
            sx={{ fontSize: 12, textTransform: "capitalize" }}
            // variant="outlined"
          >
            {allTranslations?.clear_all?.[userLang] ?? "Clear All"}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default SearchBars;
