import React, { useEffect, useState } from "react";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import {
  Box,
  Button,
  Container,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MiniMenu from "../components/common/miniMenu/MiniMenu";
import { DataGrid } from "@mui/x-data-grid";
import {
  disableProductsUnlock,
  getLockedProductsOfUsers,
  raiseRequest,
} from "../apiCalls/allApiCalls";
import { useSelector } from "react-redux";
import { toast } from "react-toastify"; // Assuming you're using react-toastify for notifications
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

const UnlockUser = () => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const theme = useTheme();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows (cart)
  const [openDialog, setOpenDialog] = useState(false); // State to control the dialog box
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );
  const [requestsData, setRequestsData] = React.useState({
    requestorId: "",
    requestType: "Unlocking User",
    status: "In Progress",
  });

  // Update requestorId once profile is available
  useEffect(() => {
    if (profile?.Uid) {
      setRequestsData((prevData) => ({
        ...prevData,
        requestorId: profile.Uid,
      }));
    }
  }, [profile]);

  // Fetch locked products data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (profile?.Uid) {
          const response = await getLockedProductsOfUsers(profile.Uid);
          const products = response.data.products;
          console.log(response);
          const transformedRows = products.map((product, index) => ({
            id: index + 1, // You can use any unique id here
            system: product.productDetails.productName,
            environment: "GRC", // Assuming a static environment for this example
            status: product.productDetails.lock ? "Inactive" : "UnInactive",
            productDetails: product,
          }));
          console.log(transformedRows);
          setRows(transformedRows);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    if (!profileLoading) {
      fetchData();
    }
  }, [profile, profileLoading]);

  // Handle submission of selected products
  async function handleSubmit() {
    try {
      if (selectedRows.length === 0) {
        toast.error(
          <div>
            Please select products to unlock!
            <a
              href="mailto:ganeshg@aapmor.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              contact support
            </a>
            .
          </div>
        );
        return;
      }

      // Check if any selected product has disabled status set to true
      const isAnyProductDisabled = selectedRows.some(
        (row) => row.productDetails.productDetails.disabled === true
      );

      if (isAnyProductDisabled) {
        toast.error(
          "Previous request is in progress. Cannot raise a new request!"
        );
        return;
      }

      const data = {
        ...requestsData,
        requestData: {
          productsData: selectedRows.map((row) => ({
            ...row.productDetails,
            status: "In Progress",
          })),
        },
      };

      const productIds = selectedRows.map(
        (row) => row.productDetails.productId
      );

      const grcDisabledStatus = {
        Uid: profile?.Uid,
        productIds: productIds,
        status: "true",
      };

      // Uncomment these lines once you're ready to make the API calls
      const response = await raiseRequest(data); // Assuming `raiseRequest` is your API call function
      if (response.status === 201) {
        toast.success("Request submitted successfully!");
        await disableProductsUnlock(grcDisabledStatus);
        console.log("disabled true");
        navigate("/"); // Perform any additional actions like navigation if needed
        setOpenDialog(false); // Close the dialog after successful submission
      } else {
        toast.error(
          <div>
            Request submission failed!
            <a
              href="mailto:ganeshg@aapmor.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              contact support
            </a>
            .
          </div>
        );
      }

      console.log(data);
    } catch (error) {
      toast.error(
        <div>
          Failed to post requests!
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            contact support
          </a>
          .
        </div>
      );
      console.error("Failed to post requests:", error);
    }
  }

  const handleOpenDialog = () => {
    if (selectedRows.length === 0) {
      toast.error(
        <div>
          Please select products to unlock!
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            contact support
          </a>
          .
        </div>
      );
      return;
    }
    setOpenDialog(true); // Open the dialog when the button is clicked
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog on cancel
  };

  const columns = [
    {
      field: "system",
      headerName: allTranslations?.system?.[userLang] ?? "System",
      headerClassName: "super-app-theme--header",
      flex: 1, // Distribute space equally
      resizable: false,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
    },
    {
      field: "environment",
      headerName: allTranslations?.environment?.[userLang] ?? "Environment",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
    },
    {
      field: "status",
      headerName: allTranslations?.status?.[userLang] ?? "Status",
      headerClassName: "super-app-theme--header",
      type: "number",
      flex: 1,
      resizable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.status === "Inactive" ? "orange" : "green",
            mt: 1,
          }}
        >
          {allTranslations?.[params.row.status]?.[userLang] ??
            params.row.status}
        </Typography>
      ),
    },
  ];

  return (
    <Box width={"95%"} m={"auto"}>
      <MiniMenu />
      <Container
        sx={{
          p: 4,
          bgcolor: "background.default",
          borderRadius: 5,
          minHeight: "80vh",
          minWidth: "100%",
          // m: "auto",
        }}
      >
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BreadCrumbs />
        </Box>

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="60vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {rows.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="40vh"
              >
                <Typography variant="h6" color="textSecondary">
                  {allTranslations?.no_data_available?.[userLang] ??
                    "No Data available"}
                  .
                </Typography>
              </Box>
            ) : (
              <>
                <Box
                  sx={(theme) => ({
                    "& .super-app-theme--header": {
                      backgroundColor: theme.palette.dataGrid.headerBg,
                    },
                    minHeight: "40%",
                  })}
                >
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    sx={{
                      "& .MuiDataGrid-cellContent": {
                        minHeight: 40,
                        maxHeight: 80,
                      },
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(ids) => {
                      const selectedRowsData = rows.filter((row) =>
                        ids.includes(row.id)
                      );
                      setSelectedRows(selectedRowsData);
                    }}
                  />
                </Box>
                <Box display={"flex"} justifyContent={"end"} mt={5}>
                  <Button
                    variant="contained"
                    onClick={handleOpenDialog}
                    disabled={!requestsData.requestorId}
                    sx={{
                      boxShadow: "none",
                      bgcolor: theme.palette.icons.bgDark,
                      color: theme.palette.text.white,
                      border: `1px solid ${theme.palette.icons.bgDark}`,
                      ":hover": {
                        border: `1px solid ${theme.palette.primary.border}`,
                        bgcolor: theme.palette.background.default,
                        color: theme.palette.text.thin,
                        boxShadow: "none",
                      },
                    }}
                  >
                    {allTranslations?.unlock_user?.[userLang] ?? "Unlock User"}
                  </Button>
                </Box>
              </>
            )}
          </>
        )}

        {/* Dialog for confirmation */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {allTranslations?.unlock_selected_system?.[userLang] + " ?" ??
              "Unlock Selected System?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {allTranslations
                ?.are_you_sure_you_want_to_submit_a_request_to_unlock_the_selected_system?.[
                userLang
              ] + " ?" ??
                "Are you sure you want to submit a request to unlock the selected system?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              color="primary"
              variant="outlined"
              sx={{
                bgcolor: theme.palette.background.default,
                color: theme.palette.text.thin,
                border: `1px solid ${theme.palette.primary.border}`,
                ":hover": {
                  bgcolor: theme.palette.icons.bgDark,
                  color: theme.palette.icons.colorActive,
                  border: `1px solid ${theme.palette.icons.bgDark}`,
                },
                boxShadow: "none",
              }}
            >
              {allTranslations?.cancel?.[userLang] ?? "Cancel"}
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              autoFocus
              variant="contained"
              sx={{
                boxShadow: "none",
                bgcolor: theme.palette.icons.bgDark,
                color: theme.palette.text.white,
                border: `1px solid ${theme.palette.icons.bgDark}`,
                ":hover": {
                  border: `1px solid ${theme.palette.primary.border}`,
                  bgcolor: theme.palette.background.default,
                  color: theme.palette.text.thin,
                  boxShadow: "none",
                },
              }}
            >
              {allTranslations?.submit?.[userLang] ?? "Submit"}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default UnlockUser;
