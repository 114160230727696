import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Typography, Box, Stack, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { formatDistanceToNow } from "date-fns";
import { useSelector } from "react-redux";
import ShortcutIcon from "@mui/icons-material/Shortcut";

const RequestStatusTile = ({
  path,
  requestId,
  requestorId,
  issueDate,
  status,
  userName,
  customFontSize,
  requestType,
  showRenewalMessage,
  createdAt,
  updatedAt,
  delegation,
  delegatorId,
}) => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const isUpdated =
    new Date(createdAt).getTime() < new Date(updatedAt).getTime();
  const displayDate = isUpdated ? updatedAt : createdAt;
  const label = isUpdated ? "Updated" : "Created";
  
  const handleClick = () => {
    const encodedRequestId = encodeURIComponent(requestId);
    navigate(path, {
      state: {
        isDelegation: delegation,
        delegatorId: delegatorId,
      },
    });
  };
  const statusColor =
    status === "In Progress"
      ? theme.palette.status.pending
      : status === "Rejected"
      ? theme.palette.status.rejected
      : theme.palette.status.approved;

  const statuschange = (status) => {
    switch (status) {
      case "In Progress":
        return (
          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
            sx={{ color: "yellow" }}
          >
            <Typography color="grey">
              {allTranslations?.in_progress?.[userLang] ?? "In Progress"}
            </Typography>
            <ScheduleIcon fontSize="12px" />
          </Stack>
        );
      case "Approved":
        return (
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography color="grey">
              {allTranslations?.approved?.[userLang] ?? "Approved"}
            </Typography>
            <CheckCircleIcon sx={{ color: "green" }} fontSize="12px" />
          </Stack>
        );
      case "Rejected":
        return (
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography color="grey">
              {" "}
              {allTranslations?.rejected?.[userLang] ?? "Rejected"}
            </Typography>
            <CancelIcon sx={{ color: "red" }} fontSize="12px" />
          </Stack>
        );
      default:
        return <Typography color="grey">{status}</Typography>;
    }
  };

  return (
    <Card
      sx={{
        cursor: "pointer",
        borderRadius: 2,
        height: 150,
        position: "relative", // Added to position the time ago indicator
        border: `1px solid ${theme.palette.primary.border}`,
        // filter: `drop-shadow(0px 2px 4px 0px ${theme.palette.primary.dropShadow})`,
        bgcolor: theme.palette.tiles.bg,
        "&:hover": {
          transform: "scale(1.02)",
        },
      }}
      onClick={handleClick}
    >
      <CardContent>
        <Box>
          <Stack sx={{ alignItems: "center" }} direction={"row"} gap={1}>
            {/* <Typography>
              {allTranslations?.status?.[userLang] ?? "Status"} :
            </Typography>
            <Stack
              sx={{ alignItems: "center" }}
              direction={"row"}
              px={1}
              // gap={}
            >
              {statuschange(status)}
            </Stack> */}
            <Typography color={theme.palette.text.light}>
              {status === "Approved"
                ? allTranslations?.approved?.[userLang] ?? "Approved"
                : status === "Rejected"
                ? allTranslations?.rejected?.[userLang] ?? "Rejected"
                : allTranslations?.in_progress?.[userLang] ?? "In Progress"}
            </Typography>
            <Box
              sx={{
                bgcolor: statusColor,
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              .
            </Box>
            {delegation && (
              <ShortcutIcon
                sx={{ fontSize: "17px", color: "text.light", ml: 1.5 }}
              />
            )}
          </Stack>
          <Grid container mt={2} rowGap={2}>
            <Grid item xs={6}>
              <Stack direction={"row"}>
                <Typography fontSize={12} color={theme.palette.text.thin}>
                  {allTranslations?.user?.[userLang] ?? "User"} ID :{" "}
                </Typography>
                <Typography
                  fontSize={12}
                  color={theme.palette.text.light}
                  px={1}
                >
                  {requestorId}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={"row"}>
                <Typography fontSize={12} color={theme.palette.text.thin}>
                  {allTranslations?.request?.[userLang] ?? "Request"} ID :{" "}
                </Typography>
                <Typography
                  fontSize={12}
                  color={theme.palette.text.light}
                  px={1}
                >
                  {requestId}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={"row"}>
                <Typography fontSize={12} color={theme.palette.text.thin}>
                  {allTranslations?.type?.[userLang] ?? "Type"} :{" "}
                </Typography>
                <Typography
                  fontSize={12}
                  px={1}
                  color={theme.palette.text.light}
                >
                  {allTranslations?.[
                    requestType?.toLowerCase().split(" ").join("_")
                  ]?.[userLang] ?? requestType}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={"row"}>
                <Typography fontSize={12} color={theme.palette.text.thin}>
                  {allTranslations?.raised_on?.[userLang] ?? "Raised On"} :{" "}
                </Typography>
                <Typography
                  fontSize={12}
                  color={theme.palette.text.light}
                  px={1}
                >
                  {issueDate}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      {showRenewalMessage && (
        <Box px={2} pb={1} pt={0.5} sx={{ bgcolor: "primary.main" }}>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <WarningAmberIcon fontSize="12px" sx={{ color: "white" }} />
            <Typography sx={{ fontSize: 11, color: "white" }}>
              This product will{" "}
              <span style={{ fontWeight: 600 }}>expire soon</span>!! Please
              renew if required.
            </Typography>
          </Stack>
        </Box>
      )}
      {/* Time ago indicator */}
      <Box
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          // bgcolor: "background.paper",
          // borderRadius: 1,
          padding: "2px 4px",
          // boxShadow: 1,
        }}
      >
        <Typography fontSize={10} color="text.secondary">
          {`${label} ${formatDistanceToNow(new Date(displayDate), {
            addSuffix: true,
          })}`}
          {/* {new Date(createdAt) < new Date(updatedAt)
            ? `Updated ${formatDistanceToNow(new Date(updatedAt), {
                addSuffix: true,
              })}`
            : `Created ${formatDistanceToNow(new Date(createdAt), {
                addSuffix: true,
              })}`} */}
        </Typography>
      </Box>
    </Card>
  );
};

export default RequestStatusTile;
