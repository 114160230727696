import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#EE4731",
        ...theme.applyStyles("dark", {
          backgroundColor: "#107346",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));

export default function CustomizedSwitches({ checked, handleSwitchChange }) {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const theme = useTheme();

  // Convert isBulkUpload to boolean
  const isBulkUpload = sessionStorage.getItem("bulkUpload") === "true";

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
        <Typography
          sx={{
            color: !checked ? "text.light" : "text.lighter",
          }}
        >
          {allTranslations?.new_change?.[userLang] ?? "New Change"}
        </Typography>

        <AntSwitch
          checked={isBulkUpload}
          onChange={handleSwitchChange}
          inputProps={{ "aria-label": "ant design" }}
        />

        <Typography
          sx={{
            color: isBulkUpload ? "text.light" : "text.lighter",
          }}
        >
          {allTranslations?.bulk_upload?.[userLang] ?? "Bulk Upload"}
        </Typography>
      </Stack>
    </FormGroup>
  );
}
