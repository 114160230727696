import { Stack } from "@mui/system";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import ShieldIcon from "@mui/icons-material/Shield";
import logo from "../../assets/logo.svg";
import lightThemeLogo from "../../assets/vertexLogo.svg";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import PeopleIcon from "@mui/icons-material/People";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import { useSelector } from "react-redux";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import { useTheme } from "@emotion/react";

const SideNavbar = () => {
  const theme = useTheme();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null); // State for managing menu
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );

  // Configuration for nav items
  const navItems = [
    {
      path: "/",
      label: `${allTranslations?.home?.[userLang] ?? "Home"}`,
      activeIcon: <PersonOutlineOutlinedIcon sx={{ fontSize: 32 }} />,
      inactiveIcon: <PersonIcon sx={{ fontSize: 32 }} />,
    },
    {
      path: "/approver",
      label: `${allTranslations?.approver?.[userLang] ?? "Approver"}`,
      activeIcon: <ChecklistOutlinedIcon sx={{ fontSize: 32 }} />,
      inactiveIcon: <ChecklistIcon sx={{ fontSize: 32 }} />,
    },
    {
      path: "/compliance",
      label: `${allTranslations?.compliance?.[userLang] ?? "Compliance"}`,
      activeIcon: <ShieldIcon sx={{ fontSize: 32 }} />,
      inactiveIcon: <ShieldIcon sx={{ fontSize: 32 }} />,
    },
    // {
    //   path: "/admin",
    //   label: "Admin",
    //   activeIcon: <AdminPanelSettingsIcon sx={{ fontSize: 32 }} />,
    //   inactiveIcon: <AdminPanelSettingsIcon sx={{ fontSize: 32 }} />,
    // },
  ];

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget); // Set anchor for the menu
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  return (
    <Stack
      alignItems={"center"}
      sx={{
        bgcolor: theme.palette.background.sideNav,
        width: "100%",
        minHeight: "100vh",
        height: "100%",
        py: 4,
        px: 2,
      }}
      spacing={3}
    >
      {/* Logo */}
      <Link to="/">
        <img
          src={theme.palette.mode === "light" ? lightThemeLogo : logo}
          style={{ width: 40, marginBottom: 10 }}
          alt="Logo"
        />
      </Link>

      {/* Navigation Links */}
      {navItems.map((item) => {
        // Check if the current location matches the path or is a child of the path
        const isActive =
          location.pathname === item.path ||
          location.pathname.startsWith(item.path + "/");

        return (
          <Link to={item.path} key={item.path}>
            <Tooltip title={item.label} placement="right">
              {isActive
                ? React.cloneElement(item.activeIcon, {
                    sx: {
                      bgcolor: "primary.main",
                      color: theme.palette.icons.colorActive,
                      borderRadius: "5px",
                      p: "3px",
                    },
                  })
                : React.cloneElement(item.inactiveIcon, {
                    sx: { color: theme.palette.icons.colorInactive },
                  })}
            </Tooltip>
          </Link>
        );
      })}
    </Stack>
  );
};

export default SideNavbar;
