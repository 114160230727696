import React, { useEffect, useState } from "react";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { Link, useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useSelector } from "react-redux";
import {
  deleteDelegation,
  getAllEndUsers,
  getDelegationsByUid,
  updateDelegation,
} from "../apiCalls/allApiCalls";
import { toast } from "react-toastify";

const Delegation = ({ uid }) => {
  const { allTranslations } = useSelector((state) => state.translations);
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const location = useLocation();
  console.log(location.pathname);
  const { user: profile } = useSelector((state) => state.users);
  const [delegations, setDelegations] = useState([]);
  const [activeRow, setActiveRow] = useState("");
  const [, setIsLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);

  const getEncryptedValue = () => {
    if (location.pathname === "/approver/delegation") {
      return "approver";
    } else if (location.pathname === "/compliance/delegation") {
      return "compliance";
    }
    return null; // or return a default value if needed
  };

  const encryptedValue = getEncryptedValue();

  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
  const handleDeleteConfirmOpen = () => setOpenDeleteConfirm(true);
  const handleDeleteConfirmClose = () => setOpenDeleteConfirm(false);
  const [openEditDelegation, setOpenEditDeligation] = React.useState(false);

  const handleEditDelegationClose = () => setOpenEditDeligation(false);
  const [delegationEdit, setDelegationEdit] = useState({
    validFrom: null,
    validTo: null,
    status: "",
    delegationType: "",
  });

  async function fetchDelegations(uid) {
    try {
      setIsLoading(true);
      let type;
      if (location.pathname === "/approver/delegation") {
        type = "approver";
      } else if (location.pathname === "/compliance/delegation") {
        type = "compliance";
      }
      const res = await getDelegationsByUid(uid, type);
      console.log("delegation res", res?.data?.data);
      setDelegations(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const getAllUsers = async () => {
    // setIsLoading(true);
    const response = await getAllEndUsers();
    // const response = await getUsers();
    if (response.status === 200) {
      // console.log("resp", response)
      setUsersData(response);
    } else {
      setUsersData([]);
    }
    // setIsLoading(false);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (profile && profile.Uid) {
      fetchDelegations(profile?.Uid);
    }
  }, [profile]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDelegationEdit({ ...delegationEdit, [name]: value });
  };

  const handleDateChange = (name, date) => {
    setDelegationEdit({ ...delegationEdit, [name]: date });
  };

  dayjs.extend(customParseFormat); // Enable custom parsing

  const handleEditDelegationOpen = (row) => {
    // console.log(usersData, "usersData");
    const validFrom = row.validFrom ? dayjs(row.validFrom) : null;
    const validTill = row.validTill ? dayjs(row.validTill) : null;

    // Check if validFrom is a valid dayjs object
    if (validFrom && !validFrom.isValid()) {
      console.log("Invalid validFrom date:", row.validFrom);
    }

    // Check if validTill is a valid dayjs object
    if (validTill && !validTill.isValid()) {
      console.log("Invalid validTill date:", row.validTill);
    }

    let fullName, email;

    usersData?.data?.map((data) => {
      if (data.Uid === row.delegateeId) {
        fullName = data.FullName;
        email = data.Email;
      }
    });

    setDelegationEdit({
      validFrom: validFrom && validFrom.isValid() ? validFrom : null,
      validTill: validTill && validTill.isValid() ? validTill : null,
      status: row.status || "",
      type: row.type || "",
      userId: row.delegateeId || "",
      fullName: fullName || "",
      email: email || "",
      // fullName: row.delegateeName || "",
      // email: row.delegateeEmail || "",
    });

    setActiveRow(row._id);

    setOpenEditDeligation(true);
  };

  const handleUpdateDelegation = async (row) => {
    try {
      const response = await updateDelegation(activeRow, delegationEdit);
      console.log("update delegation res", response);
      if (profile && profile.Uid) {
        fetchDelegations(profile?.Uid);
      }
      handleEditDelegationClose();
      if (response.status === 201) {
        toast.success("Delegation updated successfully");
        // setDelegations(delegations.filter((row) => row._id === activeRow));
        // setActiveRow("");
      }
    } catch (error) {
      console.log(`Delete Delegation Error`, error);
    }
  };

  const handleDeleteDelegation = async () => {
    try {
      const res = await deleteDelegation(activeRow);
      console.log("del res", res);
      if (res.status === 200) {
        toast.success("Delegation deleted successfully");
        setDelegations(delegations.filter((row) => row._id === activeRow));
        setActiveRow("");
      }
      handleDeleteConfirmClose();
      if (profile && profile.Uid) {
        fetchDelegations(profile?.Uid);
      }
    } catch (error) {
      console.log(`Delete Delegation Error`, error);
    }
  };

  const columns = [
    {
      field: "delegatorId",
      headerName: allTranslations?.delegator?.[userLang] ?? "Delegator",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
    },
    {
      field: "delegateeId",
      headerName: allTranslations?.delegatee?.[userLang] ?? "Delegatee",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
    },
    {
      field: "validFrom",
      headerName: allTranslations?.valid_from?.[userLang] ?? "Valid From",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params) => `${dayjs(params.value).format("DD-MM-YYYY")}`,
      // <Typography>{dayjs(params.value).format("DD-MM-YYYY")}</Typography>
    },
    {
      field: "validTill",
      headerName: allTranslations?.valid_to?.[userLang] ?? "Valid To",
      headerClassName: "super-app-theme--header",
      resizable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => `${dayjs(params.value).format("DD-MM-YYYY")}`,
      // <Typography>{dayjs(params.value).format("DD-MM-YYYY")}</Typography>
    },
    {
      field: "status",
      headerName: allTranslations?.status?.[userLang] ?? "Status",
      headerClassName: "super-app-theme--header",
      resizable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) =>
        params.row.status === "active" ? `Active` : `Inactive`,
    },
    {
      field: "Edit",
      headerName: allTranslations?.edit?.[userLang] ?? "Edit",
      headerClassName: "super-app-theme--header",
      resizable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEditDelegationOpen(params.row)}>
            <EditNoteOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "Delete",
      headerName: allTranslations?.delete?.[userLang] ?? "Delete",
      headerClassName: "super-app-theme--header",
      resizable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setActiveRow(params.row._id);
            handleDeleteConfirmOpen();
          }}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      ),
    },
  ];

  // if (isLoading || (copyRequestDetails && isProductsLoading)) {
  //   return (
  //     <Box
  //       sx={{
  //         // bgcolor: "background.default",
  //         padding: "15px",
  //         margin: 6,
  //         borderRadius: 3,
  //         px: 3,
  //       }}
  //     >
  //       <CircularProgress />
  //     </Box>
  //   );
  // }
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        padding: "20px",
        minWidth: "90vw",
        minHeight: "100vh",
        // maxHeight: "100vh",
        margin: 2,
        borderRadius: 3,
        color: "white",
      }}
    >
      <Box sx={{ mb: 2 }}>
        <BreadCrumbs />
      </Box>
      <Box mx={3} mt={5}>
        {delegations && delegations?.length > 0 ? (
          <Box sx={{ height: 400, width: "100%" }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={1}
            >
              <Typography variant="h5" mb={2} sx={{ color: "text.primary" }}>
                {allTranslations?.history?.[userLang] ?? "History"}
              </Typography>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <PersonAddAltOutlinedIcon />
                <Link
                  to={`/${encryptedValue}/delegation/new-delegation`}
                  style={{ color: "text.primary" }}
                >
                  <Typography
                    sx={{
                      fontSize: 20,
                      color: "text.primary",
                      textDecoration: "underline",
                    }}
                  >
                    {allTranslations?.add_delegation?.[userLang] ??
                      "Add Delegation"}
                  </Typography>
                </Link>
              </Stack>
            </Stack>
            <Box
              sx={(theme) => ({
                "& .super-app-theme--header": {
                  backgroundColor: theme.palette.dataGrid.headerBg,
                },
              })}
            >
              <DataGrid
                rows={delegations}
                columns={columns}
                getRowId={(row) => row._id} // Custom row ID function
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                // checkboxSelection
                disableRowSelectionOnClick
              />
            </Box>
          </Box>
        ) : (
          <>
            <Typography variant="h5" mb={2} sx={{ color: "text.primary" }}>
              {allTranslations?.history?.[userLang] ?? "History"}
            </Typography>
            <Box
              sx={{
                // border: "1px solid",
                height: 200,
                borderRadius: 5,
                margin: "auto",
                bgcolor: "background.secondary",
              }}
            >
              <Stack
                sx={{
                  // m: "auto",
                  // width: "100%",
                  textAlign: "center",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // bgcolor: "pink",
                  gap: 2,
                  // mt: 3,
                  pt: 3,
                  // height: "100%",
                }}
              >
                <Typography sx={{ fontSize: 20, color: "text.primary" }}>
                  {allTranslations?.no_data_yet?.[userLang] ?? "No Data Yet"}
                </Typography>
                <PersonAddAltOutlinedIcon
                  sx={{ margin: "auto", fontSize: 30, color: "primary.main" }}
                />
                <Link
                  to={`/${encryptedValue}/delegation/new-delegation`}
                  style={{ color: "text.primary" }}
                >
                  <Typography
                    sx={{
                      fontSize: 20,
                      color: "text.primary",
                      textDecoration: "underline",
                    }}
                  >
                    {allTranslations?.add_delegation?.[userLang] ??
                      "Add Delegation"}
                  </Typography>
                </Link>
              </Stack>
            </Box>
          </>
        )}
      </Box>
      {/* DELETE dialog box */}
      <Dialog
        open={openDeleteConfirm}
        onClose={handleDeleteConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {allTranslations?.are_you_sure_want_to_delete?.[userLang] + "?" ??
            "Are you sure want to delete ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {allTranslations
              ?.this_will_delete_the_delegation_and_all_associated_requests_will_be_removed?.[
              userLang
            ] ??
              "This will delete the delegation and all associated requests will be removed"}
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            sx={{ color: "text.primary" }}
            onClick={() => {
              setActiveRow("");
              handleDeleteConfirmClose();
            }}
          >
            {allTranslations?.cancel?.[userLang] ?? "Cancel"}
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteDelegation}
            autoFocus
            color="error"
          >
            {allTranslations?.delete?.[userLang] ?? "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit Dialog */}
      <Dialog
        open={openEditDelegation}
        onClose={handleEditDelegationClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: 800,
              borderRadius: 3,
            },
          },
        }}
      >
        <DialogTitle>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h6" color={"secondary.main"}>
              {allTranslations?.delegation_edit?.[userLang] ??
                "Delegation Edit"}
            </Typography>
            <IconButton onClick={handleEditDelegationClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack>
                <Typography>
                  {allTranslations?.user?.[userLang] ?? "User"} ID
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  value={delegationEdit.userId || "N/A"}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <Typography>
                  {allTranslations?.full_name?.[userLang] ?? "Full Name"}
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  value={delegationEdit.fullName || "N/A"}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <Typography>
                  {allTranslations?.email?.[userLang] ?? "Email"}
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  value={delegationEdit.email || "N/A"}
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack>
                <Typography>
                  {allTranslations?.valid_from?.[userLang] ?? "Valid From"}
                  <span style={{ color: "red" }}> *</span>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    value={delegationEdit.validFrom}
                    onChange={(date) => handleDateChange("validFrom", date)}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack>
                <Typography>
                  {allTranslations?.valid_to?.[userLang] ?? "Valid To"}
                  <span style={{ color: "red" }}> *</span>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    value={delegationEdit.validTill}
                    onChange={(date) => handleDateChange("validTill", date)}
                    shouldDisableDate={(date) => {
                      if (!delegationEdit.validFrom) {
                        return false;
                      }
                      const isBeforeValidFrom = date.isBefore(
                        delegationEdit.validFrom,
                        "day"
                      );
                      const isAfterMaxRange = date.isAfter(
                        delegationEdit.validFrom.add(30, "day"),
                        "day"
                      );
                      return isBeforeValidFrom || isAfterMaxRange;
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack>
                <Typography>
                  {allTranslations?.status?.[userLang] ?? "Status"}
                  <span style={{ color: "red" }}> *</span>
                </Typography>
                <FormControl margin="dense" fullWidth>
                  <Select
                    name="status"
                    value={delegationEdit.status}
                    onChange={handleInputChange}
                    fullWidth
                  >
                    <MenuItem value="active">
                      {allTranslations?.active?.[userLang] ?? "Active"}
                    </MenuItem>
                    <MenuItem value="inactive">
                      {allTranslations?.inactive?.[userLang] ?? "Inactive"}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <Typography>
                  {allTranslations?.delegation_type?.[userLang] ??
                    "Delegation Type"}
                  <span style={{ color: "red" }}> *</span>
                </Typography>
                <FormControl margin="dense" fullWidth>
                  <Select
                    name="type"
                    value={delegationEdit.type}
                    onChange={handleInputChange}
                    fullWidth
                  >
                    <MenuItem value="accessControl">
                      {allTranslations?.access_control?.[userLang] ??
                        "Access Control"}
                    </MenuItem>
                    <MenuItem value="processControl">
                      {allTranslations?.process_control?.[userLang] ??
                        "Process Control"}
                    </MenuItem>
                    <MenuItem value="both">
                      {allTranslations?.both?.[userLang] ?? "Both"}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mb: 2 }}>
          <Button
            variant="text"
            sx={{ color: "text.primary" }}
            onClick={handleEditDelegationClose}
          >
            {allTranslations?.cancel?.[userLang] ?? "Cancel"}
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateDelegation} // Update function for saving data
            autoFocus
            color="success"
          >
            {allTranslations?.update?.[userLang] ?? "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Delegation;
