import { useTheme } from "@emotion/react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import EllipsisWithTooltip from "../EllipsisWithTooltip";
import React, { useEffect, useRef, useState } from "react";
import { tileShadows } from "../../../pages/Root";
import { useSelector } from "react-redux";

const RequestDetails = ({ details }) => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const theme = useTheme();
  return (
    <>
      <Typography fontWeight={600} color="text.primary" gutterBottom>
        {allTranslations?.request_details?.[userLang] ?? "Request Details"}
      </Typography>
      <Box
        sx={(theme) => ({
          p: 3,
          borderRadius: 5,
          color: "text.primary",
          ...tileShadows,
          backdropFilter: "blur(4px)",
          boxShadow: `0px -4px ${theme.palette.icons.bgLight}, 0px 2px 4px 0px #0000001A`,
          bgcolor:
            theme.palette.mode === "light"
              ? theme.palette.primary.mainGradient2
              : "background.secondary",
        })}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Stack direction="row" spacing={1}>
              <Typography fontWeight={500}>
                {allTranslations?.request?.[userLang] ?? "Request"} ID:
              </Typography>
              {/* <Typography>{details?.requestId}</Typography> */}
              <EllipsisWithTooltip
                text={details?.requestId}
                customWidth={150}
              />
            </Stack>
          </Grid>
          {/* <Grid item xs={4}>
      <Stack direction="row" spacing={1}>
        <Typography fontWeight={500}>Requestor:</Typography>
        <Typography>{details?.requestor ?? "Ganesh"}</Typography>
      </Stack>
    </Grid> */}
          <Grid item xs={3}>
            <Stack direction="row" spacing={1}>
              <Typography fontWeight={500}>
                {allTranslations?.request_type?.[userLang] ?? "Request Type"}:
              </Typography>
              <EllipsisWithTooltip
                text={
                  allTranslations?.[
                    details?.requestType?.split(" ").join("_").toLowerCase()
                  ]?.[userLang] ?? details?.requestType
                }
                customWidth={150}
              />
            </Stack>
          </Grid>
          {/* <Grid item xs={4}>
        <Stack direction="row" spacing={1}>
          <Typography fontWeight={500}>Requestor ID:</Typography>
          <Typography>{details?.requestorId}</Typography>
        </Stack>
      </Grid> */}
          <Grid item xs={6}>
            <Stack direction="row" spacing={1}>
              <Typography fontWeight={500}>
                {allTranslations?.request_reason?.[userLang] ??
                  "Request Reason"}
                :
              </Typography>
              {/* <Typography>{details?.requestData?.comments}</Typography> */}
              <EllipsisWithTooltip
                text={details?.requestData?.comments}
                customWidth={400}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};


export default RequestDetails;
