import React, { useEffect } from "react";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { fetchUserLanguage } from "../redux/slices/userLanguage";
import {
  fetchAllTranslations,
  fetchLangTranslations,
} from "../redux/slices/translations";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Navbar from "../components/navbar/Navbar";
import Cookies from "js-cookie";
import {
  fetchHierarchy,
  fetchUser,
  fetchAllUsers,
} from "../redux/slices/users/index.js";
import { Box } from "@mui/system";
import { jwtDecode } from "jwt-decode";
import SideNavbar from "../components/sideNavbar/SideNavbar.jsx";

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

// LIGHT THEME ONLY ------------------------------------------

export const heroGradientLight = {
  background:
    "linear-gradient(87.81deg, rgba(255, 255, 255, 0) 0.19%, rgba(211, 72, 29, 0.2) 90.81%)",
};

export const tileShadows = {
  backdropFilter: "blur(4px)",
  boxShadow: "0px 2px 4px 0px #0000001A",
};

export const burgerMenuShadow = {
  boxShadow: "4px 4px 8px 0px #F4353552",
};

// ------------------------------------------------------------

const Home = () => {
  const dispatch = useDispatch();
  const [mode, setMode] = React.useState("light");
  const token = Cookies.get("jwtToken");
  const decodedToken = jwtDecode(token);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light"
            ? {
                // palette values for light mode
                primary: {
                  main: "#EE4731",
                  mainGradient1: "transparent",
                  mainGradient2: "#fff",
                  darkerShade: "#fff",
                  grayShade: "#CACACA",
                  dropShadow: "#0000001a",
                  border: "#cacaca",
                },
                secondary: {
                  main: "#EE4731",
                  // new
                  light: "#F3D8D0",
                },
                success: {
                  main: "#EE4731",
                  tileGradient1: "#fff",
                  tileGradient2: "#F3D8D0",
                },
                divider: "#EE4731",
                // theme.palette.text.thin
                text: {
                  primary: "#000000",
                  secondary: "#3D4147",
                  breadCrumbs: "#691207",
                  contrastText: "#fff",
                  // NEW
                  thin: "#161616",
                  bold: "#212121",
                  light: "#424242",
                  lighter: "#e5e5e5",
                  white: "#fff",
                },
                background: {
                  default: "#ffffff",
                  secondary: "#F9F9F9",
                  contrast: "#000000",
                  // NEW
                  primary: "#F4F4F4",
                  sideNav: "#262626",
                },
                icons: {
                  // NEW
                  bgDark: "#EE4731",
                  bgLight: "#ffe3e3",
                  colorActive: "#ffffff",
                  colorInactive: "#d3d3d3",
                },
                tiles: {
                  bg: "#fbfbfb",
                },
                table: {
                  textLight: "#616161",
                  headerBg: "#f3d8d0",
                  rowActiveBg: "#efefef",
                },
                dataGrid: {
                  headerBg: "#ffe3e3",
                },
                status: {
                  pending: "#1e90ff",
                  approved: "#4caf50",
                  rejected: "red",
                },
              }
            : {
                // palette values for dark mode
                primary: {
                  main: "#107346",
                  mainGradient1: "#1C382C",
                  mainGradient2: "#1E1F23",
                  darkerShade: "#1C372B",
                  border: "#000",
                },
                secondary: {
                  main: "#13DD7C",
                  mainGradient1: "#1C382C",
                  mainGradient2: "#1E1F23",
                  contrastText: "#fff",
                },
                success: {
                  main: "#13DD7C",
                  tileGradient1: "#276555",
                  tileGradient2: "#0A7743",
                },
                divider: "#13DD7C",
                text: {
                  primary: "#fff",
                  secondary: "#858585",
                  breadCrumbs: "#ffffff",
                  contrastText: "#000",
                  // NEW
                  thin: "#858585",
                  bold: "#13DD7C",
                  light: "#fff",
                  lighter: "#2c2c2e",
                  white: "#fff",
                },
                table: {
                  textLight: "#ffffff",
                  headerBg: "#107346",
                  rowActiveBg: "#efefef",
                },
                dataGrid: {
                  headerBg: "#ffe3e3",
                },
                background: {
                  default: "#141118",
                  secondary: "#2C2C2E",
                  paper: "#2C2C2E",
                  sideNav: "#000",
                },
                icons: {
                  // NEW
                  bgDark: "#EE4731",
                  bgLight: "#13DD7C",
                  colorActive: "#ffffff",
                  colorInactive: "#d3d3d3",
                },
                tiles: {
                  bg: "#141118",
                },
                status: {
                  pending: "#1e90ff",
                  approved: "#4caf50",
                },
              }),
        },
        components: {
          MuiDataGrid: {
            styleOverrides: {
              root: {
                border: "1px solid #cacaca", // Global border style
                "& .MuiDataGrid-withBorderColor": {
                  borderColor: "#cacaca",
                },
                "& .MuiDataGrid-columnSeparator": {
                  color: "#cacaca",
                },
                "--DataGrid-rowBorderColor": "#cacaca",
                borderColor: "#cacaca",
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  backgroundColor: "#ffe3e3",
                },
                // "& .MuiDataGrid-columnsManagementFooter": {
                //   backgroundColor: "#ffe3e3",
                // },
              },
            },
          },
          MuiTableContainer: {
            styleOverrides: {
              root: {
                "& .MuiTableCell-root": {
                  borderBottom: "none",
                },
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: ({ ownerState }) => ({
                ...(ownerState.variant === "contained" &&
                  ownerState.color === "primary" && {
                    backgroundColor: "#107346",
                    color: "#fff",
                  }),
              }),
            },
          },
          MuiTextField: {
            styleOverrides: {
              root: ({ ownerState }) => ({
                "&.MuiDataGrid-toolbarQuickFilter .MuiInput-underline:before": {
                  borderBottom: "1px solid #ffe3e3",
                },
                ...(ownerState.variant === "contained" &&
                  ownerState.color === "primary" && {
                    backgroundColor: "#202020",
                    color: "#fff",
                  }),
              }),
            },
          },
        },
      }),
    [mode]
  );

  async function fetchTranslations() {
    dispatch(fetchLangTranslations());
  }

  async function fetchAllTranslationsNexus() {
    dispatch(fetchAllTranslations());
  }

  async function fetchUserLangPreference() {
    dispatch(fetchUserLanguage(decodedToken.Uid));
  }

  async function fetchUserProfile() {
    dispatch(fetchUser(decodedToken.Uid));
  }

  async function fetchUsers() {
    dispatch(fetchAllUsers());
  }

  async function fetchUserHierarchy() {
    dispatch(fetchHierarchy());
  }

  useEffect(() => {
    fetchTranslations();
    fetchUserLangPreference();
    fetchUserProfile();
    fetchUsers();
    fetchUserHierarchy();
    fetchAllTranslationsNexus();
    // console.log(`location in root`, location);
    // dispatch("U-9ddc48");
    // console.log(`first`);
  }, [dispatch]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Box
          sx={
            theme.palette.mode === "dark"
              ? {
                  background: `linear-gradient(to right bottom, #223434, #276756)`,
                  color: "white",
                  borderRadius: "8px",
                  // height: "141px",
                }
              : {
                  bgcolor: "#f4f4f4",
                  color: "white",
                  borderRadius: "8px",
                }
          }
        >
          <Grid container>
            <Grid item xs={0.6}>
              <SideNavbar />
            </Grid>
            <Grid item container xs={11.4} gap={2.5}>
              <Grid
                item
                xs={12}
                // bgcolor={"pink"}
                sx={{
                  height: "60px", // Ensure height is consistent
                  "&.MuiGrid-root.MuiGrid-item": {
                    p: 0,
                    m: 0,
                  },
                }}
              >
                <Navbar />
              </Grid>
              <Grid
                item
                container
                xs={12}
                // bgcolor={"pink"}
                // height={"fit-content"}
                // columnGap={2}
                sx={{
                  // alignItems: "center",
                  justifyContent: "center",
                  "&.MuiGrid-root.MuiGrid-item": {
                    p: 0,
                    m: 0,
                  },
                }}
              >
                <Outlet />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const Root = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    const authAppUrl = process.env.REACT_APP_AUTH_URI;
    const redirectUri = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
    window.location.href = `${authAppUrl}?redirect_uri=${redirectUri}&app=vertex`;
  };

  const token = Cookies.get("jwtToken");

  return token === undefined ? handleLogin() : <Home />;
};

export default Root;
