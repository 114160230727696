import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Stack, Typography, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import CartTable from "../components/cartTable/CartTable";

export default function Cart(props) {
  const theme = useTheme();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    props.setCartIsActive(open);
  };

  const list = (anchor) => (
    <Box
      sx={{
        bgcolor: theme.palette.mode === "dark" ? "#00402A" : "",
        width: "100%",
        py: 0
      }}
    >
      <Stack direction={"row"} justifyContent={"end"} px={2} py={1}>
        <IconButton onClick={toggleDrawer(anchor, false)} size="small">
          <CloseIcon  />
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        // minWidth={"100%"}
        justifyContent="center"
        alignItems="start"
        // justifyContent={"stretch"}
        height={"65vh"}
        // pb={3}
      >
        <Box
          sx={{
            // paddingTop: "26px",
            // pl: "49px",
            // pb: "5%",
            width:"80%"
            // bgcolor:"pink"
          }}
          role="presentation"
          onKeyDown={toggleDrawer(anchor, false)}
        >
          {/* <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          ></Stack> */}

          <CartTable
            cart={props.cart}
            setCart={props.setCart}
            addedItems={props.addedItems}
            setAddedItems={props.setAddedItems}
          />
        </Box>
      </Stack>
    </Box>
  );

  return (
    <Drawer
      anchor="bottom"
      open={props.cartisActive}
      onClose={toggleDrawer("bottom", false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "70%", // Drawer takes full width
          maxHeight: "75%",
          maxWidth: "80%", // Restrict width to 60% of the screen
          // height: "91%", // Set the height of the drawer to 60%
          borderRadius: "16px 16px 0 0", // Rounded edges at the top
          margin: "0 auto", // Center the drawer horizontally
          boxSizing: "border-box",
          overflow:"clip",
        },
      }}
    >
      {list("bottom")}
    </Drawer>
  );
}
