import AxiosInstance from "./axiosInstance";

const baseURL = process.env.REACT_APP_LOGIC_HOST;
export const axiosInstance = AxiosInstance(baseURL);
const baseUrlAuthX = process.env.REACT_APP_AUTH_API;
const baseUrlNexus = process.env.REACT_APP_NEXUS_API;
export const axiosInstanceAuthx = AxiosInstance(baseUrlAuthX);
export const axiosInstanceNexus = AxiosInstance(baseUrlNexus);
const baseUrlGrc = process.env.REACT_APP_GRC_WORKER_API;
export const axiosInstanceGrc = AxiosInstance(baseUrlGrc);
