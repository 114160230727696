import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import MiniMenu from "../components/common/miniMenu/MiniMenu";
import { useTheme } from "@emotion/react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchRequestDetails } from "../apiCalls/allApiCalls";
import { DataGrid } from "@mui/x-data-grid";
import RenweDrawer from "../components/renew/RenweDrawer";
import { tileShadows } from "./Root";
import { useSelector } from "react-redux";

const RequestDetails = () => {
  const { user: userDetails, allUsers } = useSelector((state) => state.users);
  console.log(allUsers, "Allusers");
  const { user: profile } = useSelector((state) => state.users);
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const params = useParams();
  const [details, setDetails] = useState();
  const [isDetailsLoading, setIsDetailsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [, setSelectionModel] = useState([]);
  console.log(selectedRows, "selectedRows");
  const navigate = useNavigate();
  const theme = useTheme();

  // Renew Drawer
  const [isOpen, setIsOpen] = useState(false);

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [requestorDetails, setRquestorDetails] = useState();
  const [raisedForDetails, setRaisedForDetails] = useState();
  console.log(requestorDetails, raisedForDetails, profile, "user1234");
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };
  const handleRenewDrawerCLose = () => {
    handleSelectionChange([]);
    setSelectionModel([]); // Unselect all checkboxes
    setSelectedRows([]); // Optionally clear selected rows
    setIsOpen(false);
  };
  async function getRequestDetails() {
    setIsDetailsLoading(true);
    try {
      const response = await fetchRequestDetails(params.id);
      if (response?.status === 200) {
        console.log("deets", response.data);
        setDetails(response.data);
        setIsDetailsLoading(false);
      } else {
        throw new Error("Failed to fetch request details");
      }
    } catch (error) {
      console.error("Failed to fetch request details:", error);
    }
  }

  useEffect(() => {
    getRequestDetails();
  }, [params.id]);
  useEffect(() => {
    if (allUsers && details) {
      setRquestorDetails(allUsers[details?.requestorId]);
      setRaisedForDetails(allUsers[details?.raisedForId]);
    }
  }, [details, allUsers]);

  const changeIssuedDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSelectionChange = (selectionModel) => {
    // Filter rows based on selected IDs
    const selectedRowsData = rows.filter((row) =>
      selectionModel.includes(row.id)
    );

    // Log the selected rows
    console.log(selectedRowsData, "Selected Rows");

    // Update state with selected rows
    setSelectedRows(selectedRowsData);
  };

  console.log(selectedRows, "finaldata1");

  const handleRenew = () => {
    const now = new Date();

    for (const row of selectedRows) {
      if (row.validTill) {
        // Parse the validTill date from dd-mm-yyyy format
        const [day, month, year] = row.validTill.split("-");
        const validTillDate = new Date(`${year}-${month}-${day}`);
        console.log("Parsed Valid Till Date:", validTillDate);

        // Calculate the date one month before the validTillDate
        const oneMonthBeforeValidTill = new Date(validTillDate);
        oneMonthBeforeValidTill.setMonth(validTillDate.getMonth() - 1);
        console.log(
          "One Month Before Valid Till Date:",
          oneMonthBeforeValidTill
        );

        // Compare the current date with the oneMonthBeforeValidTill date
        if (now < oneMonthBeforeValidTill) {
          setSnackbarMessage(
            "Renewal is not possible more than one month before the expiry date."
          );
          setSnackbarOpen(true);
          return; // Stop further execution if renewal is not possible
        }
      }
    }

    // Open the drawer if all conditions are met
    setIsOpen(true);
  };
  console.log(profile?.uid === requestorDetails?.Uid, "user1234");
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const columns = [
    {
      field: "productName",
      headerName: allTranslations?.product_name?.[userLang] ?? "Product Names",
      headerClassName: "super-app-theme--header",
      flex: 1,
      width: 500,
      resizable: false,
      sortable: false,
    },
    {
      field: "status",
      headerName: allTranslations?.product_name?.[userLang] ?? "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "validFrom",
      headerName: allTranslations?.valid_from?.[userLang] ?? "Valid From",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "validTill",
      headerName: allTranslations?.valid_till?.[userLang] ?? "Valid Till",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "approver",
      headerName: allTranslations?.approver?.[userLang] ?? "Approver",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
      width: 150,
    },
  ];

  const rows = details?.requestData?.productsData
    ? details?.requestData?.productsData.map((row, index) => ({
        id: index,
        productName: row.productName || row.productDetails.productName,
        status: row.status || "In Progress",
        validFrom: row.validFrom,
        validTill: row.validTill,
        approver: row.approver || row.productDetails.approver,
        productID: row.productId,
        productDetails: row.productDetails,
      }))
    : details?.requestData?.map((row, index) => ({
        id: index,
        productName: row.productName || row.productDetails.productName,
        status: row.status || "In Progress",
        validFrom: row.validFrom,
        validTill: row.validTill,
        approver: row.approver || row.productDetails.approver,
        productID: row.productId,
        productDetails: row.productDetails,
      })) || [];

  // console.log(details, "rows");

  function handleCopyRequest() {
    switch (details?.requestType) {
      case "New Request":
        navigate(`/new-request/${details?.requestId}`);
        break;
      case "Peer Request":
        navigate(`/peer-request/${details?.requestId}`);
        break;
      case "Elevated Request":
        navigate(`/elevated-request/${details?.requestId}`);
        break;
      case "Reference User":
        navigate(`/new-request/${details?.requestId}`);
        break;
    }
  }

  if (isDetailsLoading) {
    return (
      <Box
        sx={{
          padding: "15px",
          margin: 6,
          borderRadius: 3,
          px: 3,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box width={"95%"}>
      <MiniMenu />
      <Container
        sx={{
          p: 4,
          bgcolor: "background.default",
          borderRadius: 5,
          minHeight: "50%",
          minWidth: "100%",
          mt: 3,
        }}
      >
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BreadCrumbs />
          {/* Deprovisioning User, Unlocking User */}
          {details?.requestType !== "Deprovisioning User" &&
            details?.requestType !== "Unlocking User" &&
            details?.requestType !== "Renew" && (
              <Button
                variant="contained"
                sx={{
                  bgcolor: "primary.main",
                }}
                onClick={handleCopyRequest}
              >
                {allTranslations?.copy_request?.[userLang] ?? "Copy Request"}
              </Button>
            )}
        </Box>
        {/* <Box
          sx={{
            p: 3,
            borderRadius: 5,
            color: "text.primary",
            bgcolor:
              theme.palette.mode === "light"
                ? theme.palette.primary.mainGradient2
                : "background.secondary",
            border: `1px solid`,
            borderColor: "primary.border",
            ...tileShadows,
          }}
        >
          <Typography fontWeight={600} color="secondary.main" gutterBottom>
            {allTranslations?.request_information?.[userLang] ??
              "Request Information"}
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={3}>
              <Stack direction="row" spacing={1}>
                <Typography fontWeight={500}>
                  {allTranslations?.request?.[userLang] ?? "Request"} ID:
                </Typography>
                <Typography>{details?.requestId}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row" spacing={1}>
                <Typography fontWeight={500}>
                  {allTranslations?.date_submitted?.[userLang] ??
                    "Date Submitted"}
                  :
                </Typography>
                <Typography>{changeIssuedDate(details?.updatedAt)}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row" spacing={1}>
                <Typography fontWeight={500}>
                  {allTranslations?.status?.[userLang] ?? "Status"}:
                </Typography>
                <Typography>
                  {allTranslations?.[
                    details?.status.split(" ").join("_").toLowerCase()
                  ]?.[userLang] ?? details?.status}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row" spacing={1}>
                <Typography fontWeight={500}>
                  {allTranslations?.request_type?.[userLang] ?? "Request Type"}:
                </Typography>
                <Typography>
                  {allTranslations?.[
                    details?.requestType.split(" ").join("_").toLowerCase()
                  ]?.[userLang] ?? details?.requestType}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box> */}
        {/* Request Details */}
        <Box sx={{ height: 250, width: "100%" }}>
          <Grid container spacing={5}>
            {/* Request Information Section */}
            <Grid item xs={6}>
              <Typography fontWeight={600} color="secondary.main" gutterBottom>
                {allTranslations?.request_information?.[userLang] ??
                  "Request Information"}
              </Typography>
              <Box
                sx={{
                  bgcolor:
                    theme.palette.mode === "light"
                      ? theme.palette.primary.mainGradient2
                      : "background.secondary",
                  border: `1px solid`,
                  borderColor: "primary.border",
                  ...tileShadows,
                  width: "100%",
                  color: "black",
                  p: 4,
                }}
              >
                <Stack spacing={2}>
                  {/* Row 1: Request ID and Date Submitted */}
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <Typography fontWeight={500}>
                        {allTranslations?.request?.[userLang] ?? "Request"} ID
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>{details?.requestId ?? "N/A"}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <Typography fontWeight={500}>
                        {allTranslations?.date_submitted?.[userLang] ??
                          "Date Submitted"}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>
                        {changeIssuedDate(details?.updatedAt) ?? "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* Row 2: Status and Request Type */}
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <Typography fontWeight={500}>
                        {allTranslations?.status?.[userLang] ?? "Status"}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>
                        {allTranslations?.[
                          details?.status.split(" ").join("_").toLowerCase()
                        ]?.[userLang] ??
                          details?.status ??
                          "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <Typography fontWeight={500}>
                        {allTranslations?.request_type?.[userLang] ??
                          "Request Type"}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>
                        {allTranslations?.[
                          details?.requestType
                            .split(" ")
                            .join("_")
                            .toLowerCase()
                        ]?.[userLang] ??
                          details?.requestType ??
                          "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* Row 3: Stage */}
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <Typography fontWeight={500}>
                        {allTranslations?.stage?.[userLang] ?? "Stage"}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>
                        {allTranslations?.[
                          details?.stage?.split(" ").join("_").toLowerCase()
                        ]?.[userLang] ??
                          details?.stage ??
                          "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Grid>

            {/* Requestor Information Section */}
            <Grid item xs={6}>
              <Typography fontWeight={600} color="secondary.main" gutterBottom>
                {profile?.Uid === requestorDetails?.Uid
                  ? allTranslations?.recipient_details?.[userLang] ??
                    "Recipient Details"
                  : allTranslations?.requestor_details?.[userLang] ??
                    "Requestor Details"}
              </Typography>
              <Box
                sx={{
                  bgcolor:
                    theme.palette.mode === "light"
                      ? theme.palette.primary.mainGradient2
                      : "background.secondary",
                  border: `1px solid`,
                  borderColor: "primary.border",
                  ...tileShadows,
                  width: "100%",
                  color: "black",
                  height: 250,
                  p: 4,
                }}
              >
                <Stack spacing={3}>
                  {profile?.Uid === requestorDetails?.Uid ? (
                    // Show Recipient Details if the logged-in user is the requestor
                    <>
                      {/* Row 1: Recipient UId */}
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography fontWeight={500}>
                            {allTranslations?.recipient?.[userLang] ??
                              "Recipient"}{" "}
                            ID
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography>
                            {allTranslations?.raisedForDetails?.Uid?.[
                              userLang
                            ] ?? raisedForDetails?.Uid}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Row 2: Recipient Name */}
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography fontWeight={500}>
                            {(allTranslations?.recipient?.[userLang] ||
                              "Recipient") +
                              " " +
                              (allTranslations?.name?.[userLang] || "Name")}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography>
                            {allTranslations?.raisedForDetails?.FullName?.[
                              userLang
                            ] ?? raisedForDetails?.FullName}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Row 3: Recipient Email */}
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography fontWeight={500}>
                            {(allTranslations?.recipient?.[userLang] ||
                              "Recipient") +
                              " " +
                              (allTranslations?.email?.[userLang] || "Email")}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography>
                            {allTranslations?.raisedForDetails?.Email?.[
                              userLang
                            ] ?? raisedForDetails?.Email}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Row 4: Recipient Department */}
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography fontWeight={500}>
                            {(allTranslations?.recipient?.[userLang] ||
                              "Recipient") +
                              " " +
                              (allTranslations?.department?.[userLang] ||
                                "Department")}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography>
                            {allTranslations?.raisedForDetails?.SpaceName?.[
                              userLang
                            ] ?? raisedForDetails?.SpaceName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    // Show Requestor Details if the logged-in user is the recipient
                    <>
                      {/* Row 1: Requestor UId */}
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography fontWeight={500}>
                            {allTranslations?.requestor?.[userLang] ??
                              "Requestor"}
                            ID
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography>
                            {allTranslations?.requestorDetails?.Uid?.[
                              userLang
                            ] ?? requestorDetails?.Uid}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Row 2: Requestor Name */}
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography fontWeight={500}>
                            {(allTranslations?.requestor?.[userLang] ??
                              "Requestor") +
                              " " +
                              (allTranslations?.name?.[userLang] || "Name")}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography>
                            {allTranslations?.requestorDetails?.FullName?.[
                              userLang
                            ] ?? requestorDetails?.FullName}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Row 3: Requestor Email */}
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography fontWeight={500}>
                            {(allTranslations?.requestor?.[userLang] ??
                              "Requestor") +
                              " " +
                              (allTranslations?.email?.[userLang] || "Email")}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography>
                            {allTranslations?.requestorDetails?.Email?.[
                              userLang
                            ] ?? requestorDetails?.Email}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Row 4: Requestor Department */}
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          <Typography fontWeight={500}>
                            {(allTranslations?.requestor?.[userLang] ??
                              "Requestor") +
                              " " +
                              (allTranslations?.department?.[userLang] ||
                                "Department")}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography>
                            {allTranslations?.requestorDetails?.SpaceName?.[
                              userLang
                            ] ?? requestorDetails?.SpaceName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            height: 400,
            width: "100%",
            mt: 10,
            // borderRadius: 5,
            // border: `1px solid`,
            // borderColor: "primary.border",
            // ...tileShadows,
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            // border={"1px solid"}
            borderColor={"primary.border"}
            borderRadius={"5px 5px 0px 0px"}
            height={60}
            mt={5}
          >
            <Typography
              sx={{
                fontSize: 16,
                px: 2,
                pt: 1,
                mb: 2,
                color: "secondary.main",
                fontWeight: 600,
              }}
            >
              {allTranslations?.product_details?.[userLang] ??
                "Product Details"}
            </Typography>
            {selectedRows.length > 0 && details?.status === "Approved" && (
              <Box mr={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "primary.main",
                  }}
                  onClick={handleRenew}
                >
                  {allTranslations?.renew?.[userLang] ?? "Renew"}
                </Button>
              </Box>
            )}
          </Stack>
          <Box
            sx={(theme) => ({
              "& .super-app-theme--header": {
                backgroundColor: theme.palette.dataGrid.headerBg,
              },
              // height: 600,
              width: "100%",
              // mt: 3,
            })}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              // sx={{ px: 2 }}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              rowSelectionModel={selectedRows.map((row) => row.id)} // Map selected rows to their ids
              onRowSelectionModelChange={(newSelection) => {
                console.log(newSelection, "ppp new");

                // Map the newSelection to the corresponding row data
                const newSelectedRows = newSelection.map((id) =>
                  rows.find((row) => row.id === id)
                );

                setSelectedRows((prevSelectedRows) => {
                  console.log(prevSelectedRows, "ppp prev");

                  // Merge the previous and new selections based on unique ids
                  const mergedSelection = prevSelectedRows
                    .filter((prevRow) => newSelection.includes(prevRow.id)) // Keep previous selections that are still selected
                    .concat(
                      newSelectedRows.filter(
                        (newRow) =>
                          !prevSelectedRows.some(
                            (prevRow) => prevRow.id === newRow.id
                          ) // Add new selections
                      )
                    );

                  return mergedSelection;
                });
              }}
            />
          </Box>
        </Box>
      </Container>
      <RenweDrawer
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        rows={selectedRows}
        setIsOpen={setIsOpen}
        requestType={details?.requestType}
        userID={details?.requestorId}
        attachments={details?.requestData?.attachmentsData}
        handleRenewDrawerCLose={handleRenewDrawerCLose}
        setSelectedRows={setSelectedRows}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="warning">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RequestDetails;
