import React, { Component } from "react";
import { connect } from "react-redux";
import { raiseUIExceptionLog } from "../apiCalls/allApiCalls";

class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.logError = this.debounce(this.logError.bind(this), 500);
  }

  debounce(func, delay) {
    let debounceTimeout;
    return function (...args) {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => func.apply(this, args), delay);
    };
  }

  getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    const browsers = {
      Edge: /Edge\/([0-9]+)/,
      Chrome: /Chrome\/([0-9]+)/,
      Firefox: /Firefox\/([0-9]+)/,
      Safari: /Safari\/([0-9]+)/,
      Opera: /Opera\/([0-9]+)/,
      IE: /Trident\/.*rv:([0-9]+)/,
    };

    for (const browser in browsers) {
      if (browsers[browser].test(userAgent)) {
        const version = userAgent.match(browsers[browser])[1];
        return `${browser} - version: ${version}`;
      }
    }
    return `Unknown browser - version: Unknown`;
  };

  detectOS = () => {
    const userAgent = navigator.userAgent;
    const platforms = [
      { name: "Windows", regex: /Windows NT ([0-9.]+)/ },
      { name: "Mac OS", regex: /Mac OS X ([0-9_]+)/ },
      { name: "Linux", regex: /Linux/ },
      { name: "iOS", regex: /(iPhone|iPad|iPod)/ },
      { name: "Android", regex: /Android ([0-9.]+)/ },
    ];

    for (const platform of platforms) {
      if (platform.regex.test(userAgent)) {
        const version = userAgent.match(platform.regex)[1];
        return `${platform.name}, version: ${version}`;
      }
    }
    return "Unknown OS";
  };

  componentDidCatch(error, errorInfo) {
   
    this.setState({ hasError: true });

    const { profile } = this.props;
    const timeStamp = new Date();
    const flowId = window.location.pathname;
    const deviceName = this.detectOS();
    const browserVersion = this.getBrowserInfo();
    const exceptionObject = {
      userName: profile.FullName,
      email: profile.Email,
      Uid: profile.Uid,
      timeStamp,
      error: error.message,
      flowId,
      deviceName,
      browserVersion,
      errorStack: errorInfo.componentStack.trim(),
    };

    // Debounced log to prevent duplicate calls
    this.logError(exceptionObject);
  }

  logError(exceptionObject) {
    raiseUIExceptionLog(exceptionObject);

  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div
          style={{
            background: "#c4321d",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
          data-testid="fallback-ui"
        >
          <h1 style={{ fontFamily: "Roboto", color: "#fff" }}>
            Something went wrong.
          </h1>
          <h2 style={{ fontFamily: "Roboto", color: "#fff" }}>
            <a style={{ color: "white" }} href="/">
              Click here
            </a>
            &nbsp; to Refresh
          </h2>
        </div>
      );
    }

    return <>{children}</>;
  }
}

// Map the Redux state to component props
const mapStateToProps = (state) => ({
  profile: state.users.user,
});

// Connect the component to Redux
export default connect(mapStateToProps)(ErrorBoundaryComponent);
