import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import ProgressBar from "../components/common/progressBar/ProgressBar";
import MiniMenu from "../components/common/miniMenu/MiniMenu";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useTheme } from "@emotion/react";

const Recertifications = () => {
  const [isActive, setIsActive] = useState(false);
  const [cart, setCart] = useState([]);

  const theme = useTheme();
  return (
    <Box>
      <MiniMenu />
      <Container
        sx={{
          p: 4,
          bgcolor: "background.default",
          borderRadius: 5,
          minHeight: "100vh",
          mt: 3,
        }}
      >
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BreadCrumbs />
        </Box>
        <Box
          sx={{
            p: 3,
            borderRadius: 5,
            color: "text.primary",
            bgcolor:
              theme.palette.mode === "light"
                ? theme.palette.primary.mainGradient2
                : "background.secondary",
          }}
        >
          <Typography fontWeight={600} color="secondary.main" gutterBottom>
            Request Information
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={3}>
              <Stack direction="row" spacing={1}>
                <Typography fontWeight={500}>Request ID:</Typography>
                <Typography>12345</Typography>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row" spacing={1}>
                <Typography fontWeight={500}>Date Submitted:</Typography>
                <Typography>01/01/2024</Typography>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row" spacing={1}>
                <Typography fontWeight={500}>Status:</Typography>
                <Typography>Pending</Typography>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row" spacing={1}>
                <Typography fontWeight={500}>Request Type:</Typography>
                <Typography>General</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={3} mt={3}>
          <Grid item xs={6}>
            <Box
              borderRadius={5}
              bgcolor="white"
              p={3}
              minHeight="80%"
              sx={{
                color: "text.primary",
                bgcolor: "background.secondary",
              }}
            >
              <Typography fontWeight={600} color="secondary.main" gutterBottom>
                Request Details
              </Typography>
              <Grid container spacing={3} pt={1}>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    <Typography fontWeight={500}>Description:</Typography>
                    <Typography></Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    <Typography fontWeight={500}>Justification:</Typography>
                    <Typography></Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    <Typography fontWeight={500}>Attachments:</Typography>
                    <Typography></Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item container xs={6}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  sx={{
                    mb: 3,
                  }}
                >
                  <Stack>
                    <Typography
                      sx={{
                        color: "#fff",
                      }}
                    >
                      Valid From
                    </Typography>
                    <DatePicker
                      label="Choose your Date"
                      sx={{
                        bgcolor: "#1C372B",
                        border: "1px solid #13DD7C",
                        borderRadius: "8px",
                      }}
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      sx={{
                        color: "#fff",
                      }}
                    >
                      Valid To
                    </Typography>
                    <DatePicker
                      label="Choose your Date"
                      sx={{
                        bgcolor: "#1C372B",
                        border: "1px solid #13DD7C",
                        borderRadius: "8px",
                      }}
                    />
                  </Stack>
                </Stack>
              </LocalizationProvider>
            </Grid>
            {/* 
            > */}
            {/* <Typography fontWeight={600} color="secondary.main" gutterBottom>
                Requestor Details
              </Typography>
              <Grid container spacing={4} pt={1}>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    <Typography fontWeight={500}>Employee Name:</Typography>
                    <Typography></Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    <Typography fontWeight={500}>Employee ID:</Typography>
                    <Typography></Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    <Typography fontWeight={500}>
                      Contact Information:
                    </Typography>
                    <Typography></Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    <Typography fontWeight={500}>Department:</Typography>
                    <Typography></Typography>
                  </Stack>
                </Grid>
              </Grid> */}
            <Grid item xs={12}>
              <Box
                borderRadius={5}
                bgcolor="white"
                p={3}
                minHeight="80%"
                sx={{
                  color: "text.primary",
                  bgcolor: "background.secondary",
                }}
              >
                <TextField
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Type your comment here..."
                  multiline
                  sx={{
                    border: "none",
                    color: "text.primary",
                  }}
                />
                <IconButton
                  component="label"
                  sx={{ mt: 1 }}
                  htmlFor="file-input"
                >
                  <AttachFileIcon sx={{ color: "rgb(175, 175, 175)" }} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                // disableElevation

                sx={{
                  px: 3,
                  bgcolor: "secondary.main",
                  color: "text.contrastText",
                }}
              >
                CLEAR ALL
              </Button>
              <Button
                variant="contained"
                disableElevation
                sx={{
                  px: 3,
                  bgcolor: "secondary.main",
                  color: "text.contrastText",
                }}
                onClick={() => setIsActive(true)}
              >
                SUBMIT
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Recertifications;
