import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  IconButton,
  Avatar,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import axios from "axios";
import { useTheme } from "@emotion/react";
import { getAllEndUsers } from "../../../apiCalls/allApiCalls"; // API call to fetch all users
import { useSelector } from "react-redux"; // Redux hook to select state
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { tileShadows } from "../../../pages/Root";

// Custom styles for scrollbar
const scrollBarStyles = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
    borderRadius: "10px",
    pr: 2,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#555",
  },
};

const OrgChart = ({ initialUserID, setInitialUserID, showMoreActive }) => {
  // Destructure user details, all users, and hierarchy from Redux store
  const {
    user: userDetails,
    allUsers,
    hierarchy,
  } = useSelector((state) => state.users);

  const theme = useTheme(); // Access the theme for styling
  const [currentUser, setCurrentUser] = useState(initialUserID); // Track the current user being displayed
  const [expandedSubordinates, setExpandedSubordinates] = useState({}); // Track which subordinates are expanded
  const [userNames, setUserNames] = useState({}); // State to store Uid-name mapping

  // Update the current user when initialUserID changes
  useEffect(() => {
    setCurrentUser(initialUserID);
  }, [initialUserID]);

  // Fetch user names from API (currently commented out)
  useEffect(() => {
    // const fetchAllUsers = async () => {
    //   try {
    //     const response = await getAllEndUsers(); // API call to get all users
    //     const users = response.data;

    //     // Map Uid to name
    //     const nameMapping = {};
    //     allUsers.forEach((user) => {
    //       nameMapping[user.Uid] = user.FirstName;
    //     });

    setUserNames(allUsers); // Set all users' data in state

    //     } catch (error) {
    //       console.error("Error fetching user names:", error);
    //     }
    //   };

    // fetchAllUsers(); // Fetch all users
  }, []);

  // Navigate to the parent (one level up)
  const handleOneLevelUp = () => {
    const newUser = hierarchy[currentUser].reportsTo; // Get the manager's ID
    if (newUser) {
      setCurrentUser(newUser); // Set the manager as the current user
      setInitialUserID(newUser); // Update the initial user ID in parent
    }
  };

  // When a subordinate is clicked, update the current user
  const handleSubordinateClick = (subordinateId) => {
    setCurrentUser(subordinateId);
    setInitialUserID(subordinateId);
  };

  // Toggle the expansion of subordinates
  const toggleSubordinate = (subordinateId) => {
    setExpandedSubordinates((prevState) => ({
      ...prevState,
      [subordinateId]: !prevState[subordinateId], // Toggle the expanded state
    }));
  };

  // Helper function to find user by their ID
  const findUserById = (Uid) => hierarchy[Uid];

  // Component to recursively render subordinates
  const RenderSubordinates = ({ subordinates }) => {
    return (
      <Stack pl={4}>
        {subordinates.map((subordinate) => {
          const subordinateDetails = findUserById(subordinate); // Get details of the subordinate
          const hasSubordinates = subordinateDetails?.subordinates?.length > 0; // Check if the subordinate has their own subordinates
          const isExpanded = expandedSubordinates[subordinate]; // Check if this subordinate is expanded

          return (
            <Box key={subordinate}>
              <Stack direction="row" alignItems="center" mt={0.5}>
                {/* Display subordinate's name, or fallback to their ID */}
                <Typography
                  onClick={() => handleSubordinateClick(subordinate)} // On click, set this subordinate as the current user
                  sx={{
                    color: theme.palette.text.thin,
                    cursor: "pointer",
                    transition: "color 0.3s, transform 0.3s",
                    textDecoration: "underline",
                    ":hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                  // mt={2}
                >
                  {allUsers[subordinate]?.FirstName || subordinate}{" "}
                </Typography>
                {/* Show toggle button if this subordinate has more subordinates */}
                {hasSubordinates && (
                  <IconButton
                    onClick={() => toggleSubordinate(subordinate)} // Toggle visibility of subordinates
                    sx={{
                      transition: "color 0.3s, transform 0.3s",
                      ":hover": {
                        bgcolor: "transparent",
                        color: theme.palette.primary.light,
                        transform: "scale(1.2)",
                      },
                    }}
                  >
                    {isExpanded ? (
                      <RemoveCircleOutlineIcon
                        sx={{
                          fontSize: "18px",
                          color: theme.palette.secondary.light,
                        }}
                      /> // Show collapse icon
                    ) : (
                      <AddCircleOutlineIcon
                        sx={{
                          fontSize: "18px",
                          color: theme.palette.secondary.light,
                        }}
                      /> // Show expand icon
                    )}
                  </IconButton>
                )}
              </Stack>
              {/* Render subordinates if expanded */}
              {isExpanded && hasSubordinates && (
                <RenderSubordinates
                  subordinates={subordinateDetails.subordinates}
                />
              )}
            </Box>
          );
        })}
      </Stack>
    );
  };

  return (
    <Box sx={{ position: "relative", height: showMoreActive ? 620 : 360 }}>
      <Box
        bgcolor={theme.palette.secondary.light}
        sx={{
          width: "100%",
          height: 30,
          position: "absolute",
          // top: 50,
          // left: 50,
          zIndex: 1,
          borderTopLeftRadius: "50px",
          borderTopRightRadius: "50px",
        }}
      >
        .
      </Box>
      <Box
        width={"100%"}
        height={"100%"}
        borderRadius={"28px"}
        bgcolor={theme.palette.tiles.bg}
        sx={{
          overflowY: "auto",
          // ...scrollBarStyles,
          position: "absolute",
          top: 5,
          zIndex: 2,
          color: "text.primary",
          p: 5,
          ...tileShadows,
        }} // Higher z-index to overlap the first box}} // Apply scrollbar styles
      >
        <Grid container>
          <Grid item xs={4}>
            <Box
              width={200}
              height={50}
              border={`1px solid ${theme.palette.secondary.light}`}
              bgcolor={theme.palette.icons.colorActive}
              p={1.5}
              borderRadius={2}
              onClick={handleOneLevelUp} // Navigate up one level
              sx={{
                cursor: "pointer",
                transition: "transform 0.3s ease-in-out, background-color 0.3s",
                ":hover": {
                  transform: "scale(1.03)",
                  // backgroundColor: "white",
                  // color: theme.palette.secondary.light,
                },
              }}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography>Up One Level</Typography>
                <ArrowUpwardIcon sx={{ color: theme.palette.secondary.light }} />
              </Stack>
              <SubdirectoryArrowRightIcon
                sx={{
                  mt: 2,
                  fontSize: 35,
                  color: theme.palette.text.light,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={8} height={200}>
            <Box
              width={270}
              height={65}
              p={0.5}
              borderRadius={2}
              mt={8}
              pt={1}
              // border={"1px solid"}
              bgcolor={theme.palette.secondary.light}
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Box
                  height={40}
                  width={40}
                  ml={1}
                  // bgcolor={"white"}
                  borderRadius={2}
                >
                  <Avatar sx={{ bgcolor: theme.palette.primary.main }} />
                </Box>
                <Divider
                  orientation="vertical"
                  sx={{
                    height: 40,
                    ml: 2,
                    mr: 2,
                    width: 0.01,
                    borderColor: theme.palette.primary.main,
                  }}
                />
                <Stack direction={"column"}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      textDecoration: "underline",
                      color: theme.palette.icons.bgDark,
                      fontSize: "15.5px",
                    }}
                  >
                    {allUsers[currentUser]?.FirstName || currentUser}{" "}
                    {/* Display current user's name */}
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.text.thin,
                      fontSize: "14px",
                    }}
                  >
                    {allUsers[currentUser]?.JobTitle || currentUser}{" "}
                    {/* Display current user's name */}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
            {hierarchy[currentUser]?.subordinates?.length > 0 && (
              <Box mt={2}>
                <Typography
                  sx={{
                    fontSize: 15,
                    // fontWeight: "bold",
                    color: theme.palette.text.thin,
                  }}
                >
                  Direct Reports
                </Typography>
                <Box
                  sx={{
                    overflowY: "auto",
                    ...scrollBarStyles,
                    height: "70%",
                  }}
                >
                  <Stack>
                    {hierarchy[currentUser] && (
                      <Stack pl={4}>
                        {/* Render subordinates recursively */}
                        <RenderSubordinates
                          subordinates={hierarchy[currentUser].subordinates}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrgChart;
