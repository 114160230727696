import { useTheme } from "@emotion/react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";
import EllipsisWithTooltip from "../EllipsisWithTooltip";

const UserDetails = ({ details, userDetails }) => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const theme = useTheme();
  const temp = "On the New Change Request page, ensure";

  return (
    <>
      {" "}
      <Typography fontWeight={600} color="text.primary" gutterBottom>
        {allTranslations?.user_details?.[userLang] ?? "User Details"}
      </Typography>
      <Box
        mb={5}
        sx={(theme) => ({
          p: 3,
          borderRadius: 5,
          color: "text.primary",
          backdropFilter: "blur(4px)",
          boxShadow: `0px -4px ${theme.palette.icons.bgLight}, 0px 2px 4px 0px #0000001A`,
          bgcolor:
            theme.palette.mode === "light"
              ? theme.palette.primary.mainGradient2
              : "background.secondary",
        })}
      >
        <Grid container spacing={2}>
          <Grid item xs={1.6}>
            <Stack direction="row" spacing={1}>
              <Typography fontWeight={500}>
                {allTranslations?.user?.[userLang] ?? "User"} ID:
              </Typography>
              <Typography>
                {details?.raisedForId
                  ? details?.raisedForId
                  : details?.requestorId}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3.2}>
            <Stack direction="row" spacing={1}>
              <Typography fontWeight={500}>
                {allTranslations?.user_name?.[userLang] ?? "User Name"}:
              </Typography>
              <EllipsisWithTooltip text={userDetails?.FullName} customWidth={180} />
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" spacing={1}>
              <Typography fontWeight={500}>
                {allTranslations?.email?.[userLang] ?? "Email"}:
              </Typography>
              <EllipsisWithTooltip text={userDetails?.Email} customWidth={290} />
            </Stack>
          </Grid>
          <Grid item xs={3.1}>
            <Stack direction="row" spacing={1}>
              <Typography fontWeight={500}>
                {allTranslations?.manager_name?.[userLang] ?? "Manager Name"}:
              </Typography>
              <EllipsisWithTooltip text={"Kartheek C"} customWidth={150} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UserDetails;
