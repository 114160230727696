import {
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";

import {
  getRequestByApprover,
  getRequestByComplianceManager,
} from "../apiCalls/allApiCalls";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import RequestStatusTile from "../components/common/tile/RequestStatusTile";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AddIcon from "@mui/icons-material/Add";
import TurnSharpRightOutlinedIcon from "@mui/icons-material/TurnSharpRightOutlined";
import CachedIcon from "@mui/icons-material/Cached";

const ITEM_HEIGHT = 48;

const AllRequests = () => {
  const { allTranslations } = useSelector((state) => state.translations);
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const location = useLocation();
  const { user: profile } = useSelector((state) => state.users);
  const [view, setView] = useState("grid");
  const [currentPage, setCurrentPage] = useState(1);
  const [initialRequestsData, setInitialRequestsData] = useState([]);
  const [requestsData, setRequestsData] = useState([]);
  const [requestCount, setRequestCount] = useState({});
  const [delegationRequests, setDelegationRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentRequests, setCurrentRequests] = useState();
  const requestsPerPage = 9;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] =
    React.useState("sort by descending");

  const [options, setOptions] = useState([
    { icon: ArrowUpwardIcon, value: "sort by ascending", count: 0 },
    { icon: ArrowDownwardIcon, value: "sort by descending", count: 0 },
    { icon: CheckCircleOutlineIcon, value: "Approved requests", count: 0 },
    { icon: ScheduleIcon, value: "In Progress requests", count: 0 },
    { icon: ScheduleIcon, value: "Rejected requests", count: 0 },
    { icon: AddIcon, value: "new requests", count: 0 },
    { icon: TurnSharpRightOutlinedIcon, value: "elevated requests", count: 0 },
    { icon: CachedIcon, value: "peer requests", count: 0 },
    { icon: CachedIcon, value: "deprovision requests", count: 0 },
    { icon: CachedIcon, value: "unlock requests", count: 0 },
  ]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (option = selectedOption) => {
    setAnchorEl(null);
    if (option) {
      setSelectedOption(option); // Update the selected option
    }
  };

  const indexOfLastRequest = currentPage * requestsPerPage;
  const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
  const totalPages = Math.ceil(requestsData.length / requestsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleViewChange = (event) => {
    setView(event.target.value);
  };
  const changeIssuedDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const fetchApproverData = async () => {
    try {
      const requestResponse = await getRequestByApprover(profile?.Uid);
      setLoading(true);
      if (requestResponse?.status === 200) {
        let requestsData = await requestResponse?.data.requests;
        // console.log("dataaaaa", [
        //   ...requestsData,
        //   ...requestResponse?.data?.delegationRequests,
        // ]);

        const requestDataList = [
          ...requestsData,
          ...requestResponse?.data?.delegationRequests,
        ].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

        // console.log(requestsData, "requestData");
        setRequestCount(requestResponse?.data.count);
        setInitialRequestsData(
          [...requestsData, ...requestResponse?.data?.delegationRequests].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        );
        setRequestsData(
          [...requestsData, ...requestResponse?.data?.delegationRequests].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        ); // Set the filtered in-progress data
        setDelegationRequests(requestResponse?.data?.delegationRequests);

        // Calculate counts based on request types
        const counts = {
          "sort by ascending": requestDataList.length, // Total count
          "sort by descending": requestDataList.length, // Total count
          "Approved requests": requestDataList.filter(
            (req) => req.status === "Approved"
          ).length,
          "In Progress requests": requestDataList.filter(
            (req) => req.status === "In Progress"
          ).length,
          "Rejected requests": requestDataList.filter(
            (req) => req.status === "Rejected"
          ).length,
          "new requests": requestDataList.filter(
            (req) => req.requestType === "New Request"
          ).length,
          "elevated requests": requestDataList.filter(
            (req) => req.requestType === "Elevated Request"
          ).length,
          "peer requests": requestDataList.filter((req) => {
            return (
              req.requestType === "Reference User" ||
              req.requestType === "Peer Request"
            );
          }).length,
          "deprovision requests": requestDataList.filter(
            (req) => req.requestType === "Deprovisioning User"
          ).length,
          "unlock requests": requestDataList.filter(
            (req) => req.requestType === "Deprovisioning User"
          ).length,
        };

        // console.log(counts["sort by ascending"])

        const newOptions = options.map((option) => ({
          ...option,
          count: counts[option.value],
        }));

        setOptions(newOptions);
        setLoading(false);
      } else {
        throw new Error("Failed to fetch request details");
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      // setError(error.message);
      setLoading(false);
    }
  };

  const fetchComplianceData = async () => {
    try {
      const requestResponse = await getRequestByComplianceManager(profile?.Uid);
      setLoading(true);
      if (requestResponse?.status === 200) {
        let requestsData = await requestResponse?.data.requests;
        // console.log("dataaaaa", [
        //   ...requestsData,
        //   ...requestResponse?.data?.delegationRequests,
        // ]);

        // console.log(requestsData, "requestData");
        const requestDataList = [
          ...requestsData,
          ...requestResponse?.data?.delegationRequests,
        ].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setRequestCount(requestResponse?.data.count);
        setInitialRequestsData(
          [...requestsData, ...requestResponse?.data?.delegationRequests].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        );
        setRequestsData(
          [...requestsData, ...requestResponse?.data?.delegationRequests].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        ); // Set the filtered in-progress data
        setDelegationRequests(requestResponse?.data?.delegationRequests);

        // Calculate counts based on request types
        const counts = {
          "sort by ascending": requestDataList.length, // Total count
          "sort by descending": requestDataList.length, // Total count
          "Approved requests": requestDataList.filter(
            (req) => req.status === "Approved"
          ).length,
          "In Progress requests": requestDataList.filter(
            (req) => req.status === "In Progress"
          ).length,
          "new requests": requestDataList.filter(
            (req) => req.requestType === "New Request"
          ).length,
          "elevated requests": requestDataList.filter(
            (req) => req.requestType === "Elevated Request"
          ).length,
          "peer requests": requestDataList.filter((req) => {
            return (
              req.requestType === "Reference User" ||
              req.requestType === "Peer Request"
            );
          }).length,
          "deprovision requests": requestDataList.filter(
            (req) => req.requestType === "Deprovisioning User"
          ).length,
          "unlock requests": requestDataList.filter(
            (req) => req.requestType === "Deprovisioning User"
          ).length,
        };

        // console.log(counts["sort by ascending"])

        const newOptions = options.map((option) => ({
          ...option,
          count: counts[option.value],
        }));

        setOptions(newOptions);

        setLoading(false);
      } else {
        throw new Error("Failed to fetch request details");
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      // setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("approver") && profile.Uid) {
      fetchApproverData();
    } else if (location.pathname.includes("compliance") && profile.Uid) {
      fetchComplianceData();
    }
  }, [profile]);

  useEffect(() => {
    if (selectedOption === "sort by ascending") {
      const modifyRequestData = initialRequestsData;
      modifyRequestData.sort(
        (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
      );
      setRequestsData(modifyRequestData);
    } else if (selectedOption === "sort by descending") {
      const modifyRequestData = initialRequestsData;
      modifyRequestData.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      setRequestsData(requestsData);
    } else if (selectedOption === "new requests") {
      setRequestsData(
        initialRequestsData.filter(
          (request) => request.requestType === "New Request"
        )
      );
    } else if (selectedOption === "elevated requests") {
      setRequestsData(
        initialRequestsData.filter(
          (request) => request.requestType === "Elevated Request"
        )
      );
    } else if (selectedOption === "peer requests") {
      setRequestsData(
        initialRequestsData.filter((request) => {
          return (
            request.requestType === "Reference User" ||
            request.requestType === "Peer Request"
          );
        })
      );
    } else if (selectedOption === "deprovision requests") {
      setRequestsData(
        initialRequestsData.filter(
          (request) => request.requestType === "Deprovisioning User"
        )
      );
    } else if (selectedOption === "Approved requests") {
      setRequestsData(
        initialRequestsData.filter((request) => request.status === "Approved")
      );
    } else if (selectedOption === "In Progress requests") {
      setRequestsData(
        initialRequestsData.filter(
          (request) => request.status === "In Progress"
        )
      );
    } else if (selectedOption === "Rejected requests") {
      setRequestsData(
        initialRequestsData.filter((request) => request.status === "Rejected")
      );
    }
  }, [selectedOption]);

  useEffect(() => {
    setCurrentRequests(
      requestsData.slice(indexOfFirstRequest, indexOfLastRequest)
    );
  }, [selectedOption, currentPage, requestsData]);

  // const currentRequests = requestsData
  //   ? requestsData.slice(indexOfFirstRequest, indexOfLastRequest)
  //   : [];
  // const requestsData = [];

  const requestType = () => {
    if (location.pathname.includes("approver")) {
      return "approver";
    } else if (location.pathname.includes("compliance")) {
      return "compliance";
    }
  };
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }
  return (
    <Box width={"95%"}>
      <Container
        sx={{
          p: 4,
          bgcolor: "background.default",
          borderRadius: 5,
          minHeight: "100vh",
          // maxWidth: "80vw",
          minWidth: "100%",
          // width: "",

          m: "auto",
        }}
      >
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BreadCrumbs />
          <Stack direction={"row"} gap={1.5}>
            <div>
              <Tooltip
                title={allTranslations?.filter?.[userLang] ?? "Filter"}
                arrow
                placement="left"
              >
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                slotProps={{
                  paper: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "27ch",
                    },
                  },
                }}
              >
                {options.map((option) => {
                  const IconComponent = option.icon;
                  return (
                    <MenuItem
                      key={option.value}
                      selected={option.value === selectedOption}
                      onClick={() => handleClose(option.value)}
                    >
                      <IconComponent
                        style={{ marginRight: "8px", fontSize: "14px" }}
                      />
                      {allTranslations?.[
                        option.value.split(" ").join("_").toLowerCase()
                      ]?.[userLang] ?? option.value}
                      ({option.count})
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>

            <FormControl>
              <Select
                id="view-select"
                value={view}
                onChange={handleViewChange}
                sx={{
                  width: 150,
                  borderRadius: 2,
                  bgcolor: "secondary.main",
                  color: "white",
                }}
                size="small"
              >
                <MenuItem value="grid">
                  {allTranslations?.grid_view?.[userLang] ?? "Grid View"}
                </MenuItem>
                <MenuItem value="list">
                  {allTranslations?.list_view?.[userLang] ?? "List View"}
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Box>

        {currentRequests.length > 0 ? (
          <Grid container spacing={5}>
            {currentRequests.map((request) => (
              <Grid
                item
                xs={view === "grid" ? 4 : 12}
                sm={view === "grid" ? 4 : 12}
                key={request.requestId}
              >
                <RequestStatusTile
                  requestId={request?.requestId}
                  requestorId={request?.requestorId}
                  issueDate={changeIssuedDate(request?.updatedAt)}
                  status={request?.status}
                  path={`/${requestType()}/${request?.requestId}`}
                  customFontSize={12}
                  requestType={request?.requestType}
                  createdAt={request?.createdAt}
                  updatedAt={request?.updatedAt}
                  delegation={request?.delegation}
                  delegatorId={request?.delegatorId}
                  // showRenewalMessage={isRenewalRequired(request)}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="h6" align="center" color={"text.primary"}>
            {allTranslations?.no_requests_found?.[userLang] ??
              "No requests found"}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 4,
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default AllRequests;
