import axios from "axios";
import { API_TIMEOUT } from "../utilities/constants.js";

// const token = Cookies.get("jwtToken");

const AxiosInstance = (baseURL) => {
  const initAxiosInstance = () => {
    const options = {
      baseURL:
        baseURL ||
        // "vertex-platform-api-gbffg0d7f5gbfyak.southindia-01.azurewebsites.net",
        // "https://vertex-platform-api-gbffg0d7f5gbfyak.southindia-01.azurewebsites.net",
        // "http://192.168.0.122:5100/",
        "http://localhost:8000/",
      // "http://localhost:8080/",
      timeout: API_TIMEOUT,
      // headers: {
      //   Authorization: `Bearer ${token}`,
      //   "Content-Type": "application/json",
      //   // "Content-Type": "multipart/form-data",
      // },
    };
    const axiosInstance = axios.create(options);
    return axiosInstance;
  };

  return initAxiosInstance();
};

export default AxiosInstance;
