import React from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { tileShadows } from "../../pages/Root";

const Masonry = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const translations = useSelector((state) => state.translations.translations);
  const { loading: translationsLoading, translations } = useSelector(
    (state) => state.translations
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );

  const tileStyling =
    theme.palette.mode === "dark"
      ? {
          textAlign: "center",
          border: `2px solid ${theme.palette.background.default}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          // color: "#858585",
          bgcolor: theme.palette.background.default,
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.02)",
          },
          ...tileShadows,
        }
      : {
          textAlign: "center",
          border: `1px solid ${theme.palette.primary.grayShade}`,
          // filter: `drop-shadow(0px 2px 4px 0px ${theme.palette.primary.dropShadow})`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          // color: "#858585",
          bgcolor: theme.palette.background.default,
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.02)",
          },
          ...tileShadows,
        };

  const leftDashboardTiles = [
    { name: "Raise a new request", path: "/new-request" },
    { name: "Peer user", path: "/peer-request" },
    { name: "Request status", path: "/request-status" },
    { name: "Elevated access", path: "/new-request" },
    { name: "Know more", path: "/" },
  ];

  // if (translationsLoading && userLangLoading) {
  //   return <h1>Loading...</h1>;
  // }

  return (
    <Grid container gap={"10px"} xs={4}>
      <Grid item container direction={"column"} gap={"10px"} xs={5.8}>
        <Grid
          item
          flex={1.5} // bgcolor={"background.primary"}
          sx={{
            ...tileStyling,
            paddingX: 3,
            textAlign: "center",
          }}
          onClick={() => navigate("/new-request")}
        >
          <Typography color={theme.palette.text.thin}>
            {allTranslations?.raise_a_new_request?.[userLang] ??
              "Raise a new request"}
          </Typography>
        </Grid>
        <Grid
          item
          bgcolor={"#141118"}
          flex={1.2}
          sx={tileStyling}
          onClick={() => navigate("/request-status")}
        >
          <Typography color={theme.palette.text.thin}>
            {allTranslations?.request_status?.[userLang] ?? "Request status"}
          </Typography>
        </Grid>
        <Grid
          item
          bgcolor={"#141118"}
          flex={1}
          sx={tileStyling}
          onClick={() => navigate("/deprovisioning")}
        >
          <Typography color={theme.palette.text.thin}>
            {allTranslations?.deprovisioning?.[userLang] ?? "Deprovisioning"}{" "}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={"column"}
        gap={"10px"}
        // bgcolor={"pink"}
        xs={5.8}
      >
        <Grid
          item
          flex={0.9}
          bgcolor={"#141118"}
          sx={tileStyling}
          onClick={() => navigate("/peer-request")}
        >
          <Typography color={theme.palette.text.thin}>
            {allTranslations?.peer_user?.[userLang] ?? "Peer user"}
          </Typography>
        </Grid>
        <Grid
          item
          bgcolor={"pink"}
          flex={1.1} // bgcolor={"background.primary"}
          sx={tileStyling}
          onClick={() => navigate("/elevated-request")}
        >
          <Typography color={theme.palette.text.thin}>
            {allTranslations?.elevated_access?.[userLang] ?? "Elevated access"}
          </Typography>
        </Grid>
        <Grid
          item
          bgcolor={"pink"}
          flex={1} // bgcolor={"background.primary"}
          sx={tileStyling}
          onClick={() => navigate("/unlock-user")}
        >
          <Typography color={theme.palette.text.thin}>
            {allTranslations?.unlock_user?.[userLang] ?? "Unlock User"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Masonry;
