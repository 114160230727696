import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import {
  Button,
  Stack,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DescriptionIcon from "@mui/icons-material/Description";
import { useTheme } from "@emotion/react";
import {
  deleteAttachment,
  raiseRequest,
  updateCopyRequest,
  uploadAttachment,
} from "../../apiCalls/allApiCalls";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FeedbackForm from "../feedbackform/FeedbackForm";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";

export default function Summary({
  userId,
  requestType,
  cart,
  setIsActive,
  copyRequestDetails,
  selectedFiles,
  fileNames,
  comments,
  setSubmitLoading,
  isActive,
  submitLoading,
  selectedUserDetails,
}) {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { user: allUsers } = useSelector((state) => state.users);
  const requestTypeCategories = {
    newRequest: "New Request",
    deprovisioning: "Deprovisioning User",
    elevated: "Elevated Request",
    peerRequest: "Peer Request",
  };
  const requestTypeLabel =
    requestTypeCategories[requestType] || "Unknown Request Type";
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [requestDetails, setRequestDetails] = React.useState({
    requestorId: profile?.Uid,
    raisedForId: selectedUserDetails?.Uid,
    requestType: requestTypeLabel,
    requestData: cart,

    status: "In Progress",
  });
  // if (copyRequestDetails) {
  //   console.log(copyRequestDetails)
  // }

  useEffect(() => {
    setRequestDetails((prev) => ({
      ...prev,
      requestType: requestTypeLabel,
      requestData: cart,
      requestorId: profile?.Uid,
      raisedForId: selectedUserDetails?.Uid,
    }));
  }, [cart, userId]);

  const handleOpen = () => setOpen(true);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsActive(open);
  };

  function areRequestsSame() {
    const oldAttachments = copyRequestDetails?.requestData?.attachmentsData;
    // checking if any new attachments or comments were added
    if (
      selectedFiles &&
      fileNames &&
      selectedFiles?.length > 0 &&
      oldAttachments?.length > 0
    ) {
      // collecting old request's filenames into an array
      const oldFileNames = oldAttachments.map(
        (o) => o.fileName || o.data.fileName
      );
      // console.log("old", oldFileNames);
      // checking if any filename new
      for (let i = 0; i < fileNames?.length; i++) {
        if (!oldFileNames.includes(fileNames[i])) {
          return false;
        }
      }
    } else if (
      selectedFiles &&
      selectedFiles?.length > 0 &&
      oldAttachments?.length === 0
    ) {
      // in case there were no attachments before and
      // there are attachments now, return false
      return false;
    }

    const oldComments = copyRequestDetails?.requestData?.comments;
    if (comments && oldComments && comments !== oldComments) {
      return false;
    }

    // checking if new products were added
    const productsData = requestDetails.requestData;
    const oldProducts = copyRequestDetails?.requestData?.productsData;

    if (productsData.length !== oldProducts.length) {
      return false;
    }

    const oldProductIds = oldProducts.map((o) => o.productId);

    for (let i = 0; i < productsData.length; i++) {
      // console.log(
      //   productsData[i].productId,
      //   oldProductIds.includes(productsData[i].productId)
      // );
      if (!oldProductIds.includes(productsData[i].productId)) {
        return false;
      }
    }

    return true;
  }

  /* 
    a copy req is valid when:
    1. the old req is still in progress, and the copy req 
    is not exactly same as the old req
    2. the old req is rejected, and the copy request can be 
    same/ different to the old req
    3. the old req is accepted, and the copy request 
    is different to the old req
  */
  function isCopyRequestValid() {
    const requestsSame = areRequestsSame();
    let valid = true;
    switch (copyRequestDetails.status) {
      case "In Progress":
        if (requestsSame) {
          valid = false;
        }
        break;
      case "Approved":
        if (requestsSame) {
          valid = false;
        }
        break;
      default:
        valid = true;
        break;
    }
    return valid;
  }

  async function updateRequestDetails(newDetails) {
    const response = await updateCopyRequest(
      copyRequestDetails.requestId,
      newDetails
    );
    return response;
  }

  async function submitRequest(metaDataArr) {
    setSubmitLoading(true);
    // if this is a copy req and the req is still in progress, update it
    // otherwise raise a new request
    let response;
    if (copyRequestDetails && copyRequestDetails.status === "In Progress") {
      const newDetails = {
        productsData: requestDetails.requestData,
        comments: comments,
      };

      if (metaDataArr && metaDataArr.length > 0) {
        newDetails["attachmentsData"] = metaDataArr;
      }
      response = await updateRequestDetails(newDetails);
    } else {
      // final req data
      const data = {
        ...requestDetails,
        requestData: {
          productsData: requestDetails.requestData,
          attachmentsData: metaDataArr,
          comments: comments,
        },
      };

      response = await raiseRequest(data);
    }

    if (response.status === 201) {
      toast.success("Request submitted successfully!");
      handleOpen();
    } else {
      toast.error(
        <div>
          Request submission failed!
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            contact support
          </a>
          .
        </div>
      );
    }

    setSubmitLoading(false);
    return metaDataArr;
  }

  async function handleSubmit(e) {
    setSubmitLoading(true);
    e.preventDefault();
    // array to store file metadata
    const metaDataArr = [];
    try {
      if (selectedFiles?.length > 0) {
        for (let i = 0; i < selectedFiles.length; i++) {
          const selectedFile = selectedFiles[i];
          const fileName = fileNames[i];
          // upload the file and get the metadata of the file
          const metaData = await uploadAttachment(selectedFile, fileName);
          metaDataArr.push(metaData?.data);
        }
      }

      await submitRequest(metaDataArr);
    } catch (error) {
      // toast.error(
      //   <div>
      //     Failed to post requests!
      //     <a
      //       href="mailto:ganeshg@aapmor.com"
      //       style={{ color: "blue", textDecoration: "underline" }}
      //     >
      //       contact support
      //     </a>
      //     .
      //   </div>
      // );
      console.error("Failed to post requests:", error);
      if (metaDataArr.length > 0) {
        for (let i = 0; i < metaDataArr.length; i++) {
          const selectedFile = metaDataArr[i];
          const fileName = selectedFile.data.fileName;
          await deleteAttachment(fileName);
        }
      }
      setSubmitLoading(false);
    }
  }

  const list = (anchor) => (
    <Box
      sx={{
        bgcolor: theme.palette.mode === "dark" ? "#00402A" : "",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        px: 6,
        py: 5,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "29.05px",
            letterSpacing: "0.02em",
            textAlign: "left",
            mb: 2,
          }}
        >
          {allTranslations?.summary?.[userLang] ?? "Summary"}
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack
        sx={{
          maxHeight: "200px",
        }}
      >
        <BasicTable cart={cart} comments={comments} />
      </Stack>
      <Divider
        sx={{ width: "100%", mt: 2, borderColor: theme.palette.text.lighter }}
      />
      <Stack
        direction="row"
        // spacing={5}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          mt: 3,
        }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={5}>
          <Typography color={theme.palette.text.thin} sx={{ fontWeight: 600 }}>
            {allTranslations?.attached_files?.[userLang] ?? "Attached files"}:
          </Typography>
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            width={"80%"}
            maxHeight={80}
            // columnGap={2}
            gap={1.5}
            sx={{
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "2px",
                bgcolor: theme.palette.icons.bgLight,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "icons.bgDark",
                borderRadius: 5,
              },
            }}
          >
            {fileNames && fileNames.length > 0 ? (
              fileNames.map((fileName, i) => {
                return (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={1}
                    key={i}
                    // mb={2}
                  >
                    <DescriptionIcon
                      fontSize="small"
                      sx={{
                        color: theme.palette.text.thin,
                      }}
                    />
                    <Typography
                      variant="caption"
                      component={"span"}
                      display="block"
                      sx={{ color: theme.palette.text.light, lineHeight: 1 }}
                    >
                      {fileName}
                    </Typography>
                  </Stack>
                );
              })
            ) : (
              <Typography color={theme.palette.text.light}>
                {fileNames?.length === 0 && "No Attachments"}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            copyRequestDetails && !isCopyRequestValid() && submitLoading
          } // disable button during submit
          sx={{
            px: 5,
            boxShadow: "none",
            bgcolor: theme.palette.icons.bgDark,
            color: theme.palette.text.white,
            border: `1px solid ${theme.palette.icons.bgDark}`,
            ":hover": {
              border: `1px solid ${theme.palette.primary.border}`,
              bgcolor: theme.palette.background.default,
              color: theme.palette.text.thin,
              boxShadow: "none",
            },
          }}
        >
          {submitLoading
            ? "Submitting..."
            : allTranslations?.submit?.[userLang] ?? "Submit"}
        </Button>
      </Stack>
    </Box>
  );

  return (
    <>
      <FeedbackForm open={open} setOpen={setOpen} handleOpen={handleOpen} />
      <Drawer
        anchor="bottom"
        open={isActive}
        onClose={toggleDrawer("bottom", false)}
      >
        {list("bottom")}
      </Drawer>
    </>
  );
}

function BasicTable(props) {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const theme = useTheme();
  // if (props.cart) {
  //   console.log(`props.cart`, props.cart);
  // }
  return (
    <TableContainer
      sx={{
        mb: 2,
        bgcolor: "transparent",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "2px",
          bgcolor: "white",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.icons.bgLight,
          borderRadius: 5,
        },
      }}
      elevation={0}
      component={Paper}
    >
      <Table aria-label="simple table">
        <TableBody>
          <>
            <TableRow sx={{ fontWeight: 600 }}>
              <TableCell align="center" sx={{ fontWeight: 600 }}>
                S.NO.
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                {allTranslations?.product_name?.[userLang] ?? "PRODUCT NAME"}
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                {`${allTranslations?.product?.[userLang]} ID` ?? "PRODUCT ID"}
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                {allTranslations?.valid_from?.[userLang] ?? "VALID FROM"}
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                {allTranslations?.valid_till?.[userLang] ?? "VALID TILL"}
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>
                {allTranslations?.approver?.[userLang] ?? "APPROVER"}
              </TableCell>
            </TableRow>
            {props.cart.map((row, index) => (
              <TableRow
                key={row?.productDetails?.productName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">
                  <Box
                    sx={{
                      height: 42,
                      width: 42,
                      borderRadius: "50%",
                      bgcolor: "primary.main",
                      color: "white",
                      m: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>#{index + 1}</Typography>
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.productDetails?.productName}
                </TableCell>
                <TableCell>{row?.productDetails?.productId}</TableCell>
                <TableCell>
                  {typeof row.validFrom === "string"
                    ? row.validFrom
                    : dayjs(row.validFrom).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell>{row.validTill}</TableCell>
                <TableCell>{row?.productDetails?.approver}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={5}>
                <Typography
                  mt={2}
                  mb={1}
                  fontSize={"16px"}
                  sx={{ fontWeight: 600 }}
                  color={theme.palette.text.thin}
                >
                  {allTranslations?.comments?.[userLang] ?? "Comments"}:
                </Typography>
                <TextField
                  variant="standard"
                  disabled={true}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={props.comments}
                  multiline
                  sx={{
                    p: 1.5,
                    minWidth: 900,
                    borderRadius: 1,
                    "& .MuiInputBase-input.Mui-disabled": {
                      color: theme.palette.text.light,
                      opacity: 1,
                      WebkitTextFillColor: "inherit",
                    },
                    border: `1px solid ${theme.palette.primary.border}`,
                    // maxHeight: 200,
                  }}
                />
              </TableCell>
            </TableRow>
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
