import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getUserDetailsByUid,
  getAllEndUsers,
  getHierarchy,
} from "../../../apiCalls/allApiCalls";
import {
  formatAllUsers,
  formatHierarchy,
} from "../../../utilities/helperMethods";

export const fetchUser = createAsyncThunk("users/fetchUser", async (Uid) => {
  const response = await getUserDetailsByUid(Uid); // Pass the query string to the function
  // console.log("redux", response);
  if (response?.status === 200) {
    return response.data;
  }
  return {};
});

export const fetchAllUsers = createAsyncThunk(
  "users/fetchAllUsers",
  async () => {
    const response = await getAllEndUsers(); // Pass the query string to the function
    // console.log("users/fetchAllUsers", response);
    if (response?.status === 200) {
      const formattedResponse = formatAllUsers(response.data);
      return formattedResponse;
    }
    return [];
  }
);

export const fetchHierarchy = createAsyncThunk(
  "users/fetchHierarchy",
  async () => {
    const response = await getHierarchy(); // Pass the query string to the function
    // console.log("users/fetchHierarchy", response);
    if (response?.status === 200) {
      const formattedResponse = formatHierarchy(response.data.data);
      return formattedResponse;
    }
    return [];
  }
);

const initialState = {
  loading: true,
  user: {},
  allUsers: [],
  hierarchy: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // function to set user
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handling fetchUser Thunk
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // Handling fetchAllUsers Thunk
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.allUsers = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // Handling fetchHierarchy Thunk
    builder
      .addCase(fetchHierarchy.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHierarchy.fulfilled, (state, action) => {
        state.loading = false;
        state.hierarchy = action.payload;
      })
      .addCase(fetchHierarchy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
  // extraReducers: (builder) => {
  //   // user
  //   // builder.addCase(fetchUser.pending, (state, action) => {
  //   //   state.loading = true;
  //   // });
  //   // builder.addCase(fetchUser.fulfilled, (state, action) => {
  //   //   state.loading = false;
  //   //   state.user = action.payload;
  //   // });
  //   // builder.addCase(fetchUser.rejected, (state, action) => {
  //   //   state.loading = false;
  //   // });
  //   // users
  //   builder.addCase(fetchAllUsers.pending, (state, action) => {
  //     state.loading = true;
  //   });
  //   builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
  //     state.loading = false;
  //     state.allUsers = action.payload;
  //   });
  //   builder.addCase(fetchAllUsers.rejected, (state, action) => {
  //     state.loading = false;
  //   });
  //   // hierarchy
  //   // builder.addCase(fetchHierarchy.pending, (state, action) => {
  //   //   state.loading = true;
  //   // });
  //   // builder.addCase(fetchHierarchy.fulfilled, (state, action) => {
  //   //   state.loading = false;
  //   //   state.hierarchy = action.payload;
  //   // });
  //   // builder.addCase(fetchHierarchy.rejected, (state, action) => {
  //   //   state.loading = false;
  //   // });
  // },
});

export const { setUser } = usersSlice.actions;
export default usersSlice.reducer;
