import { Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const EllipsisWithTooltip = ({ text, customWidth }) => {
  const textRef = useRef(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsOverflowed(element.scrollWidth > element.clientWidth);
    }
  }, [text]);

  return (
    <Tooltip title={isOverflowed ? text : ""}>
      <Typography
        ref={textRef}
        variant="body1"
        noWrap
        sx={{
          width: customWidth,
          overflow: "hidden",
          whiteSpace: "nowrap",
          color: "text.secondary",
          textOverflow: "ellipsis",
          cursor: isOverflowed ? "pointer" : "default",
          // bgcolor: "pink",
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

export default EllipsisWithTooltip;
