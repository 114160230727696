import {
  axiosInstance,
  axiosInstanceAuthx,
  axiosInstanceGrc,
  axiosInstanceNexus,
} from "./logicService";
import { toast } from "react-toastify";
import { endPoints } from "./apiEndPoints";
import Cookies from "js-cookie";
// import { SomethingWentWrong } from "../utilities/toastMessages";
import { useSelector } from "react-redux"; // Importing useSelector
import { useEffect, useState } from "react";

const ErrorMsgTranslator = ({ uniqueId }) => {
  const { allTranslations } = useSelector((state) => state.translations);
  const { language: userLang } = useSelector((state) => state.userLanguage);
  return allTranslations?.[uniqueId]?.[userLang] ?? "Something went wrong";
};

export const getRequests = async (email) => {
  // const { allTranslations } = useSelector((state) => state.translations);
  // const { language: userLang } = useSelector((state) => state.userLanguage);
  try {
    const response = await axiosInstance.get(`${endPoints.getRequest}`);
    return response;
  } catch (error) {
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
      // <div>
      //   Something went wrong. Please try again later
      //   <span> </span>
      //   <a
      //     href="mailto:ganeshg@aapmor.com"
      //     style={{ color: "blue", textDecoration: "underline" }}
      //   >
      //     <ErrorMsgTranslator uniqueId="contact_support" />
      //   </a>
      // </div>
    );
  }
};

export const getAllRequests = async (email) => {
  try {
    const response = await axiosInstance.get(`${endPoints.getAllRequest}`);
    return response;
  } catch (error) {
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const getRequestByApprover = async (userID) => {
  try {
    const response = await axiosInstance.get(
      `${endPoints.getRequestByApprover}/${userID}`
    );
    return response;
  } catch (error) {
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const getDelegationsByUid = async (userID, type) => {
  try {
    const response = await axiosInstance.get(
      `${endPoints.getAllDelegationsByUid}/${userID}`,
      {
        params: {
          type,
        },
      }
    );
    return response;
  } catch (error) {
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const getRequestByComplianceManager = async (userID) => {
  try {
    const response = await axiosInstance.get(
      `${endPoints.getRequestByComplianceManagerId}/${userID}`
    );
    return response;
  } catch (error) {
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const getLangTranslations = async () => {
  try {
    const response = await axiosInstance.get(`${endPoints.getTranslations}`);
    return response;
  } catch (error) {
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const getProductDetails = async () => {
  try {
    const response = await axiosInstanceGrc.get(
      `${endPoints.getProductDetails}`
    );
    return response;
  } catch (error) {
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const getUserProductDetails = async (id) => {
  try {
    const response = await axiosInstanceGrc.get(
      `${endPoints.getUserProducts}/${id}`
    );
    // console.log("prods:", response?.data?.products);
    return response?.data?.products;
  } catch (error) {
    console.log("firstcall");
    toast.error(
      // SomethingWentWrong()
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const updateUserLanguage = async (empid, lang) => {
  try {
    let config = {
      headers: {
        Authorization: Cookies.get("jwtToken"),
        "Content-Type": "application/json",
      },
    };

    const response = await axiosInstance.put(
      `${endPoints.updateLangPreference}/${empid}`,
      { languagePreference: lang },
      config
    );
    return response;
  } catch (error) {
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const updateUserLanguageByUid = async (uid, lang) => {
  try {
    let config = {
      headers: {
        Authorization: Cookies.get("jwtToken"),
        "Content-Type": "application/json",
      },
    };

    const response = await axiosInstanceAuthx.put(
      `${endPoints.updateLangPreferenceByUid}/${uid}`,
      { languagePreference: lang },
      config
    );
    return response;
  } catch (error) {
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const getUser = async (uid) => {
  try {
    const response = await axiosInstance.get(`${endPoints.getUser}/${uid}`);
    return response;
  } catch (error) {
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const uploadAttachment = async (selectedFile, fileName) => {
  try {
    let formAttachmentData = new FormData();
    formAttachmentData.append("attachment", selectedFile);
    const metadataRes = await axiosInstance.post(
      endPoints.uploadAttachments,
      formAttachmentData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Content-Disposition": `attachment; filename=${fileName}`,
          "x-file-caption": "new change request attachment", // Optional header for caption
        },
      }
    );
    console.log(`metadataRes for ${fileName}:`, metadataRes);
    return metadataRes;
  } catch (error) {
    console.error(`Error uploading attachment ${fileName}:`, error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const deleteAttachment = async (fileName) => {
  try {
    const response = await axiosInstance.delete(endPoints.deleteAttachment, {
      fileName,
    });
    return response;
  } catch (error) {
    console.error(`Error deleting attachment ${fileName}:`, error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const raiseRequest = async (requestData) => {
  try {
    const response = await axiosInstance.post(
      endPoints.raiseRequest,
      requestData
    );
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await axiosInstance.get(`${endPoints.getUsers}`);
    return response;
  } catch (error) {
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const getAllEndUsers = async () => {
  try {
    const response = await axiosInstanceAuthx.get(`${endPoints.getEndUsers}`);
    return response;
  } catch (error) {
    console.log(error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const getHierarchy = async () => {
  try {
    const response = await axiosInstance.get(`${endPoints.getUsersHierarchy}`);
    return response;
  } catch (error) {
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
  }
};

export const getProfileDetails = async (query) => {
  try {
    const response = await axiosInstance.get(
      `${endPoints.getProfileDetails}?${query}`
    );
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const getRequestDetails = async (query) => {
  try {
    const response = await axiosInstance.get(
      `${endPoints.getRequestDetailsByUserId}?${query}`
    );
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const fetchRequestDetails = async (query) => {
  try {
    const response = await axiosInstance.get(
      `${endPoints.raiseRequest}/${query}`
    );
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const updateRequest = async (reqId, body) => {
  try {
    const response = await axiosInstance.put(
      `${endPoints.updateRequest}/${reqId}`,
      body
    );
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const updateCopyRequest = async (reqId, newDetails) => {
  try {
    const response = await axiosInstance.put(
      `${endPoints.updateCopyRequest}/${reqId}`,
      newDetails
    );
    return response;
  } catch (error) {
    console.error("Error updating the request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const updateRequestStatus = async (reqId, body) => {
  try {
    const response = await axiosInstance.put(
      `${endPoints.updateRequest}/status/${reqId}`,
      body
    );
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

// AUTH X Api calls
export const getUserDetails = async (empId) => {
  try {
    let config = {
      headers: {
        Authorization: Cookies.get("jwtToken"),
        "Content-Type": "application/json",
      },
    };

    //getUserDetailsByUid
    const response = await axiosInstanceAuthx.get(
      `${endPoints.getUserDetails}/${empId}`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const getUserDetailsByUid = async (empId) => {
  try {
    let config = {
      headers: {
        Authorization: Cookies.get("jwtToken"),
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstanceAuthx.get(
      `${endPoints.getUserDetailsByUid}/${empId}`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const getUserDetailsByEmail = async (email) => {
  try {
    let config = {
      headers: {
        Authorization: Cookies.get("jwtToken"),
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstanceAuthx.get(
      `${endPoints.getUserDetailsByEmail}/${email}`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const forwardRequest = async (
  requestId,
  oldApprovalId,
  newApprovalId
) => {
  try {
    let config = {
      headers: {
        Authorization: Cookies.get("jwtToken"),
      },
    };
    const response = await axiosInstance.put(
      endPoints.forwardApproverRequest,
      { requestId, oldApprovalId, newApprovalId },
      config
    );
    toast.success("Request forwarded successfully");
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const approveRequest = async (requestData) => {
  try {
    const response = await axiosInstance.put(
      endPoints.approveTheRequest / requestData.requestId,
      requestData
    );
    return response;
  } catch (error) {
    console.error("Error approving request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const approveDeprovisioning = async (data) => {
  try {
    const response = await axiosInstanceGrc.post(endPoints.lockProducts, data);
    return response;
  } catch (error) {
    console.error("Error approving request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};
export const disableProductsUnlock = async (data) => {
  try {
    const response = await axiosInstanceGrc.post(
      endPoints.disableProducts,
      data
    );
    return response;
  } catch (error) {
    console.error("Error approving request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};
export const getLockedProductsOfUsers = async (id) => {
  try {
    const response = await axiosInstanceGrc.get(
      `${endPoints.getLockedProducts}/${id}`
    );
    return response;
  } catch (error) {
    console.error("Error approving request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const raiseDelegation = async (requestData) => {
  try {
    let config = {
      headers: {
        Authorization: Cookies.get("jwtToken"),
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance.post(
      endPoints.delegation,
      requestData,
      config
    );
    return response;
  } catch (error) {
    console.error("Error raising delegation request:", error);
    error.response.status === 400
      ? toast.error(
          <div>
            {error?.response?.data?.error}
            <a
              href="mailto:ganeshg@aapmor.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              <ErrorMsgTranslator uniqueId="contact_support" />
            </a>
            .
          </div>
        )
      : toast.error(
          <div>
            <ErrorMsgTranslator uniqueId="something_went_wrong" />
            <span> </span>
            <a
              href="mailto:ganeshg@aapmor.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              <ErrorMsgTranslator uniqueId="contact_support" />
            </a>
          </div>
        );
    throw error;
  }
};

export const updateDelegation = async (id, body) => {
  try {
    const response = await axiosInstance.put(
      `${endPoints.delegation}/${id}`,
      body
    );
    return response;
  } catch (error) {
    console.error("Error updating delegation:", error);
    error.response.status === 400
      ? toast.error(
          <div>
            {error?.response?.data?.error}
            <a
              href="mailto:ganeshg@aapmor.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              <ErrorMsgTranslator uniqueId="contact_support" />
            </a>
            .
          </div>
        )
      : toast.error(
          <div>
            <ErrorMsgTranslator uniqueId="something_went_wrong" />
            <span> </span>
            <a
              href="mailto:ganeshg@aapmor.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              <ErrorMsgTranslator uniqueId="contact_support" />
            </a>
          </div>
        );
    throw error;
  }
};

export const deleteDelegation = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `${endPoints.delegation}/${id}`
    );
    return response;
  } catch (error) {
    console.error(`Error deleting delegation`, error);
    error.response.status === 400
      ? toast.error(
          <div>
            {error?.response?.data?.error}
            <a
              href="mailto:ganeshg@aapmor.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              <ErrorMsgTranslator uniqueId="contact_support" />
            </a>
            .
          </div>
        )
      : toast.error(
          <div>
            <ErrorMsgTranslator uniqueId="something_went_wrong" />
            <span> </span>
            <a
              href="mailto:ganeshg@aapmor.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              <ErrorMsgTranslator uniqueId="contact_support" />
            </a>
          </div>
        );
    throw error;
  }
};

export const raiseFeedback = async (requestData) => {
  try {
    const response = await axiosInstance.post(
      endPoints.raiseFeedback,
      requestData
    );
    return response;
  } catch (error) {
    console.error("Error raising feedback:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};
export const getAllFeedback = async () => {
  try {
    const response = await axiosInstance.get(endPoints.getAllFeedback);
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const getAllAPIExceptionLogs = async () => {
  try {
    const response = await axiosInstance.get(endPoints.getAPIExceptionLogs);
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

// NEXUS TRANSLATIONS
// get translations in all languages
export const getAllTranslations = async () => {
  try {
    const response = await axiosInstanceNexus.get(
      endPoints.getAllTranslationsNexus
    );
    return response;
  } catch (error) {
    console.error("Error getting translations in all languages:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

// get translations in particular language
export const getAllTranslationsInSingleLanguage = async (language) => {
  try {
    const response = await axiosInstanceNexus.get(
      `${endPoints.getTranslationsInSingleLanguageNexus}/${language}`
    );
    return response;
  } catch (error) {
    console.error(
      `Error getting translations in the language: ${language}`,
      error
    );
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

// insert translations in bulk
export const insertManyTranslations = async (translations) => {
  try {
    translations.forEach(async (data) => {
      await axiosInstanceNexus.post(`${endPoints.addNewTranslation}`, data);
    });
  } catch (error) {
    console.error(`Error posting translations: `, error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

// insert translation
export const insertTranslationNexus = async (newWord) => {
  try {
    let key = newWord.trim();
    key = key.replace(/ /g, "_");
    key = key.toLowerCase();
    const data = {
      actualword: newWord,
      uniqueword: key,
    };
    const res = await axiosInstanceNexus.post(
      `${endPoints.addNewTranslation}`,
      data
    );
    toast.success("Translation added successfully");
    return res;
  } catch (error) {
    console.error(`Error posting translation: `, error);
    if (error?.response?.status === 409) {
      toast.error(error.response.data.message);
    } else {
      toast.error(
        <div>
          <ErrorMsgTranslator uniqueId="something_went_wrong" />
          <span> </span>
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            <ErrorMsgTranslator uniqueId="contact_support" />
          </a>
        </div>
      );
    }
    throw error;
  }
};

// edit translation
export const editTranslationNexus = async (key, newData) => {
  // if (
  //   !translation.unique_id ||
  //   !translation.English ||
  //   !translation.Telugu ||
  //   !translation.Hindi ||
  //   !translation.Spanish ||
  //   !translation.French ||
  //   !translation.German ||
  //   !translation.Chinese ||
  //   !translation.Russian ||
  //   !translation.Japanese ||
  //   !translation.Korean ||
  //   !translation.Arabic ||
  //   !translation.Italian ||
  //   !translation.Tamil ||
  //   !translation.Malayalam
  // ) {
  //   console.error(`Error posting translation: `, translation);
  //   toast.error("Translation is missing required fields");
  //   // throw error;
  //   return null;
  // }

  try {
    delete newData._id;
    delete newData.unique_id;
    const res = await axiosInstanceNexus.put(
      `${endPoints.editTranslation}/${key}`,
      { translations: newData }
    );
    toast.success("Translation updated successfully");
    return res;
  } catch (error) {
    console.error(`Error updating translation: `, error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

// delete translation
export const deleteTranslationsNexus = async (selectedRows) => {
  try {
    selectedRows.forEach(async (rowId) => {
      await axiosInstanceNexus.delete(
        `${endPoints.deleteTranslation}/${rowId}`
      );
    });
    toast.success("Translations deleted successfully");
  } catch (error) {
    console.error(`Error deleting translations: `, error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

// raise UI exception Log
export const raiseUIExceptionLog = async (log) => {
  try {
    const response = await axiosInstance.post(
      endPoints.raiseUiExceptionLog,
      log
    );
    return response;
  } catch (error) {
    console.error("Error raising UI Exception log:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};

export const getAllUIExceptionLog = async () => {
  try {
    const response = await axiosInstance.get(endPoints.getUiExceptionLog);
    return response;
  } catch (error) {
    console.error("Error raising request:", error);
    toast.error(
      <div>
        <ErrorMsgTranslator uniqueId="something_went_wrong" />
        <span> </span>
        <a
          href="mailto:ganeshg@aapmor.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          <ErrorMsgTranslator uniqueId="contact_support" />
        </a>
      </div>
    );
    throw error;
  }
};
