import { useTheme } from "@emotion/react";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";

const DelegateeDetails = ({
  finalData,
  setFinalData,
  delegationType,
  setDelegationType,
  status,
  setStatus,
  filteredDelegateeDetails,
  handleDelegate,
}) => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);

  const theme = useTheme();
  const today = dayjs().startOf("day");
  const columns = [
    {
      field: "userId",
      headerName: allTranslations?.user?.[userLang] + " ID" ?? "User",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
    },
    {
      field: "userName",
      headerName: allTranslations?.user_name?.[userLang] ?? "User Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
    },
    {
      field: "validFrom",
      headerName: allTranslations?.valid_from?.[userLang] ?? "Valid From",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ mt: 1.5 }}
            minDate={today}
            value={params.row.validFrom ? dayjs(params.row.validFrom) : null}
            onChange={(newDate) => {
              setFinalData((prev) => ({
                ...prev,
                validFrom: newDate ? newDate.format("MM-DD-YYYY") : "",
              }));
            }}
            slotProps={{ textField: { variant: "standard" } }}
          />
        </LocalizationProvider>
      ),
    },
    {
      field: "validTill",
      headerName: allTranslations?.valid_till?.[userLang] ?? "Valid Till",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ mt: 1.5 }}
            minDate={today}
            value={finalData.validTill ? dayjs(finalData.validTill) : null}
            shouldDisableDate={(date) => {
              if (!finalData.validFrom) {
                return false;
              }
              const isBeforeValidFrom = date.isBefore(
                dayjs(finalData.validFrom),
                "day"
              );
              const isAfterMaxRange = date.isAfter(
                dayjs(finalData.validFrom).add(30, "day"),
                "day"
              );
              return isBeforeValidFrom || isAfterMaxRange;
            }}
            onChange={(newDate) => {
              setFinalData((prev) => ({
                ...prev,
                validTill: newDate ? newDate.format("MM-DD-YYYY") : "",
              }));
            }}
            slotProps={{ textField: { variant: "standard" } }}
          />
        </LocalizationProvider>
      ),
    },

    {
      field: "status",
      headerName: allTranslations?.status?.[userLang] ?? "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params) => (
        <FormControl fullWidth sx={{ mt: 0.5 }}>
          <Select
            value={
              status === "active"
                ? "Active"
                : status.length === 0
                ? "Select Status"
                : "Inactive"
            }
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Select Status</em>;
              }

              return selected;
            }}
            onChange={(event) => {
              setStatus(event.target.value);
              setFinalData((prev) => ({
                ...prev,
                status: event.target.value,
              }));
            }}
            size="small"
            displayEmpty
          >
            <MenuItem value="active">
              {allTranslations?.active?.[userLang] ?? "Active"}
            </MenuItem>
            <MenuItem value="inactive">
              {allTranslations?.inactive?.[userLang] ?? "Inactive"}
            </MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: "type",
      headerName: allTranslations?.type?.[userLang] ?? "Type",
      headerClassName: "super-app-theme--header",
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params) => (
        <FormControl fullWidth sx={{ mt: 0.5 }}>
          <Select
            value={
              delegationType.length === 0
                ? "Select Type"
                : delegationType === "accessControl"
                ? "Access Control"
                : delegationType === "processControl"
                ? "Process Control"
                : "Both"
            }
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Select Type</em>;
              }

              return selected;
            }}
            onChange={(event) => {
              setDelegationType(event.target.value);
              setFinalData((prev) => ({
                ...prev,
                type: event.target.value,
              }));
            }}
            size="small"
            displayEmpty
          >
            <MenuItem value="accessControl">
              {allTranslations?.access_control?.[userLang] ?? "Access Control"}
            </MenuItem>
            <MenuItem value="processControl">
              {allTranslations?.process_control?.[userLang] ??
                "Process Control"}
            </MenuItem>
            <MenuItem value="both">
              {allTranslations?.both?.[userLang] ?? "Both"}
            </MenuItem>
          </Select>
        </FormControl>
      ),
    },
  ];
  const rows = filteredDelegateeDetails.map((user, index) => ({
    id: index,
    userId: user?.Uid,
    userName: user?.FullName,
  }));
  const isFormValid = () => {
    const { validFrom, validTill, type, status } = finalData;
    return (
      filteredDelegateeDetails &&
      validFrom.trim() !== "" &&
      validTill.trim() !== "" &&
      type.trim() !== "" &&
      status.trim() !== ""
    );
  };
  return (
    <>
      <Box
        sx={(theme) => ({
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.dataGrid.headerBg,
          },
        })}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          // checkboxSelection
          disableRowSelectionOnClick
          localeText={{ noRowsLabel: "No Data Yet" }}
          sx={{
            bgcolor: theme.palette.background.default,
            minHeight: 200,
          }}
        />
      </Box>
      {rows.length > 0 ? (
        <Stack alignItems={"end"} mt={5}>
          <Button
            variant="contained"
            onClick={handleDelegate}
            disabled={!isFormValid()}
            sx={{
              boxShadow: "none",
              bgcolor: theme.palette.icons.bgDark,
              color: theme.palette.text.white,
              border: `1px solid ${theme.palette.icons.bgDark}`,
              ":hover": {
                border: `1px solid ${theme.palette.primary.border}`,
                bgcolor: theme.palette.background.default,
                color: theme.palette.text.thin,
                boxShadow: "none",
              },
            }}
          >
            Delegate
          </Button>
        </Stack>
      ) : null}
    </>
  );
};

export default DelegateeDetails;
