import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProductFilters = ({ setProductFilters, setInitial }) => {
  const [platform, setPlatform] = useState("");
  const [system, setSystem] = useState("");
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const handleClearFilters = () => {
    setSearch("");
    setPlatform("");
    setSystem("");
    setRole("");
    setInitial(true);
    setProductFilters({
      platform: "",
      system: "",
      search: "",
      role: "",
    });
  };

  const handleChangePlatform = (event) => {
    setPlatform(event.target.value);
    setInitial(false);
    setProductFilters((prevFilters) => ({
      ...prevFilters, // Spread the previous filter values
      platform: event.target.value, // Update the platform
    }));
  };

  const handleChangeSystem = (event) => {
    setSystem(event.target.value);
    setInitial(false);
    setProductFilters((prevFilters) => ({
      ...prevFilters, // Spread the previous filter values
      system: event.target.value, // Update the system
    }));
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setInitial(false);
    setProductFilters((prevFilters) => ({
      ...prevFilters, // Spread the previous filter values
      search: event.target.value, // Update the search
    }));
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
    setInitial(false);
    setProductFilters((prevFilters) => ({
      ...prevFilters, // Spread the previous filter values
      role: event.target.value, // Update the role
    }));
  };
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const { pathname } = useLocation();
  const isElevatedRequest = pathname.includes("/elevated-request");

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-around"}
      sx={{
        bgcolor: "background.secondary",
        borderRadius: "5px",
        py: 3,
        px: 2,
        gap: 2,
      }}
    >
      {/* Search Product here... */}
      {!isElevatedRequest && (
        <TextField
          sx={{ maxWidth: 250, bgcolor: "primary.darkerShade" }}
          size="small"
          label={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                bgcolor: "#1C372B",
              }}
            >
              <SearchIcon style={{ marginRight: 8 }} />
              {allTranslations?.search_product_here?.[userLang] ??
                "Search Product here"}
              ...
            </div>
          }
          value={search}
          onChange={handleSearchChange}
        />
      )}
      {/* Select Platform  */}
      <Box sx={{ minWidth: 200 }}>
        <FormControl fullWidth>
          <InputLabel
            sx={{ fontSize: 16, mt: -0.9 }}
            id="platform-select-label"
          >
            {allTranslations?.select_platform?.[userLang] ?? "Select Platform"}
          </InputLabel>
          <Select
            labelId="platform-select-label"
            id="platform-select"
            value={platform}
            size="small"
            label="Select Platform"
            onChange={handleChangePlatform}
            sx={{
              bgcolor: "primary.darkerShade",
              color: "text.primary",
            }}
          >
            {/* <MenuItem value="">All Platforms</MenuItem> */}
            <MenuItem value="PROJECT CAPITAL REQUESTOR">
              PROJECT CAPITAL REQUESTOR
            </MenuItem>
            <MenuItem value="PROJECT NONCAPITAL REQUESTOR">
              PROJECT NONCAPITAL REQUESTOR
            </MenuItem>
            <MenuItem value="QM ANALYST">QM ANALYST</MenuItem>
            <MenuItem value="QM ANALYST AND DDL">QM ANALYST AND DDL</MenuItem>
            <MenuItem value="QM ANALYST BASIC">QM ANALYST BASIC</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {/* Select System */}
      <Box sx={{ minWidth: 200 }}>
        <FormControl fullWidth>
          <InputLabel sx={{ fontSize: 16, mt: -0.9 }} id="system-select-label">
            {allTranslations?.select_system?.[userLang] ?? "Select System"}
          </InputLabel>
          <Select
            labelId="system-select-label"
            id="system-select"
            value={system}
            label="Select System"
            size="small"
            onChange={handleChangeSystem}
            sx={{
              bgcolor: "primary.darkerShade",
              color: "text.primary",
            }}
          >
            <MenuItem value="A05CLNTOOO">A05CLNTOOO</MenuItem>
            <MenuItem value="A05CLNT050">A05CLNT050</MenuItem>
            <MenuItem value="A1 UCLNT300">A1 UCLNT300</MenuItem>
            <MenuItem value="A28CLNTOOO">A28CLNTOOO</MenuItem>
            <MenuItem value="A28CLNT050">A28CLNT050</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {/* Search FF Object here... */}
      {isElevatedRequest && (
        <TextField
          sx={{ maxWidth: 300, bgcolor: "primary.darkerShade" }}
          size="small"
          label={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                bgcolor: "#1C372B",
              }}
            >
              <SearchIcon style={{ marginRight: 8 }} />
              {allTranslations?.search_ff_object_here?.[userLang] ??
                "Search FF Object here"}
              ...
            </div>
          }
          value={search}
          onChange={handleSearchChange}
        />
      )}
      {/* Type role here... */}
      <TextField
        disabled
        sx={{
          minWidth: 250,
          bgcolor: "primary.darkerShade",
        }}
        size="small"
        label={
          allTranslations?.type_role_here?.[userLang] + "..."
            ? "Type role here..."
            : "Type role here"
        }
        value={role}
        onChange={handleRoleChange}
      />
      <Button
        variant="contained"
        onClick={(e) => {
          e.stopPropagation();
          handleClearFilters();
        }}
        sx={{
          px: 2,
          bgcolor: "secondary.main",
          color: "text.contrastText",
        }}
      >
        {allTranslations?.clear?.[userLang] ?? "CLEAR."}
      </Button>
    </Stack>
  );
};

export default ProductFilters;
