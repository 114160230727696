export function formatTranslationData(translationData) {
  let translationsMap = sessionStorage.getItem("translationsMap");
  if (!translationsMap) {
    translationsMap = {};
    translationData.forEach((data) => {
      translationsMap[data.key] = data;
    });
    sessionStorage.setItem("translationsMap", JSON.stringify(translationsMap));
    // console.log("new translationsMap", translationsMap);
  } else {
    // console.log("old translationsMap", translationsMap);
  }
  return true;
  // sessionStorage.setItem("translations", JSON.stringify(res.data));
  // const test = sessionStorage.getItem("translations");
  // console.log("trans:", JSON.parse(test));
}

export async function formatAllTranslations(translationData) {
  let translationsMap = {};
  translationData.forEach((data) => {
    translationsMap[data.unique_id] = data;
  });
  // console.log("new translationsMap", translationsMap);
  return translationsMap;
}

export function formatAllUsers(allUsersData) {
  const usersInfoMap = {};
  allUsersData.forEach((data) => {
    usersInfoMap[data.Uid] = data;
  });
  return usersInfoMap;
}

export function formatHierarchy(allHierarchy) {
  const hierarchyInfoMap = {};
  allHierarchy.forEach((data) => {
    hierarchyInfoMap[data.Uid] = data;
  });
  return hierarchyInfoMap;
}
