import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Box } from "@mui/system";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

const RequestsTable = ({ requestsData, status, path }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [view, setView] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const requestsPerPage = 4;
  const indexOfLastRequest = currentPage * requestsPerPage;
  const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
  const currentRequests = requestsData
    ? requestsData.slice(indexOfFirstRequest, indexOfLastRequest)
    : [];


  const totalPages = Math.ceil(
    requestsData ? requestsData.length : 0 / requestsPerPage
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);

  const changeIssuedDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const columns = [
    {
      field: "userId",
      headerName: allTranslations?.user?.[userLang] + " ID" ?? "User ID",
      headerClassName: "super-app-theme--header",
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <Link
          style={{
            color: theme.palette.table.textLight,
            textDecoration: "none",
            textAlign: "left",
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "requestId",
      headerName: allTranslations?.request?.[userLang] + "ID" ?? "Request ID",
      headerClassName: "super-app-theme--header",
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Link
          style={{
            color: theme.palette.table.textLight,
            textDecoration: "none",
            textAlign: "left",
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "raised",
      headerName: allTranslations?.raised?.[userLang] ?? "Raised",
      headerClassName: "super-app-theme--header",
      flex: 1,
      textAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => (
        <Link
          style={{
            color: theme.palette.table.textLight,
            textDecoration: "none",
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "requestType",
      headerName: allTranslations?.type?.[userLang] ?? "Type",
      headerClassName: "super-app-theme--header",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Link
          style={{
            color: theme.palette.table.textLight,
            textDecoration: "none",
          }}
        >
          {allTranslations?.[
            params.value?.toLowerCase().split(" ").join("_")
          ]?.[userLang] ?? params.value}
        </Link>
      ),
    },
    {
      field: "delegation",
      headerName: "",
      headerClassName: "super-app-theme--header",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Link
          style={{
            color: theme.palette.table.textLight,
            textDecoration: "none",
          }}
        >
          {params.value ? "Delegated" : ""}
        </Link>
      ),
    },
  ];

  return (
    <>
      {currentRequests.length > 0 ? (
        <>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={1}
          >
            <Typography color={theme.palette.text.bold}>
              {allTranslations?.recent_requests?.[userLang] ??
                "Recent Requests"}
            </Typography>
            {/* <IconButton onClick={() => setView(!view)}>
              {view ? <TuneIcon /> : <GridViewIcon />}
            </IconButton> */}
            <Button
              variant="contained"
              sx={{
                bgcolor: theme.palette.primary.main,
                color: theme.palette.text.contrastText,
              }}
              onClick={() => navigate(`${location.pathname}/requests`)}
            >
              {allTranslations?.view_more?.[userLang] ?? "View More"}
            </Button>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DataGridDemo
                requestRows={requestsData.sort(
                  (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
                )}
                requestColumns={columns}
                path={path}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // mt: 10,
            color: "black",
            height: 300,
          }}
        >
          <Typography variant="h6" align="center">
            {allTranslations?.no_requests_found?.[userLang] ??
              "No requests found"}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default RequestsTable;

function DataGridDemo({ requestRows, requestColumns, path }) {
  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleRowClick = (params) => {
    const requestId = params.row.requestId;
    // Navigate to /approver/{requestId}
    navigate(`/${path}/${requestId}`, {
      state: {
        isDelegation: params.row.delegation,
        delegatorId: params.row.delegatorId,
      },
    });
  };

  return (
    <Box
      sx={(theme) => ({
        height: 270,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: theme.palette.dataGrid.headerBg,
        },
      })}
    >
      <DataGrid
        rows={requestRows.map((request) => ({
          id: request.requestId, // DataGrid expects a unique "id" field
          userId: request.requestorId,
          requestId: request.requestId,
          raised: new Date(request.updatedAt).toLocaleDateString(),
          requestType: request.requestType,
          delegation: request.delegation,
          delegatorId: request.delegatorId,
        }))}
        columns={requestColumns}
        disableColumnResize
        onRowClick={handleRowClick} // Handle row click event
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 3,
            },
          },
        }}
        pageSizeOptions={[3]}
        disableRowSelectionOnClick
      />
    </Box>
  );
}
