import { Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
const UserInfoStack = ({ label, value }) => (
  <Stack direction="row" gap={1} alignItems="center">
    <Typography sx={{ fontSize: 16, color: "text.primary", fontWeight: 600 }}>
      {label}:
    </Typography>
    <Typography sx={{ fontSize: 16, color: "text.secondary" }}>
      {value ?? "N/A"}
    </Typography>
  </Stack>
);
const UserInfo = ({ details, isSelf }) => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);
  return (
    <Stack
      direction="row"
      py={1}
      px={3}
      mt={1}
      justifyContent="space-between"
      alignItems="center"
    >
      <UserInfoStack
        label={allTranslations?.user?.[userLang] ?? "User ID"}
        value={`${details?.Uid ?? "N/A"} ${
          isSelf ? `(${allTranslations?.self?.[userLang] ?? "Self"})` : ""
        }`}
      />
      <UserInfoStack
        label={allTranslations?.user_name?.[userLang] ?? "User Name"}
        value={details?.FullName}
      />
      <UserInfoStack
        label={allTranslations?.email?.[userLang] ?? "Email"}
        value={details?.Email}
      />
      <UserInfoStack
        label={allTranslations?.manager_name?.[userLang] ?? "Manager Name"}
        value={details?.Manager}
      />
    </Stack>
  );
};

export default UserInfo;
