import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLangTranslations,
  getAllTranslations,
  getAllTranslationsInSingleLanguage,
} from "../../../apiCalls/allApiCalls";
import {
  formatAllTranslations,
  formatTranslationData,
} from "../../../utilities/helperMethods";

// First, create the thunk
export const fetchLangTranslations = createAsyncThunk(
  "translations/fetchLangTranslations",
  async () => {
    console.log("fetchLangTranslations called");
    const response = await getLangTranslations();
    // convert the translations data to a map
    formatTranslationData(response.data);
    // get the map from session storage
    const data = sessionStorage.getItem("translationsMap");
    if (data) {
      const finalData = JSON.parse(data);
      return finalData;
    }
    return {};
  }
);

export const fetchAllTranslations = createAsyncThunk(
  "translations/fetchAllTranslations",
  async () => {
    const response = await getAllTranslations();
    // console.log("fetchAllTranslations called", response.data);
    const data = await formatAllTranslations(response.data);
    console.log("all translations", data)
    return data || {};
  }
);

export const fetchUserLanguageTranslations = createAsyncThunk(
  "translations/fetchUserLanguageTranslations",
  async () => {
    const response = await getAllTranslations();
    // console.log("fetchAllTranslations called", response.data);
    const data = await formatAllTranslations(response.data);
    return data || {};
  }
);

const initialState = {
  loading: true,
  translations: {},
  allTranslations: {},
  userLanguageTranslations: {},
};

export const translationsSlice = createSlice({
  name: "translations",
  initialState,
  reducers: {
    // function to set translations
    setTranslations: (state, action) => {
      state.translations = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handling fetchLangTranslations Thunk
    builder
      .addCase(fetchLangTranslations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLangTranslations.fulfilled, (state, action) => {
        state.loading = false;
        state.translations = action.payload;
      })
      .addCase(fetchLangTranslations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // Handling fetchAllTranslations Thunk
    builder
      .addCase(fetchAllTranslations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllTranslations.fulfilled, (state, action) => {
        state.loading = false;
        state.allTranslations = action.payload;
      })
      .addCase(fetchAllTranslations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // Handling fetchUserLanguageTranslations Thunk
    builder
      .addCase(fetchUserLanguageTranslations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserLanguageTranslations.fulfilled, (state, action) => {
        state.loading = false;
        state.userLanguageTranslations = action.payload;
      })
      .addCase(fetchUserLanguageTranslations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setTranslations } = translationsSlice.actions;
export default translationsSlice.reducer;
