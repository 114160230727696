import {
  Box,
  CircularProgress,
  Container,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniMenu from "../common/miniMenu/MiniMenu";
import BreadCrumbs from "../common/breadCrumbs/BreadCrumbs";
import { DataGrid, GridOverlay, GridToolbar } from "@mui/x-data-grid";
import { getAllUIExceptionLog } from "../../apiCalls/allApiCalls";
import { textAlign } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { localeText } from "../../pages/Root";
import { useSelector } from "react-redux";
function CustomNoDataOverlay() {
  return (
    <GridOverlay>
      <Typography color="text.secondary">No Data Yet</Typography>
    </GridOverlay>
  );
}
const UIExceptionLogs = () => {
  const [uiLogs, setUiLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );

  const getAllUILogs = async () => {
    try {
      setIsLoading(true);
      const response = await getAllUIExceptionLog();

      if (response.status === 200) {
        setUiLogs(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Failed to fetch feedbacks");
    }
  };

  useEffect(() => {
    getAllUILogs();
  }, []);

  // To change the format of the date
  const DateTime = (params) => {
    const utcDate = new Date(params.value); // Convert `updatedAt` to Date object assuming UTC

    // Convert UTC date to IST
    const istDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );

    // Format the date and time as "dd-mm-yyyy, hh:mm:ss"
    const formattedDate = `${String(istDate.getDate()).padStart(
      2,
      "0"
    )}-${String(istDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${istDate.getFullYear()}, ${String(istDate.getHours()).padStart(
      2,
      "0"
    )}:${String(istDate.getMinutes()).padStart(2, "0")}:${String(
      istDate.getSeconds()
    ).padStart(2, "0")}`;
    return formattedDate;
  };
  // Today's date and time in "DD-MM-YYYY_HH-mm-ss" format
  const todayWithTime = dayjs().format("DD-MM-YYYY_HH-mm-ss");
  const columns = [
    //fullName,phoneNumber,email,source,resume,status,actions

    {
      field: "Uid",
      headerName: allTranslations?.user?.[userLang] + "ID" ?? "User ID",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "userName",
      headerName: allTranslations?.user_name?.[userLang] ?? "User Name",

      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "email",
      headerName: allTranslations?.email?.[userLang] ?? "Email",

      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: allTranslations?.date_and_time?.[userLang] ?? "Date and Time",
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: false,
      renderCell: (params) => {
        return (
          <span
            title={DateTime(params)} // This will show the full date and time on hover
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              display: "block",
            }}
          >
            {DateTime(params)}
          </span>
        );
      },
    },
    {
      field: "flowId",
      headerName: allTranslations?.user_flow?.[userLang] ?? "User FLow",
      editable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "errorStack",
      headerName: allTranslations?.error_stack?.[userLang] ?? "Error Stack",
      editable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip
            title={params.row.errorStack || "No error details available"}
            arrow
          >
            <Box mt={1}>
              <ErrorOutlineIcon color="error" />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "deviceName",
      headerName: allTranslations?.device?.[userLang] ?? "Device",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "browserVersion",
      headerName: allTranslations?.browser?.[userLang] ?? "Browser",
      editable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
  ];

  // Define translations for the toolbar
  const localeText = {
    toolbarColumns: allTranslations?.columns?.[userLang] ?? "Columns",
    toolbarFilters: allTranslations?.filters?.[userLang] ?? "Filters",
    toolbarDensity: allTranslations?.density?.[userLang] ?? "Density",
    toolbarExport: allTranslations?.export?.[userLang] ?? "Export",
    toolbarQuickFilterPlaceholder:
      allTranslations?.search?.[userLang] ?? "Search",
  };
  const getRowId = (row) => row._id;

  return (
    <Box width={"95%"} m={"auto"}>
      <MiniMenu />
      <Container
        sx={{
          p: 4,
          bgcolor: "background.default",
          borderRadius: 5,
          minHeight: "80vh",
          minWidth: "100%",
          // m: "auto",
        }}
      >
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BreadCrumbs />
        </Box>
        <Box
          sx={(theme) => ({
            // height: 350,
            width: "100%",
            mt: 2,
            minHeight: "200px",
            "& .super-app-theme--header": {
              backgroundColor: theme.palette.dataGrid.headerBg,
            },
          })}
        >
          {!isLoading ? (
            <DataGrid
              localeText={localeText}
              rows={uiLogs}
              getRowId={getRowId}
              columns={columns}
              checkboxSelection
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, { value: -1, label: "All" }]}
              slots={{
                toolbar: GridToolbar,
                noRowsOverlay: CustomNoDataOverlay,
              }}
              sx={{
                minHeight: 200,
                ".css-t89xny-MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  csvOptions: {
                    fileName: `User Feedback - ${todayWithTime}`,
                  },
                },
              }}
              disableRowSelectionOnClick
            />
          ) : (
            <Box>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default UIExceptionLogs;
