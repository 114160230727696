import { Box, Button, Container, Stack, Typography, Grid } from "@mui/material";
import React from "react";
import MiniMenu from "../components/common/miniMenu/MiniMenu";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
// import Grid from "@mui/material/Grid2";

const Settings = () => {
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const theme = useTheme();
  const navigate = useNavigate();
  const buttonData = [
    {
      text: allTranslations?.user_feedbacks?.[userLang] ?? "User Feedbacks",
      url: "/admin/feedback",
    }, // Internal route
    {
      text:
        allTranslations?.api_exception_logs?.[userLang] ?? "API Exception Logs",
      url: "/admin/api-exception-logs",
    }, // Internal route
    {
      text:
        allTranslations?.ui_exception_logs?.[userLang] ?? "UI Exception Logs",
      url: "/admin/ui-exception-logs",
    }, // External URL
    {
      text:
        allTranslations?.language_translations?.[userLang] ??
        "Language translations",
      url: "/admin/language-translations",
    }, // External URL
  ];
  return (
    <Box width={"95%"} m={"auto"}>
      <MiniMenu />
      <Container
        sx={{
          p: 4,
          bgcolor: "background.default",
          borderRadius: 5,
          minHeight: "80vh",
          minWidth: "100%",
          // m: "auto",
        }}
      >
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BreadCrumbs />
        </Box>
        <Grid
          container
          spacing={2}
          justifyContent={"left"}
          alignItems={"left"}
        >
          {buttonData.map(({ text, url }) => (
            <Grid item xs={4}>
              <Box
                onClick={() => {
                  if (url.startsWith("http")) {
                    window.open(url, "_blank"); // Opens external URL in a new tab
                  } else {
                    navigate(url); // Navigates to internal route
                  }
                }}
                key={text}
                height={150}
                width={300}
                border={"1px solid"}
                borderColor={theme.palette.icons.bgDark}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  borderRadius: "8px",
                  cursor: "pointer",
                  // color: "#858585",
                  bgcolor: theme.palette.background.default,
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <Typography color={"text.secondary"} fontWeight={600}>
                  {text}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Settings;
