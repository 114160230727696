import {
  Container,
  Grid,
  Box,
  Stack,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import {
  getProfileDetails,
  getRequestDetails,
  getUserDetailsByUid,
} from "../../apiCalls/allApiCalls";
import Cookies from "js-cookie";

import RequestTile from "./RequestTile";
import RequestStatusTile from "../common/tile/RequestStatusTile";

const RequestTiles = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const { loading: translationsLoading, translations } = useSelector(
    (state) => state.translations
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );
  // console.log(profile, "123ddd");

  const [requestsData, setRequestsData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);

  // Fetch requests data when profile details are available
  useEffect(() => {
    if (!profile) return;

    const fetchAllData = async () => {
      try {
        // Fetch request details
        const query = `userId=${profile.Uid}`;

        const requestResponse = await getRequestDetails(query);

        if (requestResponse?.status === 200) {
          const requestsData = requestResponse.data;
          setRequestsData(requestsData);
          // console.log(requestsData, "Requestsaaa");

          // Fetch user details using the UID from the request data
          // const userDetailsResponse = await getUserDetailsByUid(
          //   requestsData?.Uid
          // );
        } else {
          throw new Error("Failed to fetch request details");
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchAllData();
  }, [profile]);

  const changeIssuedDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };
  // console.log(requestsData, "hello");
  return (
    <Grid
      container
      item
      bgcolor={theme.palette.tiles.bg}
      border={`1px solid ${theme.palette.primary.border}`}
      alignContent={"flex-start"}
      xs={12}
      sx={{
        px: 1.5,
        py: 1.5,
        borderRadius: "8px",
        height: "72%",
      }}
      gap={"10px"}
    >
      <Grid
        item
        xs={12}
        sx={{
          "&.MuiGrid-root.MuiGrid-item": {
            px: 0,
          },
          maxHeight: "40px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 700,
            color: theme.palette.text.bold,
          }}
        >
          {allTranslations?.recent_requests?.[userLang] ?? "Recent Requests"}
        </Typography>
      </Grid>
      {requestsData.length !== 0 ? (
        requestsData
          ?.slice(-4)
          .reverse()
          .map((data, index) => (
            <Grid
              key={data.requestId} // Make sure to add a unique key for each item
              item
              xs={5.89}
              gap={2}
              sx={{
                "&.MuiGrid-root.MuiGrid-item": {
                  px: 0,
                },
              }}
            >
              {/* <RequestStatusTile
                requestId={data.requestId}
                requestorId={data.requestorId}
                issueDate={changeIssuedDate(data.updatedAt)}
                status={data.status}
                path={data.path}
                userName={data.userDetails.fullName}
                customFontSize={10}
              /> */}
              <RequestTile
                requestId={data?.requestId}
                requestorId={data?.requestorId}
                issueDate={changeIssuedDate(data?.updatedAt)}
                status={data?.status}
                path={`/request-details/${data.requestId}`}
                userName={data?.userDetails?.fullName}
                customFontSize={10}
                requestType={data?.requestType}
              />
            </Grid>
          ))
      ) : (
        <Grid
          item
          xs={12}
          sx={{
            "&.MuiGrid-root.MuiGrid-item": {
              px: 0,
              py: 13,
            },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ color: theme.palette.text.thin, textAlign: "center" }}>
            {allTranslations?.no_recent_requests_to_display?.[userLang] ??
              "No recent requests to display"}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default RequestTiles;
