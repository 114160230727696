import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserDetailsByUid } from "../../../apiCalls/allApiCalls";

// First, create the thunk
export const fetchUserLanguage = createAsyncThunk(
  "users/fetchUserLanguage",
  async (Uid) => {
    console.log("fetchUserLanguage called");
    const response = await getUserDetailsByUid(Uid);
    // console.log("first", response);
    if (response.data.languagePreference) {
      return response.data.languagePreference;
    }
    return "English";
  }
);

const initialState = {
  loading: true,
  language: "English",
};

export const userLanguageSlice = createSlice({
  name: "userLanguage",
  initialState,
  reducers: {
    // function to set user language
    setUserLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserLanguage.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchUserLanguage.fulfilled, (state, action) => {
      state.loading = false;
      state.language = action.payload;
    });
    builder.addCase(fetchUserLanguage.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setUserLanguage } = userLanguageSlice.actions;
export default userLanguageSlice.reducer;
