import React from "react";
import { useRouteError } from "react-router-dom";
import { Stack } from "@mui/material";

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);
  return (
    <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </Stack>
  );
};

export default ErrorPage;
