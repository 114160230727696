import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

const DataGridDemo = (props) => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const theme = useTheme();
  // Ensure props.cart is defined and fallback to an empty array if not
  const initialCart = props.cart || [];

  const [rows, setRows] = useState(
    initialCart.map((row, index) => ({
      id: index,
      productName: row.productDetails?.productName || "Unknown Product", // Safely access productName
      // validTill: row.validTill,
      // validFrom: row.validFrom,

      productId: row.productDetails?.productId, // Safely access productId
    }))
  );

  const [finalAddedItems, setFinalAddedItems] = useState(
    props.addedItems || {}
  );

  const handleDelete = (obj) => {
    // Safeguard to check if obj exists and has the required properties
    if (!obj || obj.productId === undefined) {
      console.error("Invalid object for deletion:", obj);
      return;
    }

    // Log the necessary details for debugging

    // Filter out the row that is being deleted
    const updatedRows = rows.filter((row) => row.id !== obj.id);

    //  update the cart rows
    const updatedCartRows = props.cart.filter(
      (product) => product.productId !== obj.productId
    );

    setRows(updatedRows); // Update local rows state

    // Remove the product from finalAddedItems immutably
    const updatedAddedItems = { ...finalAddedItems };
    delete updatedAddedItems[obj.productId]; // Delete the product by its ID
    setFinalAddedItems(updatedAddedItems); // Update local finalAddedItems state

    // Update the parent component states if necessary
    props.setCart(updatedCartRows);
    props.setAddedItems && props.setAddedItems(updatedAddedItems);
  };

  const handleDeleteAll = () => {
    setRows([]); // Clear all rows
    setFinalAddedItems({}); // Clear finalAddedItems
    props.setCart && props.setCart([]); // Clear the cart in parent component
    props.setAddedItems && props.setAddedItems({}); // Clear added items in parent component
  };

  const columns = [
    {
      field: "productName",
      headerName:
        allTranslations?.product_details?.[userLang] ?? "Product Details",
      headerClassName: "super-app-theme--header",
      flex: 1,
      textAlign: "center",
      editable: true,
      resizable: false,
      sortable: false,
    },
    {
      field: "productId",
      headerName: allTranslations?.product?.[userLang] + "Id" ?? "Product",
      headerClassName: "super-app-theme--header",
      flex: 1,
      textAlign: "center",
      editable: true,
      resizable: false,
      sortable: false,
    },
    // {
    //   field: "validTill",
    //   headerName: "Valid Till",
    //   flex:1,
    // textAlign:"center",
    //   editable: true,
    //   resizable: false,
    //   sortable: false,
    // },
    {
      field: "delete",
      headerName: allTranslations?.delete?.[userLang] ?? "Delete",
      headerClassName: "super-app-theme--header",
      resizable: false,
      sortable: false,
      flex: 1,
      textAlign: "center",
      renderCell: (params) => (
        <IconButton color="secondary" onClick={() => handleDelete(params.row)}>
          <DeleteOutlinedIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box sx={{ height: 330, width: "100%" }}>
      {/* Delete All Button */}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "29.05px",
            letterSpacing: "0.02em",
            textAlign: "left",
          }}
        >
          {allTranslations?.product_details?.[userLang] ?? "Cart"}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDeleteAll}
          size="small"
          sx={{
            marginBottom: 2,
            boxShadow: "none",
            bgcolor: theme.palette.icons.bgDark,
            color: theme.palette.text.white,
            border: `1px solid ${theme.palette.icons.bgDark}`,
            ":hover": {
              border: `1px solid ${theme.palette.primary.border}`,
              bgcolor: theme.palette.background.default,
              color: theme.palette.text.thin,
              boxShadow: "none",
            },
          }}
        >
          {allTranslations?.delete_all?.[userLang] ?? "Delete All"}
        </Button>
      </Box>
      <Box
        sx={(theme) => ({
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.dataGrid.headerBg,
          },
          minHeight: 200,
        })}
      >
        {/* Data Grid */}
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 4,
              },
            },
          }}
          sx={{ minHeight: 200 }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          localeText={{
            noRowsLabel:
              allTranslations?.no_products_are_added?.[userLang] ??
              "No products are added",
          }}
        />
      </Box>
    </Box>
  );
};

export default DataGridDemo;
