import React, { useEffect, useState } from "react";
import {
  Drawer,
  TextField,
  Typography,
  IconButton,
  Button,
  Box,
  Stack,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete"; // Import DeleteIcon for X mark
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { raiseRequest } from "../../apiCalls/allApiCalls";
import FeedbackForm from "../feedbackform/FeedbackForm";
import { tileShadows } from "../../pages/Root";
import { useTheme } from "@emotion/react";

const formatDate = (date) => {
  if (!date) return null;
  return dayjs(date).format("DD-MM-YYYY");
};

const RenweDrawer = ({
  isOpen,
  toggleDrawer,
  rows,
  setIsOpen,
  requestType,
  userID,
  handleRenewDrawerCLose,
  setSelectedRows,
}) => {
  const theme = useTheme();
  const [requestsData, setRequestsData] = useState({
    requestorId: userID,
    requestType: "Renew",
    requestData: rows,
    status: "In Progress",
  });

  useEffect(() => {
    setRequestsData((prev) => ({
      ...prev,
      requestorId: userID,
      requestType: "Renew",
      requestData: rows,
    }));
  }, [rows, userID]);

  const [validFrom, setValidFrom] = useState(dayjs()); // Single validFrom for all products, starting from today
  const [validTill, setValidTill] = useState(); // Single validTill for all products, starting from today
  const [comment, setComment] = useState("");
  const today = dayjs(); // Get today's date to restrict past dates
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [loading, setLoading] = useState(false);

  // Handle date change
  const handleDateChange = (date, field) => {
    if (field === "validFrom") {
      setValidFrom(date);
    } else if (field === "validTill") {
      setValidTill(date);
    }
  };

  // Handle comment input change
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  // Handle removing a product when X is clicked
  // const handleRemoveProduct = (productId) => {
  //   console.log(productId, "hello");
  //   const updatedProducts = requestsData.requestData.filter(
  //     (product) => product.productID !== productId
  //   );
  //   setRequestsData((prev) => ({
  //     ...prev,
  //     requestData: updatedProducts,
  //   }));
  //   console.log(requestsData, "hello");
  //   // If no products left, close the drawer
  //   if (updatedProducts.length === 0) {
  //     handleRenewDrawerCLose();
  //   }
  // };
  const handleRemove = (riskId) => {
    setSelectedRows((prevSelectedRows) => {
      const updatedRows = prevSelectedRows.filter((row) => row.id !== riskId); // Remove the row with the matching id

      if (updatedRows.length === 0) {
        handleRenewDrawerCLose();
        setComment(""); // Close the drawer when no rows are left
      }

      return updatedRows;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Disable the button after clicking

    try {
      const data = {
        ...requestsData,
        requestData: {
          productsData: requestsData.requestData.map((product) => ({
            ...product,
            validFrom: formatDate(validFrom),
            validTill: formatDate(validTill),
            status: "In Progress",
          })),
          comments: comment,
        },
      };
      const response = await raiseRequest(data);
      if (response.status === 201) {
        toast.success("Request submitted successfully!");
        handleOpen();
      } else {
        toast.error(
          <div>
            Request submission failed!
            <a
              href="mailto:ganeshg@aapmor.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              contact support
            </a>
            .
          </div>
        );
      }
    } catch (error) {
      toast.error(
        <div>
          Failed to post requests!
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            contact support
          </a>
          .
        </div>
      );
      console.error("Failed to post requests:", error);
    } finally {
      setLoading(false); // Re-enable the button after submission completes
    }
  };

  return (
    <div>
      <Drawer
        anchor="bottom"
        open={isOpen}
        PaperProps={{
          sx: {
            width: "88%",
            margin: "0 auto",
            borderTopLeftRadius: 24, // Curve for top-left
            borderTopRightRadius: 24, // Curve for top-right
            // backgroundColor: "primary.mainGradient1", // Ensures background covers rounded area
            overflow: "hidden", // Prevents content overflow at the rounded corners
          },
        }}
      >
        <Box height={390} width={"98%"} bgcolor={"primary.mainGradient1"}>
          <Box height={2} bgcolor={"secondary.main"}></Box>
          <IconButton
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              float: "right",
            }}
            onClick={() => {
              setComment(""); // Reset the comment field
              handleRenewDrawerCLose(); // Properly close the drawer
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box px={8} py={3}>
            <Typography fontSize={18} fontWeight={600}>
              Renew Products:
            </Typography>
            <Stack spacing={2} direction={"row"} mt={2}>
              {requestsData.requestData.map((product, index) => (
                <Box
                  key={product.productId}
                  sx={{
                    mt: 2,
                    border: "1px dashed",
                    borderRadius: 2,
                    borderColor: "secondary.main",
                    p: 1,
                    display: "inline-flex", // Updated to inline-flex for X mark
                    alignItems: "center", // Align text and icon
                  }}
                >
                  <Typography fontSize={12}>{product.productName}</Typography>
                  <IconButton
                    size="small"
                    onClick={() => handleRemove(index)}
                    sx={{ ml: 1 }}
                  >
                    <DeleteIcon fontSize="small" sx={{ color: "tomato" }} />
                  </IconButton>
                </Box>
              ))}
            </Stack>

            <Stack direction={"row"} gap={3}>
              <Box>
                <Typography fontSize={18} fontWeight={600} mt={3}>
                  Comment{" "}
                  <span
                    style={{
                      color: "tomato",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Box
                    sx={{
                      bgcolor: "primary.mainGradient2",
                      border: `1px solid ${theme.palette.primary.border}`,
                      ...tileShadows,
                      height: 150,
                      width: 600,
                      borderRadius: 3,
                      px: 3,
                      py: 3,
                      overflow: "auto", // Allows scrolling if text exceeds the area
                    }}
                  >
                    <TextField
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      placeholder="Type your comment here..."
                      multiline
                      value={comment}
                      onChange={handleCommentChange}
                      sx={{
                        minWidth: 400,
                        border: "none",
                      }}
                    />
                  </Box>
                </Stack>
              </Box>
              <Stack direction={"row"} spacing={2} mt={4}>
                <Stack>
                  <Typography fontSize={14} fontWeight={600} mb={1}>
                    Valid From:
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={validFrom}
                      minDate={today} // Restrict to dates starting from today
                      onChange={(newValue) =>
                        handleDateChange(newValue, "validFrom")
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack>
                  <Typography fontSize={14} fontWeight={600} mb={1}>
                    Valid Till:
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={validTill}
                      minDate={today} // Restrict to dates starting from today
                      onChange={(newValue) =>
                        handleDateChange(newValue, "validTill")
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Stack>
            </Stack>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                mt: 2,
              }}
            >
              <Button
                variant="outlined"
                onClick={(e) => handleSubmit(e)}
                disabled={!comment.trim() || loading} // Disable if the comment is empty or loading
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <FeedbackForm open={open} setOpen={setOpen} handleOpen={handleOpen} />
    </div>
  );
};

export default RenweDrawer;
