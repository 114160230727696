// import { Box, Typography } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";

// const RequestsDetailsTable = ({ rows, columns, setSelectedRows }) => {
//   const { loading: allTranslationsLoading, allTranslations } = useSelector(
//     (state) => state.translations
//   );
//   const { loading: userLangLoading, language: userLang } = useSelector(
//     (state) => state.userLanguage
//   );

//   const isCompliancePath = window.location.pathname.includes("compliance");

//   const [selectionModel, setSelectionModel] = useState([]);

//   useEffect(() => {
//     // Update selection model when rows change
//     setSelectionModel(rows.map((row) => row.id));
//   }, [rows]);

//   useEffect(() => {
//     // Update selected rows whenever selectionModel changes
//     const selectedRowData = selectionModel.map((id) =>
//       rows.find((row) => row.id === id)
//     );
//     setSelectedRows(selectedRowData);
//   }, [selectionModel, rows, setSelectedRows]);

//   const handleSelectionChange = (newSelectionModel) => {
//     setSelectionModel(newSelectionModel);
//   };

//   return (
//     <>
//       <Typography fontWeight={600} color="text.primary" gutterBottom mt={3}>
//         {allTranslations?.product_details?.[userLang] ?? "Product Details"}
//       </Typography>
//       <Box
//         sx={(theme) => ({
//           "& .super-app-theme--header": {
//             backgroundColor: theme.palette.table.headerBg,
//           },
//         })}
//       >
//         <DataGrid
//           rows={rows}
//           columns={columns}
//           autoHeight
//           pageSize={5}
//           checkboxSelection
//           rowSelectionModel={selectionModel}
//           onRowSelectionModelChange={handleSelectionChange}
//         />
//       </Box>
//     </>
//   );
// };

// export default RequestsDetailsTable;

import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const RequestsDetailsTable = ({ rows, columns, setSelectedRows }) => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );

  const isCompliancePath = window.location.pathname.includes("compliance");

  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    // Update selected rows whenever selectionModel changes
    const selectedRowData = selectionModel.map((id) =>
      rows.find((row) => row.id === id)
    );
    setSelectedRows(selectedRowData);
  }, [selectionModel, rows, setSelectedRows]);

  const handleSelectionChange = (newSelectionModel) => {
    setSelectionModel(newSelectionModel);
  };

  return (
    <>
      <Typography fontWeight={600} color="text.primary" gutterBottom mt={3}>
        {allTranslations?.product_details?.[userLang] ?? "Product Details"}
      </Typography>
      <Box
        sx={(theme) => ({
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.dataGrid.headerBg,
          },
        })}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight
          pageSize={5}
          checkboxSelection
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={handleSelectionChange}
        />
      </Box>
    </>
  );
};

export default RequestsDetailsTable;
