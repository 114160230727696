import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  Avatar,
  Divider,
  Grid,
} from "@mui/material";
import { CiShare1 } from "react-icons/ci";
import { useTheme } from "@emotion/react";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useSelector } from "react-redux";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ShortcutOutlinedIcon from "@mui/icons-material/ShortcutOutlined";
import { bgcolor } from "@mui/system";

const RequestStatusTile = ({
  path,
  requestId,
  requestorId,
  issueDate,
  status,
  userName,
  requestType,
  customFontSize,
  delegation,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const handleClick = () => {
    const encodedRequestId = encodeURIComponent(requestId);
    navigate(path);
  };
  const { loading: translationsLoading, translations } = useSelector(
    (state) => state.translations
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );

  const statusColor =
    status === "In Progress"
      ? theme.palette.status.pending
      : status === "Rejected"
      ? theme.palette.status.rejected
      : theme.palette.status.approved;

  return (
    <Card
      sx={{
        cursor: "pointer",
        // boxShadow: "none",
        borderRadius: 2,
        border: `1px solid ${theme.palette.primary.border}`,
        // filter: `drop-shadow(0px 2px 4px 0px ${theme.palette.primary.dropShadow})`,
        bgcolor: theme.palette.tiles.bg,
        height: 129,
        "&:hover": {
          transform: "scale(1.02)",
        },
      }}
      onClick={handleClick}
    >
      <CardContent>
        <Box>
          <Stack direction={"row"}>
            <Typography color={theme.palette.text.thin}>
              {allTranslations?.status?.[userLang] ?? "Status"} :
            </Typography>
            <Stack
              sx={{ alignItems: "center" }}
              direction={"row"}
              px={1}
              gap={1}
            >
              <Typography color={theme.palette.text.light}>
                {status === "Approved"
                  ? allTranslations?.approved?.[userLang] ?? "Approved"
                  : status === "Rejected"
                  ? allTranslations?.rejected?.[userLang] ?? "Rejected"
                  : allTranslations?.in_progress?.[userLang] ?? "In Progress"}
              </Typography>
              <Box
                sx={{
                  bgcolor: statusColor,
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                .
              </Box>
              {/* {status === "In Progress" ? (
                <ScheduleIcon sx={{ fontSize: 14, color: "yellow" }} />
              ) : status === "Rejected" ? (
                <CancelOutlinedIcon sx={{ fontSize: 14, color: "red" }} />
              ) : (
                <CheckCircleIcon sx={{ fontSize: 14, color: "green" }} />
              )} */}
              {delegation && (
                <ShortcutOutlinedIcon
                  sx={{
                    fontSize: 16,
                    color: "#13dd71",
                  }}
                />
              )}
            </Stack>
          </Stack>
          <Grid container mt={2} rowGap={2}>
            <Grid item xs={6}>
              <Stack direction={"row"}>
                <Typography fontSize={11} color={theme.palette.text.thin}>
                  {allTranslations?.user?.[userLang] ?? "User"} ID :{" "}
                </Typography>
                <Typography
                  fontSize={11}
                  color={theme.palette.text.light}
                  px={1}
                >
                  {requestorId}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={"row"}>
                <Typography fontSize={11} color={theme.palette.text.thin}>
                  {allTranslations?.request?.[userLang] ?? "Request"} ID :{" "}
                </Typography>
                <Typography
                  fontSize={11}
                  color={theme.palette.text.light}
                  px={1}
                >
                  {requestId}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={"row"}>
                <Typography fontSize={11} color={theme.palette.text.thin}>
                  {allTranslations?.type?.[userLang] ?? "Type"} :{" "}
                </Typography>
                <Typography
                  fontSize={11}
                  px={1}
                  color={theme.palette.text.light}
                >
                  {/* {requestType === "New Request"
                    ? allTranslations?.new_request?.[userLang] ?? "Peer Request"
                    : requestType === "Peer Request"
                    ? allTranslations?.peer_request?.[userLang] ??
                      "Peer Request"
                    : requestType === "Elevated Request"
                    ? allTranslations?.elevated_request?.[userLang] ??
                      "Elevated Request" : requestType === "Peer Request"
                      ? allTranslations?.peer_request?.[userLang] ??
                        "Peer Request" : " "} */}
                  {allTranslations?.[
                    requestType?.toLowerCase().split(" ").join("_")
                  ]?.[userLang] ?? requestType}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={"row"}>
                <Typography fontSize={11} color={theme.palette.text.thin}>
                  {allTranslations?.raised_on?.[userLang] ?? "Raised On"} :{" "}
                </Typography>
                <Typography
                  fontSize={11}
                  color={theme.palette.text.light}
                  px={1}
                >
                  {issueDate}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RequestStatusTile;

// import React from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Card,
//   CardContent,
//   Typography,
//   Box,
//   Stack,
//   Avatar,
//   Divider,
//   Grid,
// } from "@mui/material";
// import { CiShare1 } from "react-icons/ci";
// import { useTheme } from "@emotion/react";
// import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
// import { useSelector } from "react-redux";

// const RequestTile = ({
//   path,
//   requestId,
//   requestorId,
//   issueDate,
//   status,
//   userName,
// }) => {
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const handleClick = () => {
//     const encodedRequestId = encodeURIComponent(requestId);
//     console.log(`Encoded Request ID: ${encodedRequestId}`);
//     navigate(`/request-details/${encodedRequestId}`);
//   };
//   const { loading: translationsLoading, translations } = useSelector(
//     (state) => state.translations
//   );
//   const { loading: userLangLoading, language: userLang } = useSelector(
//     (state) => state.userLanguage
//   );

//   return (
//     <Card
//       sx={{
//         cursor: "pointer",
//         boxShadow: "none",
//         borderRadius: "8px",
//         bgcolor:
//           theme.palette.mode === "light"
//             ? theme.palette.primary.mainGradient2
//             : "",
//         // height: "115px",
//         "&:hover": {
//           transform: "scale(1.02)",
//         },
//       }}
//       onClick={handleClick}
//     >
//       <CardContent
//         sx={{
//           "&.MuiCardContent-root": {
//             px: 1,
//             py: 2,
//           },
//         }}
//       >
//         <Stack spacing={1.5} direction={"row"} justifyContent={"center"}>
//           <Avatar sx={{ bgcolor: "#276756" }}>
//             <PersonOutlineIcon sx={{ fontSize: "28px", color: "white" }} />
//           </Avatar>
//           <Stack gap={1}>
//             <Typography variant="subtitle1">{userName}</Typography>
//             <Grid
//               container
//               // spacing={1}
//               // pt={1}
//               sx={{
//                 // border: "2px solid #000",
//                 "&.MuiGrid-root.MuiGrid-item": {
//                   p: 0,
//                 },
//               }}
//               // bgcolor={"yellow"}
//             >
//               <Grid item xs={7} mb={1.25}>
//                 <Stack direction={"row"} gap={0.5}>
//                   <Typography fontSize={"12px"} color="white">
//                     {translations?.request?.[userLang] ?? "Request"} Id :
//                   </Typography>
//                   <Typography fontSize={"12px"} fontWeight={600}>
//                     {requestId}
//                   </Typography>
//                 </Stack>
//               </Grid>
//               <Grid
//                 item
//                 xs={5}
//                 // bgcolor={"pink"}
//               >
//                 <Stack direction={"row"} gap={0.5}>
//                   <Typography fontSize={"12px"} color="white">
//                     Date:
//                   </Typography>
//                   <Typography fontSize={"12px"} fontWeight={600}>
//                     {issueDate}
//                   </Typography>
//                 </Stack>
//               </Grid>
//               <Grid item xs={7}>
//                 <Stack direction={"row"} gap={0.5}>
//                   <Typography fontSize={"12px"} color="white">
//                     {translations?.status?.[userLang] ?? "Status"} :
//                   </Typography>
//                   <Typography
//                     fontSize={"12px"}
//                     // sx={{ color: "secondary.main" }}
//                     fontWeight={600}
//                   >
//                     {status}
//                   </Typography>
//                 </Stack>
//               </Grid>
//               <Grid item xs={5}>
//                 <Stack direction={"row"} gap={0.5}>
//                   <Typography fontSize={"12px"} color="white">
//                     {translations?.type?.[userLang] ?? "Type"} :
//                   </Typography>
//                   <Typography fontSize={"12px"} fontWeight={600}>
//                     Self
//                   </Typography>
//                 </Stack>
//               </Grid>
//             </Grid>
//           </Stack>
//         </Stack>
//       </CardContent>
//     </Card>
//   );
// };

// export default RequestTile;
