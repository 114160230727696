import {
  Autocomplete,
  Box,
  Checkbox,
  InputAdornment,
  InputBase,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import EllipsisWithTooltip from "../components/common/EllipsisWithTooltip";
import { getAllEndUsers, raiseDelegation } from "../apiCalls/allApiCalls";
import SearchBars from "../components/delegation/SearchBars";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import DelegateeDetails from "../components/delegation/DelegateeDetails";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const RaiseDelegation = () => {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const navigate = useNavigate();
  const location = useLocation();

  // Helper function to determine which role's delegation we are creating
  const redirectUrl = () => {
    if (location.pathname === "/approver/delegation/new-delegation") {
      return "approver";
    } else if (location.pathname === "/compliance/delegation/new-delegation") {
      return "compliance";
    }
    return null;
  };

  const Url = redirectUrl();
  const [delegationType, setDelegationType] = useState([]);
  const [status, setStatus] = useState([]);
  const [finalData, setFinalData] = useState({
    delegatorId: "",
    delegateeId: "",
    validFrom: "",
    validTill: "",
    type: "",
    status: "",
  });

  const theme = useTheme();
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );

  const [selectedDelegatorOption, setSelectedDelegatorOption] = useState(null);
  const [selectedDelegateeOption, setSelectedDelegateeOption] = useState(null);
  const [usersData, setUsersData] = useState([]);

  // Function to get all users
  const getAllUsers = async () => {
    const response = await getAllEndUsers();
    if (response) {
      setUsersData(response?.data);
    } else {
      setUsersData([]);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  // Filter user details for delegator and delegatee
  const filteredDelegatorDetails = usersData.filter(
    (user) => user.Uid === selectedDelegatorOption?.split(", ")[1]
  );
  const filteredDelegateeDetails = usersData.filter(
    (user) => user.Uid === selectedDelegateeOption?.split(", ")[1]
  );

  // Handle delegation
  const delegatorUid = selectedDelegatorOption?.split(", ")[1];
  const delegateeUid = selectedDelegateeOption?.split(", ")[1];
  const handleDelegate = async () => {
    const updatedData = {
      ...finalData,
      creatorId: profile?.Uid,
      delegatorId: delegatorUid,
      delegateeId: delegateeUid,
      isApprover: Url === "approver" ? "yes" : "no",
    };

    try {
      const response = await raiseDelegation(updatedData);

      toast.success("The Delegation has been successfully processed!");
      navigate(`/${Url}/delegation`);
    } catch (error) {
      console.error("Error during delegation:", error);
    }
  };

  // Handle setting the current user as "Self" delegator
  const handleSelfDelegator = () => {
    setSelectedDelegatorOption(
      `${profile?.FullName} (${profile?.Email}), ${profile?.Uid}`
    );
  };

  return (
    <Box
      sx={{
        padding: "20px",
        minWidth: "90vw",
        minHeight: "125vh",
        margin: 2,
        borderRadius: 3,
        color: "white",
      }}
    >
      <Box sx={{ mb: 2 }}>
        <BreadCrumbs />
      </Box>
      <SearchBars
        usersData={usersData}
        setSelectedDelegatorOption={setSelectedDelegatorOption}
        setSelectedDelegateeOption={setSelectedDelegateeOption}
        selectedDelegatorOption={selectedDelegatorOption}
        selectedDelegateeOption={selectedDelegateeOption}
      />
      <Stack mt={5}>
        <Typography variant="h6" color={theme.palette.text.primary}>
          {allTranslations?.delegator_details?.[userLang] ??
            "Delegator Details"}
        </Typography>
        <Box
          width={"100%"}
          height={100}
          mt={3}
          borderRadius={5}
          bgcolor={"icons.bgLight"}
        >
          <Box
            width={"100%"}
            height={98}
            borderRadius={5}
            mt={0.5}
            bgcolor="background.secondary"
          >
            {filteredDelegatorDetails.length > 0 &&
            delegatorUid != profile?.Uid ? (
              <Stack
                direction={"row"}
                py={4}
                px={5}
                justifyContent={"space-between"}
                gap={1}
              >
                <Stack
                  direction={"row"}
                  gap={0.5}
                  alignItems={"center"}
                  width={160}
                  // bgcolor="pink"
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "text.primary",
                      fontWeight: 600,
                      wordBreak: "break-word",
                    }}
                  >
                    {allTranslations?.user?.[userLang] ?? "User"} ID :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "text.secondary",
                    }}
                  >
                    {filteredDelegatorDetails[0]?.Uid}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={0.5}
                  alignItems={"center"}
                  width={300}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "text.primary",
                      fontWeight: 600,
                      wordBreak: "break-word",
                    }}
                  >
                    {allTranslations?.user_name?.[userLang] ?? "User Name"} :
                  </Typography>
                  <EllipsisWithTooltip
                    text={filteredDelegatorDetails[0]?.FullName}
                    customWidth={140}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  gap={0.5}
                  alignItems={"center"}
                  width={270}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "text.primary",
                      fontWeight: 600,
                      wordBreak: "break-word",
                    }}
                  >
                    {allTranslations?.email?.[userLang] ?? "Email"} :
                  </Typography>
                  <EllipsisWithTooltip
                    text={filteredDelegatorDetails[0]?.Email}
                    customWidth={180}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  gap={0.5}
                  alignItems={"center"}
                  width={220}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "text.primary",
                      fontWeight: 600,
                      wordBreak: "break-word",
                    }}
                  >
                    {allTranslations?.manager_name?.[userLang] ??
                      "Manager Name"}{" "}
                    :
                  </Typography>
                  <EllipsisWithTooltip
                    text={filteredDelegatorDetails[0]?.Manager || "N/A"}
                    customWidth={100}
                  />
                </Stack>
              </Stack>
            ) : (
              <Box margin={"auto"} pt={4}>
                <Stack
                  direction={"row"}
                  px={2}
                  gap={1}
                  // justifyContent={"space-between"}
                >
                  <Stack
                    direction={"row"}
                    gap={0.5}
                    alignItems={"center"}
                    mr={3}
                    width={90}
                  >
                    <Checkbox
                      onClick={handleSelfDelegator}
                      checked={selectedDelegatorOption !== null}
                    />
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "text.primary",
                      }}
                    >
                      {allTranslations?.self?.[userLang] ?? "Self"}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    gap={0.5}
                    alignItems={"center"}
                    width={160}
                    // bgcolor="pink"
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "text.primary",
                        fontWeight: 600,
                        wordBreak: "break-word",
                      }}
                    >
                      {allTranslations?.user?.[userLang] ?? "User"} ID :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "text.secondary",
                      }}
                    >
                      {profile?.Uid}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    gap={0.5}
                    alignItems={"center"}
                    width={300}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "text.primary",
                        fontWeight: 600,
                        wordBreak: "break-word",
                      }}
                    >
                      {allTranslations?.user_name?.[userLang] ?? "User Name"} :
                    </Typography>
                    <EllipsisWithTooltip
                      text={profile?.FullName}
                      customWidth={140}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    gap={0.5}
                    alignItems={"center"}
                    width={270}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "text.primary",
                        fontWeight: 600,
                        wordBreak: "break-word",
                      }}
                    >
                      {allTranslations?.email?.[userLang] ?? "Email"} :
                    </Typography>
                    <EllipsisWithTooltip
                      text={profile?.Email}
                      customWidth={180}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    gap={0.5}
                    alignItems={"center"}
                    width={220}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "text.primary",
                        fontWeight: 600,
                        wordBreak: "break-word",
                      }}
                    >
                      {allTranslations?.manager_name?.[userLang] ??
                        "Manager Name"}{" "}
                      :
                    </Typography>
                    <EllipsisWithTooltip
                      text={profile?.Manager || "N/A"}
                      customWidth={100}
                    />
                  </Stack>
                </Stack>
              </Box>
            )}
          </Box>
        </Box>
      </Stack>
      <Stack mt={5}>
        <Typography variant="h6" color={theme.palette.text.primary}>
          {allTranslations?.delegatee_details?.[userLang] ??
            "Delegatee Details"}
        </Typography>
        <Box sx={{ height: 200, width: "100%", mt: 2 }}>
          <DelegateeDetails
            finalData={finalData}
            setFinalData={setFinalData}
            delegationType={delegationType}
            setDelegationType={setDelegationType}
            status={status}
            filteredDelegateeDetails={filteredDelegateeDetails}
            setStatus={setStatus}
            handleDelegate={handleDelegate}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default RaiseDelegation;
