import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";

function RisksTable({
  mitigations,
  handleChangeMitigation,
  handleRunRiskAnalysis,
  loading,
  rows,
}) {
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  // Function to check if all rows have mitigation selected
  const checkIfAllMitigationsAssigned = () => {
    return rows.every((row) => mitigations[row.id]); // Ensures each row has a mitigation
  };

  // UseEffect to update button state when mitigations change
  useEffect(() => {
    setIsButtonEnabled(rows.length > 0 && checkIfAllMitigationsAssigned());
  }, [mitigations, rows]);

  const columns = [
    {
      field: "userId",
      headerName: allTranslations?.user?.[userLang] + "ID" ?? "User ID",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      sortable: false,
      editable: false,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: "system",
      headerName: allTranslations?.system?.[userLang] ?? "System",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: "riskDescription",
      headerName:
        allTranslations?.risk_description?.[userLang] ?? "Risk description",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: "platform",
      headerName: allTranslations?.platform?.[userLang] ?? "Platform",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: "assignMitigation",
      headerName:
        allTranslations?.assign_mitigation?.[userLang] ?? "Assign mitigation",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <FormControl fullWidth>
          <Select
            sx={{ mt: 0.5 }}
            value={mitigations[params.row.id] || ""}
            onChange={(event) => handleChangeMitigation(event, params.row.id)}
            size="small"
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Select Mitigation</em>;
              }

              return selected;
            }}
            displayEmpty
          >
            <MenuItem value="Mitigation 1">Mitigation 1</MenuItem>
            <MenuItem value="Mitigation 2">Mitigation 2</MenuItem>
            <MenuItem value="Mitigation 3">Mitigation 3</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: "risk",
      headerName: allTranslations?.risk?.[userLang] ?? "Risk",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box>
          {params.value === "false" ? (
            <CheckCircleIcon sx={{ color: "green" }} />
          ) : (
            <ReportGmailerrorredIcon sx={{ color: "tomato" }} />
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={3}
        mb={1}
      >
        <Typography sx={{ color: "white", fontSize: "20px" }}>Risks</Typography>
        <Button
          variant="contained"
          onClick={handleRunRiskAnalysis}
          disabled={!isButtonEnabled} // Disable the button based on the state
          sx={{
            bgcolor: "primary.main",
          }}
        >
          Run risk analysis{" "}
          <PlayArrowIcon sx={{ color: "text.contrastText" }} />
        </Button>
      </Stack>

      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <CircularProgress sx={{ color: "white" }} size={60} />
        </Box>
      )}
      <Box
        sx={(theme) => ({
          minHeight: 200,
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.dataGrid.headerBg,
          },
        })}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
          pageSizeOptions={[3]}
          localeText={{ noRowsLabel: "Select the producs from Above table" }}
          disableRowSelectionOnClick
          sx={{ mb: 5, minHeight: 200 }}
        />
      </Box>
    </>
  );
}

export default RisksTable;
