import { Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import MiniMenu from "../components/common/miniMenu/MiniMenu";
import BreadCrumbs from "../components/common/breadCrumbs/BreadCrumbs";
import CustomizedSwitches from "../components/common/switch/Switch";
import { useNavigate } from "react-router-dom";

const BulkUpload = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [rows, setRows] = useState([]);
  const [checked, setChecked] = useState(true); // Set initial state to true
  const [columns, setColumns] = useState([]);
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { language: userLang } = useSelector((state) => state.userLanguage);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
        validateAndSetData(jsonData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const validateAndSetData = (data) => {
    if (data.length === 0) {
      setErrorMessage("The uploaded file is empty or invalid.");
      return;
    }
    const expectedHeaders = [
      "User ID",
      "Role",
      "Action",
      "Valid From",
      "Valid To",
      "Connector",
    ];
    const uploadedHeaders = data[0];
    const isValidFormat =
      uploadedHeaders.length === expectedHeaders.length &&
      expectedHeaders.every(
        (header, index) => header === uploadedHeaders[index]
      );

    if (!isValidFormat) {
      toast.error(
        <div>
          The uploaded Excel file is not in the correct format.
          <a
            href="mailto:ganeshg@aapmor.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            contact support
          </a>
          .
        </div>
      );
      setErrorMessage("The uploaded Excel file is not in the correct format.");
      setRows([]);
      return;
    }

    const gridColumns = uploadedHeaders.map((header) => ({
      field: header.replace(/\s+/g, "_").toLowerCase(),
      headerName: header,
      headerClassName: "super-app-theme--header",
      width: 150,
    }));

    const gridRows = data.slice(1).map((row, index) => {
      const rowData = {};
      uploadedHeaders.forEach((header, i) => {
        rowData[header.replace(/\s+/g, "_").toLowerCase()] = row[i] || "";
      });
      return { id: index + 1, ...rowData };
    });

    setColumns(gridColumns);
    setErrorMessage("");
    setRows(gridRows);
  };

  const handleDownload = () => {
    const tableData = [
      ["User ID", "Role", "Action", "Valid From", "Valid To", "Connector"],
      ["702464224", "ABAP_READ", "Assign", "", "", "DGH"],
      [
        "702464224",
        "GRC-SECURITY::XG1_GRAC_XXXXXX_SYS_COMM_UID",
        "Assign",
        "",
        "",
        "DGH",
      ],
      [
        "702464224",
        "GRC-SECURITY::XG1_ITSC_XXXXXX_SEC_ADM",
        "Assign",
        "",
        "",
        "DGH",
      ],
      [
        "702464224",
        "XG2-GRAC-XXXXXX-FIORI_FF_USER",
        "Assign",
        "",
        "",
        "GXZCLNT950",
      ],
      ["702460461", "ABAP_READ", "Assign", "", "", "DGH"],
      [
        "702460461",
        "GRC-SECURITY::XG1_GRAC_XXXXXX_SYS_COMM_UID",
        "Assign",
        "",
        "",
        "DGH",
      ],
      [
        "702460461",
        "GRC-SECURITY::XG1_ITSC_XXXXXX_SEC_ADM",
        "Assign",
        "",
        "",
        "DGH",
      ],
      [
        "702460461",
        "XG2-GRAC-XXXXXX-FIORI_FF_USER",
        "Assign",
        "",
        "",
        "GXZCLNT950",
      ],
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "sample-data.xlsx");
  };

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
    console.log(checked, "checked");
    sessionStorage.setItem("bulkUpload", false);
    navigate("/new-request");
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        padding: "20px",
        minWidth: "90vw",
        minHeight: "100vh",
        margin: 5,
        borderRadius: 3,
        border: `1px solid`,
        borderColor: "primary.border",
      }}
    >
      <MiniMenu />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={12}
        mb={6}
        ml={1}
      >
        <BreadCrumbs />
        <CustomizedSwitches
          checked={checked}
          handleSwitchChange={handleSwitchChange}
        />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          bgcolor: "background.secondary",
          borderRadius: "5px",
          py: 3,
          px: 2,
          gap: 2,
        }}
      >
        <Box
          sx={{
            maxWidth: 300,
            mb: 3,
            display: "flex",
            gap: 4,
            alignItems: "center",
          }}
        >
          <Button
            component="label"
            variant="contained"
            sx={{ mt: 1, bgcolor: "primary.main" }}
            htmlFor="upload-input"
          >
            {allTranslations?.upload?.[userLang] ?? "Upload"}
          </Button>
          <input
            id="upload-input"
            type="file"
            accept=".xlsx, .xls"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
        </Box>
        <Button
          component="label"
          variant="outlined"
          sx={(theme) => ({
            mt: 1,
            bgcolor: "icons.bgLight",
            color: "text.primary",
            border: `1px solid ${theme.palette.text.primary}`,
          })}
          endIcon={<FileDownloadOutlinedIcon />}
          onClick={handleDownload}
        >
          {allTranslations?.download_sample_file?.[userLang] ??
            "Download Sample File"}
        </Button>
      </Stack>
      <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
        <Box
          sx={(theme) => ({
            "& .super-app-theme--header": {
              backgroundColor: theme.palette.dataGrid.headerBg,
            },
          })}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            components={{
              NoRowsOverlay: () => (
                <Box sx={{ textAlign: "center", p: 2 }}>
                  <Typography>
                    {allTranslations?.no_data_available?.[userLang] ??
                      "No Data Available"}
                  </Typography>
                </Box>
              ),
            }}
            localeText={{
              noRowsLabel:
                allTranslations?.no_products_are_added?.[userLang] ??
                "No products are added",
            }}
          />
        </Box>
      </div>
    </Box>
  );
};

export default BulkUpload;
