import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  IconButton,
  Divider,
  Avatar,
  Stack,
  Typography,
  Button,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import TranslateIcon from "@mui/icons-material/Translate";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { ColorModeContext } from "../../pages/Root";
import CloseIcon from "@mui/icons-material/Close";
import { Description } from "@mui/icons-material";
import Notifications from "../common/notifications/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { setUserLanguage } from "../../redux/slices/userLanguage";
import { updateUserLanguageByUid } from "../../apiCalls/allApiCalls";
import Cookies from "js-cookie";
import Profile from "../common/profile/Profile";

const NavRight = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const dispatch = useDispatch();
  const { loading: userLangLoading, language: userLang } = useSelector(
    (state) => state.userLanguage
  );
  const { loading: allTranslationsLoading, allTranslations } = useSelector(
    (state) => state.translations
  );
  const { loading: profileLoading, user: profile } = useSelector(
    (state) => state.users
  );

  // const router = useRouter();
  const onLogout = async () => {
    try {
      // await axios.get("/api/users/logout");
      // router.push("/login");
    } catch (error) {
      // console.log(error.message);
    }
  };

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [option, setOption] = useState("");
  const [anchorElAvatar, setAnchorElAvatar] = useState(null);
  const languages = [
    {
      value: allTranslations?.chinese?.[userLang] ?? "Chinese",
      code: "Chinese",
    },
    { value: allTranslations?.arabic?.[userLang] ?? "Arabic", code: "Arabic" },
    {
      value: allTranslations?.english?.[userLang] ?? "English",
      code: "English",
    },
    { value: allTranslations?.french?.[userLang] ?? "French", code: "French" },
    { value: allTranslations?.german?.[userLang] ?? "German", code: "German" },
    { value: allTranslations?.hindi?.[userLang] ?? "Hindi", code: "Hindi" },
    {
      value: allTranslations?.italian?.[userLang] ?? "Italian",
      code: "Italian",
    },
    {
      value: allTranslations?.japanese?.[userLang] ?? "Japanese",
      code: "Japanese",
    },
    { value: allTranslations?.korean?.[userLang] ?? "Korean", code: "Korean" },
    {
      value: allTranslations?.malayalam?.[userLang] ?? "Malayalam",
      code: "Malayalam",
    },
    {
      value: allTranslations?.russian?.[userLang] ?? "Russian",
      code: "Russian",
    },
    {
      value: allTranslations?.spanish?.[userLang] ?? "Spanish",
      code: "Spanish",
    },
    { value: allTranslations?.tamil?.[userLang] ?? "Tamil", code: "Tamil" },
    { value: allTranslations?.telugu?.[userLang] ?? "Telugu", code: "Telugu" },
  ];

  const handleCloseAvatarMenu = () => {
    setAnchorElAvatar(null);
  };

  const handleOpenAvatarMenu = (event) => {
    setAnchorElAvatar(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const iconButtonStyling = {
    bgcolor: "#FFFFFF",
    width: "40px",
    color: "#223635",
    border: `1px solid ${theme.palette.primary.border}`,
    "&:hover": {
      border: `1px solid ${theme.palette.icons.bgDark}`,
      color: theme.palette.icons.colorActive,
      bgcolor: theme.palette.icons.bgDark,
    },
  };

  const [translationsAnchorEl, setTranslationsAnchorEl] = React.useState(null);
  const translationsMenuOpen = Boolean(translationsAnchorEl);
  const handleTranslationsClick = (event) => {
    setTranslationsAnchorEl(event.currentTarget);
  };
  const handleTranslationsClose = () => {
    setTranslationsAnchorEl(null);
  };
  const handleTranslationSelect = (lang) => {
    // console.log("lang", lang);
    // update the user lang preference in the db
    // updateUserLanguage("66ab28a2a9a45270eaadd37f", lang);
    updateUserLanguageByUid(profile?.Uid, lang);
    // update the user lang preference in the store
    dispatch(setUserLanguage(lang));
    handleTranslationsClose();
  };

  const handleLogout = () => {
    Cookies.remove("jwtToken", {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      path: "/",
    });

    Cookies.remove("userEmail", {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      path: "/",
    });
    Cookies.remove("access", {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      path: "/",
    });
    const authAppUrl = process.env.REACT_APP_AUTH_URI;
    const redirectUri = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
    window.location.href = `${authAppUrl}?redirect_uri=${redirectUri}&app=vertex`;
  };

  return (
    <>
      <Stack
        // flexDirection="row"
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
      >
        {/* <Tooltip title="Switch theme">
          <IconButton
            sx={{ ml: 1 }}
            onClick={colorMode.toggleColorMode}
            color="inherit"
          >
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
        </Tooltip> */}
        <Tooltip
          title={allTranslations?.translations?.[userLang] ?? "Translations"}
        >
          <IconButton
            // onClick={handleOpenAvatarMenu}
            id="translations-button"
            sx={iconButtonStyling}
            aria-controls={
              translationsMenuOpen ? "translations-menu" : undefined
            }
            aria-haspopup="true"
            aria-expanded={translationsMenuOpen ? "true" : undefined}
            onClick={handleTranslationsClick}
          >
            <TranslateIcon style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
        <Menu
          id="translations-menu"
          anchorEl={translationsAnchorEl}
          open={translationsMenuOpen}
          onClose={handleTranslationsClose}
          MenuListProps={{
            "aria-labelledby": "translations-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          slotProps={{
            paper: {
              sx: {
                maxHeight: 48 * 4.5,
                width: "18ch",
                "&::-webkit-scrollbar": {
                  width: "1px",
                  bgcolor: "white",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "black", // Customize the scrollbar thumb color
                  borderRadius: 5, // Customize the thumb border radius
                },
              },
            },
          }}
          // sx={{
          //   "&MuiPaper-root ": {
          //     "::-webkit-scrollbar": {
          //       display: "none",
          //     },
          //     "-ms-overflow-style": "none", // Internet Explorer 10+
          //     "scrollbar-width": "none", // Firefox
          //   },
          // }}
        >
          {languages.map(({value, code}) => (
            <MenuItem
              key={code}
              onClick={() => handleTranslationSelect(code)}
              selected={
                userLangLoading ? code === "English" : userLang === code
              }
              sx={{
                "&.Mui-selected": {
                  bgcolor: theme.palette.icons.bgDark,
                  color: theme.palette.icons.colorActive,
                  ":hover": {
                    bgcolor: theme.palette.icons.bgDark,
                    color: theme.palette.icons.colorActive,
                  },
                },
                ":hover": {
                  bgcolor: theme.palette.icons.bgDark,
                  color: theme.palette.icons.colorActive,
                },
              }}
            >
              {code}
            </MenuItem>
          ))}
        </Menu>
        <Tooltip
          title={allTranslations?.notifications?.[userLang] ?? "Notifications"}
        >
          <IconButton onClick={handleOpenMenu} sx={iconButtonStyling}>
            <NotificationsNoneIcon style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
        <Notifications handleCloseMenu={handleCloseMenu} anchorEl={anchorEl} />
        <Tooltip title={allTranslations?.profile?.[userLang] ?? "Profile"}>
          <IconButton
            onClick={handleOpenAvatarMenu}
            aria-label="account of current user"
            aria-controls="menu-avatar"
            aria-haspopup="true"
            color="inherit"
            sx={iconButtonStyling}
          >
            {/* <Avatar sx={{ width: 40, height: 40, fontSize: "17px" }}>ST</Avatar> */}
            <PersonOutlineIcon style={{ width: "20px" }} />
          </IconButton>
        </Tooltip>
        <Profile
          handleLogout={handleLogout}
          anchorElAvatar={anchorElAvatar}
          handleCloseAvatarMenu={handleCloseAvatarMenu}
        />
      </Stack>
    </>
  );
};

export default NavRight;
